import React from 'react';

import {
	Dropdown, DropdownDisclosure, DropdownWrapper, useDropdownState
} from 'components/Dropdown';
import getSortByUrl from 'features/sort/getSortByUrl';
import getSortByEventName from 'features/sort/getSortByEventName';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import './SortByDropdown.scss';

const options = Object.entries(lp.pageData?.sortOptions?.Mappings).map(([ k, v ]) => ({ key: k, value: v.DisplayFieldName }));
const selected = options.find((opt) => lp.pageData?.sortOptions?.SelectedSortByValue?.toString() === opt.key);

const SortByDropdown = () => {

	const dropdown = useDropdownState(false);

	const onButtonClick = () => {

		if (!dropdown.show) {

			setGenericEvent({ event_name: 'sortby_expand' });

		}
		dropdown.toggleDropdown();

	};

	const onOptionClick = (optionName) => {

		setGenericEvent({ event_name: getSortByEventName(optionName) });

	};

	return (
		<DropdownWrapper className="SortByDropdown">
			<DropdownDisclosure onButtonClick={onButtonClick} {...dropdown} className="SortByDropdown__button">
				<img src="/img/sort/sort-icon.svg" className="SortByDropdown__icon" aria-hidden="true" alt="" />
				Sort By:
				{' '}
				{selected.value}
			</DropdownDisclosure>
			{dropdown.show && (
				<Dropdown
					{...dropdown}
					alignEdge="right"
					anchorEdge="bottom"
					hideOnEscape
					hideOnOutsideClick
				>
					<div className="SortByDropdown__wrapper">
						{options.map((opt) => (
							selected.key === opt.key
								? (
									<span aria-selected="true" className="SortByDropdown__option SortByDropdown__option--selected" key={opt.key}>
										{opt.value}
									</span>
								)
								: (
									<a href={getSortByUrl(opt.key)} onClick={() => onOptionClick(opt.value)} aria-selected="false" className="SortByDropdown__option" key={opt.key}>
										{opt.value}
									</a>
								)
						))}
					</div>
				</Dropdown>
			)}
		</DropdownWrapper>
	);

};

export default SortByDropdown;
