import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { Button } from 'components/Button';
import {
	Drawer, OverlayCloseButton, OverlayContent, OverlayDisclosure, useOverlayState
} from 'components/Overlay';
import { utagViewArtificialPageView } from 'features/tealium';
import useChecklistDraftState from 'features/sort/useChecklistDraftState';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import isMappedTo from '../../isMappedTo';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import SortFilterAttrHeader from '../SortFilterAttrHeader';
import SortFilterAttrGroupHeader from '../SortFilterAttrGroupHeader';
import findAttributeValueById from '../../findAttributeValueById';

import './SortFilterNestedDrawer.scss';

const trackArtificalPageViewEvent = utagViewArtificialPageView();

const SortFilterNestedDrawer = ({
	groupName,
	attrGroupLevel,
	attributeGroupFilterDisplayTypeId,
	render,
	hideParentOverlay = undefined,
	attributes = [],
	headerImageSrc,
	headerImageAltTag
}) => {

	// Most of the logic before the return statement is for renders where there is only one attribute in the attributes
	// array. This will be on the final drawer that actually renders the attribute (value list, slider, etc)
	// At the last level, SortFilterAttributeGroup renders SortFilterNestedDrawer in a loop for each attribute,
	// sending only the one attribute in the attributes array for each

	const firstAttribute = attributes[0];
	const name = groupName || firstAttribute.Name;
	const attributeFilterDisplayTypeId = firstAttribute.AttributeFilterDisplayTypeId;

	const gridViewVariation = isMappedTo.attribute.attrValGridView.which(attributeFilterDisplayTypeId);
	const isMultiSelect = isMappedTo.attribute.multiSelect(attributeFilterDisplayTypeId);
	const hasNestedAttributes = isMappedTo.attributeGroup.nestedAttributes(attributeGroupFilterDisplayTypeId);
	// tells us if we are at the last nesting level, for ex. directly in 'Color', or all the way down in 'Size/Height'
	// need both conditions because when at last level (inside 'Height'), areAnyAttrsNested is still true
	const noMoreNested = !attrGroupLevel || !hasNestedAttributes;
	const showApplyClearBtns = attributes.length === 1 && isMultiSelect && noMoreNested;
	const gridImageSizeVariation = isMappedTo.attribute.attrValHeaderImageSize.which(attributeFilterDisplayTypeId);
	const isGridViewContainer = noMoreNested && gridViewVariation;

	const defaultClassName = 'SortFilterNestedDrawer';
	const gridBaseClassName = 'SortFilterAttrGroupGridView';
	const fullClassName = classNames(defaultClassName, {
		[`${defaultClassName}--multiSelect`]: noMoreNested && isMultiSelect,
		[`${defaultClassName}--noFooter`]: !showApplyClearBtns,
		[gridBaseClassName]: isGridViewContainer,
		[`${gridBaseClassName}--${gridViewVariation}`]: isGridViewContainer,
		[`${gridBaseClassName}--${gridImageSizeVariation}`]: isGridViewContainer && gridImageSizeVariation
	});
	const mainElementClassName = classNames({
		[`${defaultClassName}__attributeList`]: hasNestedAttributes && attrGroupLevel
	});

	const {
		setValue, reset, valueList, applyDisabled, clearDisabled, defaultValues, applyMultiValueList
	} =		useChecklistDraftState(firstAttribute);
	const drawer = useOverlayState(false, '#filterNestedDrawer');
	const { hideOverlay } = drawer;
	const [ isLoading, setIsLoading ] = useState(false);

	// To close this drawer and the parent drawer at the same time
	const hideOverlays = () => {

		hideOverlay();
		if (hideParentOverlay) {

			hideParentOverlay();

		}

	};
	const onApplyClick =		applyDisabled || isLoading
		? () => null
		: () => {

			setIsLoading(true);

			valueList?.forEach((val) => {

				const attributeValue = findAttributeValueById(firstAttribute.AttributeValueGroups, val).Name;
				sortFilterUtagLink(name, attributeValue);
				sortFilterGenericEvent('filter_click', name, attributeValue, true);

			});

			applyMultiValueList();

		};
	const onClearClick = clearDisabled
		? () => null
		: () => {

			reset();
			setValue('SortSearchFilter', '');

		};

	const onButtonClick = () => {

		// since this component is reused across all nested levels so we additionally check for top level
		if (!drawer.show && attrGroupLevel) {

			const { managedSortModels } = window.lp.pageData.sortFilter || {};
			if (managedSortModels?.[0]?.AttributeGroups) {

				const matchedAttr = managedSortModels?.[0]?.AttributeGroups.reduce(
					(attr, { Attributes }) => [ ...attr, ...Attributes ],
					[]
				).find(({ Name: attrName }) => attrName === name);
				if (matchedAttr?.DisplayType) {

					window.utag_data.fds_display_type = matchedAttr?.DisplayType;

				} else {

					const matchedAttrVar = managedSortModels?.[0]?.AttributeGroups.find(
						({ Name: attrName }) => attrName === name
					).Attributes;
					if (matchedAttrVar?.[0].DisplayType) {

						window.utag_data.fds_display_type = matchedAttrVar?.[0].DisplayType;

					}

				}

			}

			sortFilterUtagLink(`Expand-${name}`, '', false);
			sortFilterGenericEvent('filter_expand', name, '', true);
			trackArtificalPageViewEvent({ pageName: name });

		}
		drawer.showOverlay();

	};

	const headerComponent = attrGroupLevel ? (
		<SortFilterAttrGroupHeader
			name={name}
			attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
			headerImageSrc={headerImageSrc}
			headerImageAltTag={headerImageAltTag}
			numApplied={defaultValues.length}
		/>
	) : (
		<SortFilterAttrHeader
			attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
			name={firstAttribute.Name}
			headerImageSrc={firstAttribute.HeaderImage}
			headerImageAltTag={firstAttribute.HeaderImageAltTag}
		/>
	);

	return (
		<div className="SortFilterDisplaySetDrawer__buttonGroup">
			<OverlayDisclosure {...drawer} onButtonClick={onButtonClick}>
				<span>
					{headerComponent}
				</span>
				<span className="caret right" />
			</OverlayDisclosure>
			<Drawer
				{...drawer}
				className={fullClassName}
				aria-label={`${name} Filter Menu`}
				anchorEdge="right"
				hideOverlay={hideOverlays}
				underlayClickExits={false}
				scrollDisabled={false}
				focusDialog
				escapeExits
				fullScreen
			>
				<OverlayContent>
					<header>
						<OverlayCloseButton hideOverlay={hideOverlay} className="caret left">
							{headerComponent}
						</OverlayCloseButton>
					</header>
					<main className={mainElementClassName}>
						{render(hideOverlays)}
					</main>
					{showApplyClearBtns && (
						<footer>
							<Button
								onClick={onApplyClick}
								buttonStyleVariant="primary"
								aria-disabled={applyDisabled}
								loading={isLoading}
							>
								Apply
							</Button>
							<Button onClick={onClearClick} buttonStyleVariant="tertiary" aria-disabled={clearDisabled}>
								Clear All
							</Button>
						</footer>
					)}
				</OverlayContent>
			</Drawer>
		</div>
	);

};

SortFilterNestedDrawer.propTypes = {
	groupName: PropTypes.string,
	attrGroupLevel: PropTypes.bool,
	attributeGroupFilterDisplayTypeId: PropTypes.number,
	render: PropTypes.func.isRequired,
	hideParentOverlay: PropTypes.func,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string,
	attributes: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				ImageUrl: PropTypes.string,
				Name: PropTypes.string,
				SortOrder: PropTypes.number,
				Type: PropTypes.number,
				AttributeValueGroups: PropTypes.arrayOf(
					PropTypes.shape({
						DisplayName: PropTypes.string,
						ImageUrl: PropTypes.string,
						SortOrder: PropTypes.number,
						Url: PropTypes.string
					})
				)
			})
		)
	]).isRequired
};

export default SortFilterNestedDrawer;
