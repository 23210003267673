import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleDisclosure from './CollapsibleDisclosure';

import './CollapsibleTitle.scss';

function CollapsibleTitle ({
	children, collapsibleContentId, collapsibleDisclosureId, open, setOpen
}) {

	const caretClassName = [ 'CollapsibleTitle__caret', `CollapsibleTitle__caret--${open ? 'open' : 'closed'}` ].join(
		' '
	);

	return (
		<CollapsibleDisclosure
			open={open}
			setOpen={setOpen}
			collapsibleContentId={collapsibleContentId}
			collapsibleDisclosureId={collapsibleDisclosureId}
		>
			<div className="CollapsibleTitle">
				{children}
				<div className={caretClassName} />
			</div>
		</CollapsibleDisclosure>
	);

}

CollapsibleTitle.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	collapsibleContentId: PropTypes.string.isRequired,
	collapsibleDisclosureId: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};

export default CollapsibleTitle;
