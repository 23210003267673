import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import isMappedTo from '../../isMappedTo';
import SortFilterAttributeValue from '../SortFilterAttributeValue';

const SortFilterAttrValueHeader = memo(
	({
		displayName, thumbnail, description, isGridView, productCount, attrDisplayTypeId
	}) => {

		const AttributeValueHeader = ({
			showThumbnail = false,
			showDisplayName = false,
			showDescription = false,
			showDescriptionAsDisplayName = false
		}) => (
			<SortFilterAttributeValue
				displayName={displayName}
				showDisplayName={showDisplayName}
				thumbnail={thumbnail}
				showThumbnail={showThumbnail}
				description={description}
				showDescription={showDescription}
				isGridView={isGridView}
				productCount={productCount}
				showDescriptionAsDisplayName={showDescriptionAsDisplayName}
			/>
		);

		AttributeValueHeader.propTypes = {
			showDisplayName: PropTypes.bool,
			showThumbnail: PropTypes.bool,
			showDescription: PropTypes.bool,
			showDescriptionAsDisplayName: PropTypes.bool
		};

		const attrValHeaderVariation = isMappedTo.attribute.attrValHeaderVariation.which(attrDisplayTypeId);

		switch (attrValHeaderVariation) {

			case 'imageOnly':
				return <AttributeValueHeader showThumbnail />;

			case 'imageWithTextAndDescription':
				return <AttributeValueHeader showThumbnail showDisplayName showDescription />;

			case 'imageWithText':
				return <AttributeValueHeader showThumbnail showDisplayName />;

			case 'textWithDescription':
				return <AttributeValueHeader showDisplayName showDescription />;

			case 'imageWithTextDescriptionAsDisplay':
				return <AttributeValueHeader showThumbnail showDisplayName showDescriptionAsDisplayName />;

			case 'textOnlyDescriptionAsDisplay':
				return <AttributeValueHeader showDisplayName showDescriptionAsDisplayName />;

			case 'textOnly':
			default:
				return <AttributeValueHeader showDisplayName />;

		}

	},
	(prev, next) => shallowEqual(prev.thumbnail, next.thumbnail) // prevent flicker of LazyImage thumbnail
);

SortFilterAttrValueHeader.propTypes = {
	displayName: PropTypes.string.isRequired,
	thumbnail: PropTypes.oneOfType([ PropTypes.object ]),
	description: PropTypes.string,
	isGridView: PropTypes.bool,
	productCount: PropTypes.number,
	attrDisplayTypeId: PropTypes.number
};

export default SortFilterAttrValueHeader;
