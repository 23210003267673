const getTrimmedContent = (el, excessLines) => {

	const text = el.innerHTML;
	const totalHeight = el.offsetHeight;
	el.innerHTML = 'a';
	const maxElementHeight = totalHeight - excessLines * el.offsetHeight;
	const arr = text.split(' ');
	let cur = '';
	let prev = '';

	for (let i = 0; i < arr.length; i++) {

		const restOfText = text.substring(prev.length, text.length);

		const nextIndex =			restOfText.indexOf(' ') === 0
			? restOfText.substring(1, restOfText.length).indexOf(' ') + 1
			: restOfText.indexOf(' ');

		cur += restOfText.substring(0, nextIndex);

		el.innerHTML = cur;

		if (el.offsetHeight > maxElementHeight) {

			el.innerHTML = prev;
			const firstLine = el.innerText;
			el.innerHTML = text;
			return firstLine;

		}
		prev += cur.substring(prev.length, cur.length);

	}

};

export const initializeTrimHybridContent = (elementId, maxNumberOf = 20) => {

	const hybridContentElement = document.getElementById(elementId);
	if (!hybridContentElement) return;
	const elementClone = document.createElement('div');
	elementClone.innerHTML = hybridContentElement.innerHTML;
	const trimmedConntent = document.createElement('div');
	hybridContentElement.parentNode.insertBefore(trimmedConntent, hybridContentElement.nextSibling);
	const [ contentWrapper ] = elementClone.children;
	let readMoreClicked = false;

	const countNumberOfLine = (element, wrapper) => {

		if (element.tagName === 'BR') {

			wrapper.appendChild(element.cloneNode(true));
			return 0;

		}
		const a = element.cloneNode(true);
		const b = element.cloneNode(true);
		a.innerHTML = 'a<br>b';
		const originalInnerHTML = b.innerHTML;
		b.innerHTML = originalInnerHTML.replace(/\<br\>/g, ' ');
		wrapper.appendChild(b);
		wrapper.appendChild(a);
		const size = a.scrollHeight / 2;
		a.remove();
		const lines = Math.floor(b.scrollHeight / size) || 0;
		b.innerHTML = originalInnerHTML;
		return lines;

	};

	const renderReadMoreBtn = (container, hybridContentElement) => {

		const btnWrapper = document.createElement('div');
		const btn = document.createElement('button');
		btn.innerHTML = 'Read more';
		btn.classList.add('hybridContent-read-more', 'anchorLink');
		btn.addEventListener('click', () => {

			container.innerHTML = hybridContentElement.innerHTML;
			readMoreClicked = true;

		});
		btnWrapper.append(btn);
		btnWrapper.append('.');
		const [ contentWrapper ] = container.children;
		contentWrapper.append(btnWrapper);

	};

	const trimContent = () => {

		if (readMoreClicked) return;
		const clonedDiv = document.createElement('div');
		clonedDiv.classList.add('hybridContent');
		const copyOfContentWrapper = contentWrapper.cloneNode(true);
		copyOfContentWrapper.innerHTML = '';
		clonedDiv.append(copyOfContentWrapper);
		hybridContentElement.parentNode.insertBefore(clonedDiv, hybridContentElement.nextSibling);
		let numberofLines = 0;
		let contentExceedMaxLine = false;
		const childElements = contentWrapper.childNodes;
		const elementList = [];
		let isLastElementCreated = false;
		childElements.forEach((node) => {

			if ([ 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'OL', 'UL', 'DIV' ].includes(node.nodeName)) {

				isLastElementCreated = false;
				elementList.push(node.cloneNode(true));

			} else {

				if (isLastElementCreated) elementList[elementList.length - 1].append(node.cloneNode(true));
				else {

					const element = document.createElement('div');
					element.append(node.cloneNode(true));
					elementList.push(element.cloneNode(true));
					isLastElementCreated = true;

				}

			}

		});
		const trimLastElementContent = () => {

			if (numberofLines > maxNumberOf) {

				const [ wrapper ] = trimmedConntent.children;
				let lastChild = wrapper.lastChild;
				if (lastChild.tagName === 'OL') lastChild = lastChild.lastChild;
				lastChild.innerHTML = getTrimmedContent(lastChild, numberofLines - maxNumberOf);

			}

		};

		for (let i = 0; i < elementList.length; i++) {

			if (numberofLines < maxNumberOf) {

				const currentElement = elementList[i];
				if (currentElement.tagName === 'OL') {

					const olChildren = currentElement.children;
					const clonedOl = currentElement.cloneNode(true);
					clonedOl.innerHTML = '';
					copyOfContentWrapper.appendChild(clonedOl);
					for (let j = 0; j < olChildren.length; j++) {

						if (numberofLines < maxNumberOf) {

							const liElement = olChildren[j];
							numberofLines += countNumberOfLine(liElement, clonedOl);
							contentExceedMaxLine = numberofLines >= maxNumberOf && i !== elementList.length - 1;

						}

					}

				} else {

					numberofLines += countNumberOfLine(currentElement, copyOfContentWrapper);
					contentExceedMaxLine = numberofLines >= maxNumberOf && i !== elementList.length - 1;

				}

			}

		}
		trimmedConntent.innerHTML = clonedDiv.innerHTML;
		trimLastElementContent();
		if (contentExceedMaxLine) renderReadMoreBtn(trimmedConntent, hybridContentElement);
		clonedDiv.remove();

	};

	if (contentWrapper) {

		trimContent();
		window.addEventListener('resize', trimContent);

	}

};
