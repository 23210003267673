import { utagLinkSort } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import initBreadCrumbShowMoreBtn from './initBreadCrumbShowMoreBtn';

function initBreadCrumb () {

	const breadcrumbHome = document.querySelector('.breadcrumbHome');
	if (breadcrumbHome) {

		breadcrumbHome.addEventListener('click', () => {

			utagLinkSort('Breadcrumb')({
				eventAction: 'Click',
				eventLabel: 'Home-L1'
			});
			setGenericEvent({
				event_name: 'breadcrumb_click',
				breadcrumb_info: 'Home-L1'
			});

		});

	}

	const breadcrumbLevel = document.querySelectorAll('.breadcrumbLevel');
	breadcrumbLevel.forEach((link) => {

		link.addEventListener('click', (e) => {

			utagLinkSort('Breadcrumb')({
				eventAction: 'Click',
				eventLabel: e.target.name
			});
			setGenericEvent({
				event_name: 'breadcrumb_click',
				breadcrumb_info: e.target.name
			});

		});

	});

	const breadcrumbRemoveLevel = document.querySelectorAll('.breadcrumbRemoveLevel');
	breadcrumbRemoveLevel.forEach((link) => {

		link.addEventListener('click', (e) => {

			utagLinkSort('Breadcrumb')({
				eventAction: 'Remove',
				eventLabel: e.target.name
			});
			setGenericEvent({
				event_name: 'breadcrumb_remove',
				breadcrumb_info: e.target.name
			});

		});

	});

	initBreadCrumbShowMoreBtn();

}

export default initBreadCrumb;
