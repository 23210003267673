type ToTitleCaseOptions = {
	multiple?: boolean;
}

export default function toTitleCase(str: string, { multiple = false }: ToTitleCaseOptions = {}) {

	if (typeof str !== 'string') {

		return str;

	}

	if (multiple && str.split(' ').length > 1) {

		const result = str.split(' ').reduce((acc, value) => {

			acc += `${value.substr(0, 1).toUpperCase()}${value.substr(1).toLowerCase()} `;

			return acc;

		}, '');

		return result.trimEnd();

	}

	return `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}`;

}
