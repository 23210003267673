import React from 'react';
import PropTypes from 'prop-types';

function OverlayCloseButton ({ hideOverlay, children = undefined, className = 'lpIcon-close02' }) {

	return (
		<button
			onClick={() => hideOverlay()}
			className="Overlay__contentWrapper__closeButton"
			aria-label="Close"
			type="button"
		>
			<span className={className} />
			{children}
		</button>
	);

}

OverlayCloseButton.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]),
	hideOverlay: PropTypes.func.isRequired,
	className: PropTypes.string
};

export default OverlayCloseButton;
