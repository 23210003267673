import getSearchUrl from '../getSearchUrl';

import redirectToContextualSearch from './redirectToContextualSearch';

function doContextualSearch (query) {

	if (query) {

		const searchUrl = getSearchUrl(query);
		redirectToContextualSearch(searchUrl);

	}

}

export default doContextualSearch;
