import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import SortFilterAttrValueGroupHeader from '../SortFilterAttrValueGroupHeader';
import SortFilterThumbnail from '../SortFilterThumbnail';
import isMappedTo from '../../isMappedTo';
import isValueGroupIndented from '../../isValueGroupIndented';
import getTotalNumberOfRowsMultiColumn from '../../getTotalNumberOfRowsMultiColumn';

import ValueLink from './ValueLink';

import './SortFilterAttributeValueList.scss';

const SortFilterAttributeValueList = ({
	htmlName,
	attributeValueGroups,
	attrDisplayTypeId,
	className,
	isGridView,
	showAttributeValueCounts = false,
	isSingleValueAttribute,
	attributeGroupName
}) => {

	const defaultClassName = 'SortFilterAttributeValueList';
	const fullClassName = classNames(defaultClassName, className, { [`${defaultClassName}--gridView`]: isGridView });
	const showAttrValueGroupBodyImage = isMappedTo.attribute.showAttrValGroupBodyImage(attrDisplayTypeId);
	const attrValGroups = isSingleValueAttribute ? [ attributeValueGroups[0] ] : attributeValueGroups;

	const renderValueLink = (attrValObj) => (
		<ValueLink
			attributeName={htmlName}
			isGridView={isGridView}
			showCounts={showAttributeValueCounts}
			attrDisplayTypeId={attrDisplayTypeId}
			attributeValue={attrValObj}
			isSingleValueAttribute={isSingleValueAttribute}
			attributeGroupName={attributeGroupName}
		/>
	);

	return (
		<>
			<ul className={fullClassName}>
				{attrValGroups.map(
					({
						AttributeValueGroupFilterDisplayTypeId,
						AttributeValues,
						HeaderImage,
						HeaderImageAltTag,
						BodyImage,
						BodyImageAltTag,
						Name,
						SortOrder
					}) => {

						const reactKeyName = `${Name}${SortOrder}`;
						const attrVals = isSingleValueAttribute ? [ AttributeValues[0] ] : AttributeValues;
						const attributeValuesCount = attrVals.length;

						// We are sorting AttributeValues to display Attribute with images first and Attribute without images at last.
						const imageAttributeValues = attrVals.filter((AttributeValue) => AttributeValue.HeaderImage);

						if (attributeValuesCount === 0) {

							return null;

						}

						const totalNumberOfRows = getTotalNumberOfRowsMultiColumn(attributeValuesCount);

						const showGroupView = isMappedTo.attributeValueGroup.groupDisplay.show(
							AttributeValueGroupFilterDisplayTypeId
						);
						const { isHeaderIndented, isBodyAndValuesIndented } = isValueGroupIndented(
							attrDisplayTypeId,
							AttributeValueGroupFilterDisplayTypeId
						);
						const attrValGroupHeaderStyle =							isMappedTo.attribute.attrValGroupHeaderStyle.which(attrDisplayTypeId);

						const multiValueGroupBaseClass = 'attributeValueGroupList';
						const valueGroupHeaderBaseClass = 'attributeValueGroupHeader';
						const valueGroupBodyImageBaseClass = 'valueGroupBodyImage';

						const multiValueGroupClassName = classNames(multiValueGroupBaseClass, {
							[`${multiValueGroupBaseClass}--indented`]: isBodyAndValuesIndented
						});
						const valueGroupHeaderClass = classNames(valueGroupHeaderBaseClass, {
							[`${valueGroupHeaderBaseClass}--indented`]: isHeaderIndented,
							[`${valueGroupHeaderBaseClass}--${attrValGroupHeaderStyle}`]: attrValGroupHeaderStyle
						});
						const valueGroupBodyImageClass = classNames(valueGroupBodyImageBaseClass, {
							[`${valueGroupBodyImageBaseClass}--indented`]: isBodyAndValuesIndented
						});

						if (isGridView) {

							return (
								<React.Fragment key={reactKeyName}>
									{imageAttributeValues.map((attributeValue) => (
										<li key={attributeValue.Url}>
											{renderValueLink(attributeValue)}
										</li>
									))}
								</React.Fragment>
							);

						}

						return (
							<li key={reactKeyName}>
								<>
									{showGroupView && (
										<>
											<SortFilterAttrValueGroupHeader
												attrDisplayTypeId={attrDisplayTypeId}
												Name={Name}
												className={valueGroupHeaderClass}
												headerImageSrc={HeaderImage}
												headerImageAltTag={HeaderImageAltTag}
											/>

											{showAttrValueGroupBodyImage && (
												<SortFilterThumbnail
													className={valueGroupBodyImageClass}
													src={BodyImage}
													alt={BodyImageAltTag}
												/>
											)}
										</>
									)}

									<ul
										className={multiValueGroupClassName}
										style={{ '--numberOfRows': totalNumberOfRows }}
									>
										{imageAttributeValues.map((attributeValue) => (
											<li key={attributeValue.Url}>
												{renderValueLink(attributeValue)}
											</li>
										))}
									</ul>
								</>
							</li>
						);

					}
				)}
			</ul>
			<ul className={fullClassName}>
				{attrValGroups.map(
					({
						AttributeValueGroupFilterDisplayTypeId,
						AttributeValues,
						HeaderImage,
						HeaderImageAltTag,
						BodyImage,
						BodyImageAltTag,
						Name,
						SortOrder
					}) => {

						const reactKeyName = `${Name}${SortOrder}`;
						const attrVals = isSingleValueAttribute ? [ AttributeValues[0] ] : AttributeValues;
						const attributeValuesCount = attrVals.length;

						// We are sorting AttributeValues to display Attribute with images first and Attribute without images at last.
						const nonImageAttributeValues = attrVals.filter(
							(AttributeValue) => !AttributeValue.HeaderImage
						);

						if (attributeValuesCount === 0) {

							return null;

						}

						const totalNumberOfRows = getTotalNumberOfRowsMultiColumn(attributeValuesCount);

						const showGroupView = isMappedTo.attributeValueGroup.groupDisplay.show(
							AttributeValueGroupFilterDisplayTypeId
						);
						const { isHeaderIndented, isBodyAndValuesIndented } = isValueGroupIndented(
							attrDisplayTypeId,
							AttributeValueGroupFilterDisplayTypeId
						);
						const attrValGroupHeaderStyle =							isMappedTo.attribute.attrValGroupHeaderStyle.which(attrDisplayTypeId);

						const multiValueGroupBaseClass = 'attributeValueGroupList';
						const valueGroupHeaderBaseClass = 'attributeValueGroupHeader';
						const valueGroupBodyImageBaseClass = 'valueGroupBodyImage';

						const multiValueGroupClassName = classNames(multiValueGroupBaseClass, {
							[`${multiValueGroupBaseClass}--indented`]: isBodyAndValuesIndented
						});
						const valueGroupHeaderClass = classNames(valueGroupHeaderBaseClass, {
							[`${valueGroupHeaderBaseClass}--indented`]: isHeaderIndented,
							[`${valueGroupHeaderBaseClass}--${attrValGroupHeaderStyle}`]: attrValGroupHeaderStyle
						});
						const valueGroupBodyImageClass = classNames(valueGroupBodyImageBaseClass, {
							[`${valueGroupBodyImageBaseClass}--indented`]: isBodyAndValuesIndented
						});

						if (isGridView) {

							return (
								<React.Fragment key={reactKeyName}>
									{nonImageAttributeValues.map((attributeValue) => (
										<li key={attributeValue.Url}>
											{renderValueLink(attributeValue)}
										</li>
									))}
								</React.Fragment>
							);

						}

						return (
							<li key={reactKeyName}>
								<>
									{showGroupView && (
										<>
											<SortFilterAttrValueGroupHeader
												attrDisplayTypeId={attrDisplayTypeId}
												Name={Name}
												className={valueGroupHeaderClass}
												headerImageSrc={HeaderImage}
												headerImageAltTag={HeaderImageAltTag}
											/>

											{showAttrValueGroupBodyImage && (
												<SortFilterThumbnail
													className={valueGroupBodyImageClass}
													src={BodyImage}
													alt={BodyImageAltTag}
												/>
											)}
										</>
									)}

									<ul
										className={multiValueGroupClassName}
										style={{ '--numberOfRows': totalNumberOfRows }}
									>
										{nonImageAttributeValues.map((attributeValue) => (
											<li key={attributeValue.Url}>
												{renderValueLink(attributeValue)}
											</li>
										))}
									</ul>
								</>
							</li>
						);

					}
				)}
			</ul>
		</>
	);

};

SortFilterAttributeValueList.propTypes = {
	htmlName: PropTypes.string,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired,
	attrDisplayTypeId: PropTypes.number,
	className: PropTypes.string,
	isGridView: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool,
	isSingleValueAttribute: PropTypes.bool,
	attributeGroupName: PropTypes.string
};

export default SortFilterAttributeValueList;
