import PropTypes from 'prop-types';
import React, { useState } from 'react';

import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import SortFilterAttrValueHeader from '../SortFilterAttrValueHeader';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import isMappedTo from '../../isMappedTo';
import getAttrValHeaderImageDimensions from '../../getAttrValHeaderImageDimensions';

const ValueLink = ({
	attributeName,
	isGridView,
	showCounts,
	attrDisplayTypeId,
	attributeValue,
	isSingleValueAttribute,
	attributeGroupName
}) => {

	const {
		HeaderImage, HeaderImageAltTag, Url, Description, Name, ProductCount
	} = attributeValue;

	const showAriaLabel =		isMappedTo.attribute.attrValHeaderVariation.imageWithTextDescriptionAsDisplay(attrDisplayTypeId);

	const [ utagCalled, setUtagCalled ] = useState(false);

	const handleValueClick = () => {

		if (!utagCalled) {

			sortFilterUtagLink(attributeName, Name, true, attributeGroupName);
			if (attributeGroupName && attributeGroupName !== attributeName) sortFilterGenericEvent('filter_click', attributeGroupName, attributeName, true);
			else sortFilterGenericEvent('filter_click', attributeName, Name, true);
			setUtagCalled(true);

		}

	};

	const { height: imageHeight, width: imageWidth } = getAttrValHeaderImageDimensions(attrDisplayTypeId);

	const thumbnail = {
		src: HeaderImage,
		alt: HeaderImageAltTag,
		height: imageHeight,
		width: imageWidth
	};

	const productCount = showCounts ? ProductCount : null;
	const valueName = isSingleValueAttribute ? attributeName : Name;

	return (
		<a href={Url} onClick={handleValueClick} aria-label={showAriaLabel ? valueName : undefined}>
			<SortFilterAttrValueHeader
				displayName={valueName}
				thumbnail={thumbnail}
				description={Description}
				isGridView={isGridView}
				productCount={productCount}
				attrDisplayTypeId={attrDisplayTypeId}
			/>
		</a>
	);

};

ValueLink.propTypes = {
	attributeName: PropTypes.string,
	showCounts: PropTypes.bool,
	isGridView: PropTypes.bool,
	attrDisplayTypeId: PropTypes.number,
	attributeValue: PropTypes.shape({
		HeaderImage: PropTypes.string,
		HeaderImageAltTag: PropTypes.string,
		Description: PropTypes.string,
		Url: PropTypes.string,
		Name: PropTypes.string,
		ProductCount: PropTypes.number
	}),
	isSingleValueAttribute: PropTypes.bool,
	attributeGroupName: PropTypes.string
};

export default ValueLink;
