import getSearchUrl from '../getSearchUrl';

import redirectToSaleSearch from './redirectToSaleSearch';

function doSaleSearch (query) {

	if (query) {

		const searchUrl = getSearchUrl(query);
		redirectToSaleSearch(searchUrl);

	}

}

export default doSaleSearch;
