import { useState } from 'react';

function useDropdownState (initiallyShown = false) {

	const [ show, setShow ] = useState(initiallyShown);
	const showDropdown = () => setShow(true);
	const hideDropdown = () => setShow(false);
	const toggleDropdown = () => {

		setShow(!show);

	};

	return {
		show,
		showDropdown,
		hideDropdown,
		toggleDropdown,
		setShow
	};

}

export default useDropdownState;
