import scaleTransform from './scaleTransform';

// Returns function that you can use to reverse-transform a full number to the discreet scaled step for the expo slider
const getFindOnScaleFunc = (min, max, exponentialSteps) => (value) => {

	const [ DISCRETE_MAX, expoValueTransform ] = scaleTransform(min, max, exponentialSteps);

	// If full min / full max of exponential slider passed, return discreet min or discreet max
	// Min is always 0 because ExpoRangeSlider component discreet min is always 0
	if (value <= min) {

		return 0;

	}

	if (value >= max) {

		return DISCRETE_MAX;

	}

	let closestScale = DISCRETE_MAX;

	for (let i = 0; i < DISCRETE_MAX; i += 1) {

		if (expoValueTransform(i) >= value) {

			closestScale = i;
			break;

		}

	}

	return closestScale;

};

export default getFindOnScaleFunc;
