import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Field, Input, Label } from 'components/Form';

const MultiSelectCheckboxField = ({
	className, name, children, value, id
}) => {

	const fullClassName = classNames(className, 'fieldCheckbox');

	return (
		<Field className={fullClassName}>
			<Input name={name} type="checkbox" value={value} id={id} />
			<Label htmlFor={id}>
				{children}
			</Label>
		</Field>
	);

};

MultiSelectCheckboxField.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	value: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};

export default MultiSelectCheckboxField;
