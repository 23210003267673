import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { uniqueId } from 'utilities/dom';
import { Input, Label } from 'components/Form';
import ExpoRangeSlider, { getFindOnScaleFunc, getDynamicExpoSteps } from 'components/Form/components/ExpoRangeSlider';
import { Button } from 'components/Button';

import SortFilterAttributeValueList from '../SortFilterAttributeValueList';

import './SortFilterSliderOneThumb.scss';

const filterInputRegex = /(\d+)?/;

const SortFilterSliderOneThumb = ({
	htmlName,
	attributeValueGroups,
	displayTypeData,
	hideAttributeValues,
	showAttributeValueCounts
}) => {

	const {
		MinimumRangeValue: min,
		MaximumRangeValue: max,
		Label: label,
		Prefix: prefix,
		PlusOnMax: plusOnMax = true
	} = displayTypeData;

	const id = useRef(uniqueId()).current;
	const EXPO_STEPS = getDynamicExpoSteps(min, max);
	const findScale = getFindOnScaleFunc(min, max, EXPO_STEPS);
	const [ rangeValue, setRangeValue ] = useState([ findScale(min) ]);
	const { watch, setValue } = useFormContext();
	const inputValue = watch(id);
	const onFinalChange = (newRangeValues, newExpoRangeValues) => {

		const sliderVal = newExpoRangeValues[0];
		const isInputOutOfBounds = inputValue !== '' && window.parseInt(inputValue) > max;
		let finalInputValue;

		// if an out of bound input value exists, it will persist if slider thumb is touched
		if (sliderVal === max) {

			finalInputValue = isInputOutOfBounds ? inputValue : `${max}${plusOnMax ? '+' : ''}`;

		} else {

			finalInputValue = sliderVal;

		}

		setValue(id, finalInputValue.toString());

	};
	const onInputChange = (e) => {

		const validMatches = e.target.value.match(filterInputRegex);

		if (validMatches && validMatches.length) {

			setValue(id, validMatches[0]);

		} else {

			setValue(id, '');

		}

	};
	const onInputBlur = () => {

		let parsed = window.parseInt(inputValue, 10);
		const isBelowMin = inputValue === '' || window.parseInt(inputValue, 10) < min;
		parsed = isBelowMin ? min : parsed;

		setValue(id, parsed.toString());
		setRangeValue([ findScale(parsed) ]);

	};

	return (
		<div className="SortFilterSliderOneThumb">
			<div className="SortFilterSliderOneThumb__inputWrapper">
				<Input
					name={id}
					id={id}
					type="text"
					defaultValue={min.toString()}
					onChange={onInputChange}
					onBlur={onInputBlur}
				/>
				{label && (
					<Label htmlFor={id}>
						{label}
					</Label>
				)}
			</div>

			<div className="SortFilterSliderOneThumb__sliderWrapper">
				<ExpoRangeSlider
					values={rangeValue}
					onChange={setRangeValue}
					onFinalChange={onFinalChange}
					min={min}
					max={max}
					exponentialSteps={EXPO_STEPS}
					plusOnMax={plusOnMax}
					showRangeLabels
					rangeLabelPrefix={prefix}
				/>
			</div>

			{/* TODO ADD ONCLICK AFTER FILTER APPLY LOGIC IS DETERMINED */}
			<Button buttonStyleVariant="secondary">Apply</Button>

			{!hideAttributeValues && attributeValueGroups && attributeValueGroups.length && (
				// TODO THIS NEEDS TO SEND DOWN VALUES SAME WAY AS MAPPEDATTRIBUTECOMPONENT DOES
				<SortFilterAttributeValueList
					htmlName={htmlName}
					attributeValueGroups={attributeValueGroups}
					showAttributeValueCounts={showAttributeValueCounts}
				/>
			)}
		</div>
	);

};

SortFilterSliderOneThumb.propTypes = {
	htmlName: PropTypes.string,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]),
	displayTypeData: PropTypes.shape({
		MinimumRangeValue: PropTypes.number,
		MaximumRangeValue: PropTypes.number,
		Label: PropTypes.string,
		Prefix: PropTypes.string,
		PlusOnMax: PropTypes.bool
	}),
	hideAttributeValues: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool
};

export default SortFilterSliderOneThumb;
