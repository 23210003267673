import React from 'react';

import ThumbLabel from './ThumbLabel';

// Using the closure for our custom properties
const Thumb =	(values, max, plusOnMax, displayedMax, displayedValues) => ({ props, index, isDragged }) => (
	<div className="RangeSlider__thumb-wrapper" aria-label={`Range Thumb ${index + 1}`} {...props}>
		<ThumbLabel
			values={values}
			index={index}
			// Can't use shorthand or closure won't work
			isDragged={isDragged}
			max={max}
			plusOnMax={plusOnMax}
			displayedValues={displayedValues}
			displayedMax={displayedMax}
		/>
		<div className={`RangeSlider__thumb ${isDragged ? 'RangeSlider__thumb--dragging' : ''}`} />
	</div>
);

export default Thumb;
