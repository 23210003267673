import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Dropdown, DropdownDisclosure, useDropdownState } from 'components/Dropdown';
import useChecklistDraftState from 'features/sort/useChecklistDraftState';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import SortFilterAttrHeader from '../SortFilterAttrHeader';
import SortFilterDropdownContent from '../SortFilterDropdownContent';
import SortFilterDropdownFooter from '../SortFilterDropdownFooter';
import isMappedTo from '../../isMappedTo';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import findAttributeValueById from '../../findAttributeValueById';

import MappedAttributeComponent from './MappedAttributeComponent';

import './NestedAttributeDropdown.scss';

const NestedAttributeDropdown = ({ attributeGroupFilterDisplayTypeId, attributeGroupName, attribute }) => {

	const attributeName = attribute.Name;
	const attributeDisplayTypeId = attribute.AttributeFilterDisplayTypeId;
	const attributeValueGroups = attribute.AttributeValueGroups;
	const isMultiSelect = isMappedTo.attribute.multiSelect(attributeDisplayTypeId);

	const {
		reset, valueList, applyDisabled, clearDisabled, applyMultiValueList
	} = useChecklistDraftState(attribute);
	const dropdown = useDropdownState(false);
	const disclosureRef = useRef();
	const [ isAnchoredRight, setIsAnchoredRight ] = useState(true);
	// slower browsers will flash the default anchored side before switching sides, we use this to avoid the change
	const [ isInvisible, setIsInvisible ] = useState(true);
	const [ isLoading, setIsLoading ] = useState(false);

	const disclosureButtonId = `${attributeName}-disclosure`;
	const defaultClassName = 'NestedAttributeDropdown';
	const defaultDisclosureClassName = `${defaultClassName}__disclosure`;
	const disclosureClassName = classNames(defaultDisclosureClassName, {
		[`${defaultDisclosureClassName}--invisible`]: isInvisible,
		[`${defaultDisclosureClassName}--active`]: dropdown.show
	});

	const onApplyClick = () => {

		if (applyDisabled || isLoading) {

			return;

		}

		setIsLoading(true);

		valueList?.forEach((val) => {

			const attributeValue = findAttributeValueById(attributeValueGroups, val).Name;
			sortFilterUtagLink(attributeGroupName, findAttributeValueById(attributeValueGroups, val).Name);
			sortFilterGenericEvent('filter_click', attributeGroupName, attributeValue, true);

		});

		applyMultiValueList();

	};

	const onCancelClick = () => {

		if (!clearDisabled) {

			reset();

		}

	};

	// we use side of viewport that has most space for nested menus
	useEffect(() => {

		const rect = disclosureRef.current.getBoundingClientRect();
		const isMoreSpaceOnRight = document.body.clientWidth - rect.right > rect.left;

		setIsAnchoredRight(isMoreSpaceOnRight);
		setIsInvisible(false);

	}, []);

	return (
		<div className={defaultClassName}>
			<DropdownDisclosure
				id={disclosureButtonId}
				className={disclosureClassName}
				ref={disclosureRef}
				buttonStyleVariant="none"
				{...dropdown}
			>
				{!isAnchoredRight && <span className="caret left" />}

				<SortFilterAttrHeader
					attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
					name={attributeName}
					headerImageSrc={attribute.HeaderImage}
					headerImageAltTag={attribute.HeaderImageAltTag}
				/>

				{isAnchoredRight && <span className="caret right" />}
			</DropdownDisclosure>

			{dropdown.show && (
				<Dropdown
					{...dropdown}
					alignEdge="top"
					anchorEdge={isAnchoredRight ? 'right' : 'left'}
					aria-labelledby={disclosureButtonId}
					hideOnEscape
					hideOnOutsideClick
					hideOnResize
					ref={disclosureRef}
				>
					<SortFilterDropdownContent
						isMultiSelect={isMultiSelect}
						hasAnyNested={false}
						attributeFilterDisplayTypeId={attributeDisplayTypeId}
						totalAttributes={attributeValueGroups[0].AttributeValues.length}
					>
						<MappedAttributeComponent attribute={attribute} />
					</SortFilterDropdownContent>

					{/* We only want to show buttons for multiselect.
					For unnested footer buttons, look in SortFilterAttrGroupDropdown */}
					{isMultiSelect && (
						<SortFilterDropdownFooter
							applyDisabled={applyDisabled}
							clearDisabled={clearDisabled}
							loading={isLoading}
							applyBtnOnClick={onApplyClick}
							clearBtnOnClick={onCancelClick}
						/>
					)}
				</Dropdown>
			)}
		</div>
	);

};

NestedAttributeDropdown.propTypes = {
	attributeGroupFilterDisplayTypeId: PropTypes.number.isRequired,
	attributeGroupName: PropTypes.string,
	attribute: PropTypes.shape({
		Name: PropTypes.string,
		AttributeFilterDisplayTypeId: PropTypes.number,
		HeaderImage: PropTypes.string,
		HeaderImageAltTag: PropTypes.string,
		AttributeValueGroups: PropTypes.arrayOf(
			PropTypes.shape({
				AttributeValues: PropTypes.arrayOf(PropTypes.object)
			})
		)
	})
};

export default NestedAttributeDropdown;
