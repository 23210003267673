// credit: https://pawelgrzybek.com/page-scroll-in-vanilla-javascript/
// Revised with explicit parentheticals and no decrement operation by tmares
const easings = {
	linear (t) {

		return t;

	},
	easeInQuad (t) {

		return t * t;

	},
	easeOutQuad (t) {

		return t * (2 - t);

	},
	easeInOutQuad (t) {

		return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

	},
	easeInCubic (t) {

		return t * t * t;

	},
	easeOutCubic (t) {

		return (t - 1) * (t - 1) * (t - 1) + 1;

	},
	easeInOutCubic (t) {

		return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

	},
	easeInQuart (t) {

		return t * t * t * t;

	},
	easeOutQuart (t) {

		return 1 - (t - 1) * (t - 1) * (t - 1) * (t - 1);

	},
	easeInOutQuart (t) {

		return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (t - 1) * (t - 1) * (t - 1) * (t - 1);

	},
	easeInQuint (t) {

		return t * t * t * t * t;

	},
	easeOutQuint (t) {

		return 1 + (t - 1) * (t - 1) * (t - 1) * (t - 1) * (t - 1);

	},
	easeInOutQuint (t) {

		return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (t - 1) * (t - 1) * (t - 1) * (t - 1) * (t - 1);

	}
};

export default easings;
