import { scrollTo } from 'ui/animation';

import initSplashVideo from './initSplashVideo';

function initSplashContent () {

	// sort splash buckets
	const $sortSplashBuckets = $('.sortSplashBuckets');
	$sortSplashBuckets.on('click', function () {

		window.location = $(this).find('a').attr('href');

	});

	// sort splash image
	$('#sortSplashSlider img').fadeIn();

	// sort splash video link (ex: LP-26427)
	// TODO: Could dynamically import initSplashVideo against watchVideo
	const watchVideo = document.querySelectorAll('.jsWatchVideo');
	initSplashVideo(watchVideo);

	// Sale sort splash
	const $sortSaleBanner = $('.sortSaleHeader .banner');
	$sortSaleBanner.on('click', (e) => {

		e.preventDefault();
		const sortResultProducts = document.getElementById('sortResultProducts');
		if (sortResultProducts) {

			scrollTo(800, sortResultProducts.offsetTop);

		}

	});

}

export default initSplashContent;
