import PropTypes from 'prop-types';
import React from 'react';

import './SortFilterAttributeValueDescription.scss';

const SortFilterAttributeValueDescription = ({ description }) => (
	<div className="SortFilterAttributeValue__description">
		{description}
	</div>
);

SortFilterAttributeValueDescription.propTypes = {
	description: PropTypes.string.isRequired
};

export default SortFilterAttributeValueDescription;
