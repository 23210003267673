import React from 'react';

const Track =	(className) => ({ props, children }) => (
	// eslint-disable-next-line jsx-a11y/no-static-element-interactions
	<div className={className} onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
		<div className="RangeSlider__track" ref={props.ref}>
			{children}
		</div>
	</div>
);

export default Track;
