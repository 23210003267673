import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './SortFilterGenericHeader.scss';

const SortFilterGenericHeader = ({ children, className }) => (
	<div className={classNames('SortFilterGenericHeader', className)}>
		{children}
	</div>
);

SortFilterGenericHeader.propTypes = {
	children: PropTypes.element,
	className: PropTypes.string
};

export default SortFilterGenericHeader;
