import { utagLinkSort } from 'features/tealium';

/**
 * @param {string} eventActionName - typically the attribute group name
 * @param {string} value - typically the attribute value name
 * @param {Boolean} eventActionPrefix - should it prepend 'Click-' to the first argument
 */
const sortFilterUtagLink = (eventActionName, value, eventActionPrefix = true, eventGroupActionName = undefined) => {

	if (eventGroupActionName && eventGroupActionName !== eventActionName) {

		utagLinkSort('Sort-Filter-Menu')({
			eventAction: `${eventActionPrefix ? 'Click-' : ''}${eventGroupActionName}`,
			eventLabel: eventActionName
		});

	} else {

		utagLinkSort('Sort-Filter-Menu')({
			eventAction: `${eventActionPrefix ? 'Click-' : ''}${eventActionName}`,
			eventLabel: value
		});

	}

};

export default sortFilterUtagLink;
