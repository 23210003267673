import utagLinkGeneric from './utagLinkGeneric';

function utagLinkOpenBox (eventCategory = 'Open Box') {

	return ({ eventAction, eventLabel = '' }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel
		});

	};

}

export default utagLinkOpenBox;
