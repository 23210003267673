import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import {
	Dropdown, DropdownDisclosure, DropdownWrapper, useDropdownState
} from 'components/Dropdown';
import useChecklistDraftState from 'features/sort/useChecklistDraftState';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import SortFilterDropdownContent from '../SortFilterDropdownContent';
import SortFilterDropdownFooter from '../SortFilterDropdownFooter';
import isMappedTo from '../../isMappedTo';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import SortFilterAttrGroupHeader from '../SortFilterAttrGroupHeader';
import getHorizBtnClassName from '../../getHorizBtnClassName';
import findAttributeValueById from '../../findAttributeValueById';

import './SortFilterAttrGroupDropdown.scss';

const SortFilterAttrGroupDropdown = ({
	name,
	attributes,
	className = '',
	children,
	attributeGroupFilterDisplayTypeId,
	headerImageSrc,
	headerImageAltTag,
	isFocusTrapActive
}) => {

	const firstAttribute = attributes[0];
	const attributeFilterDisplayTypeId = firstAttribute.AttributeFilterDisplayTypeId;

	const isMultiSelect = isMappedTo.attribute.multiSelect(attributeFilterDisplayTypeId);
	const hasNestedAttributes = isMappedTo.attributeGroup.nestedAttributes(attributeGroupFilterDisplayTypeId);
	const showApplyClearBtns = attributes.length === 1 && isMultiSelect && !hasNestedAttributes;

	const {
		reset, valueList, applyDisabled, clearDisabled, defaultValues, applyMultiValueList
	} =		useChecklistDraftState(firstAttribute);
	const dropdown = useDropdownState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const onApplyClick = () => {

		if (applyDisabled || isLoading) {

			return;

		}

		setIsLoading(true);

		valueList?.forEach((val) => {

			const attributeValue = findAttributeValueById(firstAttribute.AttributeValueGroups, val).Name;
			sortFilterUtagLink(name, attributeValue);
			sortFilterGenericEvent('filter_click', name, attributeValue, true);

		});

		applyMultiValueList();

	};
	const onCancelClick = () => {

		if (!clearDisabled) {

			reset();

		}

	};
	const onButtonClick = () => {

		if (!dropdown.show) {

			const { managedSortModels } = window.lp.pageData.sortFilter || {};
			if (managedSortModels?.[0]?.AttributeGroups) {

				const matchedAttr = managedSortModels?.[0]?.AttributeGroups.reduce(
					(attr, { Attributes }) => [ ...attr, ...Attributes ],
					[]
				).find(({ Name: attrName }) => attrName === name);
				if (matchedAttr?.DisplayType) {

					window.utag_data.fds_display_type = matchedAttr?.DisplayType;

				} else {

					const matchedAttrVar = managedSortModels?.[0]?.AttributeGroups.find(
						({ Name: attrName }) => attrName === name
					).Attributes;
					if (matchedAttrVar?.[0].DisplayType) {

						window.utag_data.fds_display_type = matchedAttrVar?.[0].DisplayType;

					}

				}

			}
			sortFilterUtagLink(`Expand-${name}`, '', false);
			sortFilterGenericEvent('filter_expand', name, '', true);

		}
		dropdown.toggleDropdown();

	};

	const fullClassName = classNames('SortFilterAttrGroupDropdown', className);
	const attributeGroupButtonId = `btnAttributeGroup__${name.replace(/\W/g, '-')}`;
	const disclosureClassName = getHorizBtnClassName(attributeGroupFilterDisplayTypeId);
	const disclosureRef = useRef();

	return (
		<DropdownWrapper className={fullClassName} data-unbxd-facet-type={name}>
			<DropdownDisclosure
				id={attributeGroupButtonId}
				className={disclosureClassName}
				ref={disclosureRef}
				onButtonClick={onButtonClick}
				{...dropdown}
			>
				<SortFilterAttrGroupHeader
					name={name}
					attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
					headerImageSrc={headerImageSrc}
					headerImageAltTag={headerImageAltTag}
					numApplied={defaultValues.length}
				/>
				<span className="caret down" />
			</DropdownDisclosure>
			{dropdown.show && (
				<Dropdown
					{...dropdown}
					alignEdge="autoHorizontal"
					hasSubMenu={hasNestedAttributes}
					anchorEdge="bottom"
					aria-labelledby={attributeGroupButtonId}
					hideOnEscape
					hideOnOutsideClick
					hideOnResize
					isFocusTrapActive={isFocusTrapActive}
					ref={disclosureRef}
				>
					<SortFilterDropdownContent
						isMultiSelect={isMultiSelect}
						hasAnyNested={hasNestedAttributes}
						attributeFilterDisplayTypeId={attributeFilterDisplayTypeId}
						totalAttributes={firstAttribute.AttributeValueGroups[0].AttributeValues.length}
					>
						{children}
					</SortFilterDropdownContent>

					{/* Footer apply and clear buttons here are only used if its 1 unnested multiselect attribute
					in the group, otherwise look in NestedAttributeDropdown for the nested buttons */}
					{showApplyClearBtns && (
						<SortFilterDropdownFooter
							applyDisabled={applyDisabled}
							clearDisabled={clearDisabled}
							loading={isLoading}
							applyBtnOnClick={onApplyClick}
							clearBtnOnClick={onCancelClick}
						/>
					)}
				</Dropdown>
			)}
		</DropdownWrapper>
	);

};

SortFilterAttrGroupDropdown.propTypes = {
	name: PropTypes.string.isRequired,
	attributes: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				AttributeFilterDisplayTypeId: PropTypes.number,
				ImageUrl: PropTypes.string,
				Name: PropTypes.string,
				SortOrder: PropTypes.number,
				Type: PropTypes.number,
				AttributeValueSeparator: PropTypes.string,
				EncodedAttributeName: PropTypes.string,
				AttributeId: PropTypes.number,
				AttributeValueGroups: PropTypes.arrayOf(
					PropTypes.shape({
						DisplayName: PropTypes.string,
						ImageUrl: PropTypes.string,
						SortOrder: PropTypes.number,
						Url: PropTypes.string,
						AttributeValues: PropTypes.array
					})
				)
			})
		)
	]).isRequired,
	children: PropTypes.oneOfType([ PropTypes.element ]),
	className: PropTypes.string,
	attributeGroupFilterDisplayTypeId: PropTypes.number.isRequired,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string,
	isFocusTrapActive: PropTypes.bool
};

export default SortFilterAttrGroupDropdown;
