import { getQueryStringParameters } from 'utilities/browser';

function getPathName () {

	let sortPath = document.location.href;

	if (document.location.pathname === '/popups/QuickLook.aspx' && window.parent.$('#bdProdSort').length) {

		sortPath = window.parent.location.href;

	}

	sortPath = sortPath.replace(/^(?:\/\/|[^/]+)*\//, '');

	if (sortPath.indexOf('/') !== 0) {

		sortPath = `/${sortPath}`;

		const queryParamIndex = sortPath.indexOf('?');

		if (queryParamIndex !== -1) {

			const queryStringParams = getQueryStringParameters(sortPath.substring(queryParamIndex));

			sortPath = sortPath.substring(0, queryParamIndex);

			if (queryStringParams.sfp) {

				sortPath = `${sortPath}?sfp=${queryStringParams.sfp}`;

			}

		}

	}
	return sortPath;

}

export default getPathName;
