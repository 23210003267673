import { setGenericEvent } from 'features/tealium/ga4/custom';

const trackSortMoreYouMayLike = () => {

	const selector = window.utag_data?.is_mobile === '1'
		? '#sortMoreYouMayLike .sortMoreYouMayLikeItem a[data-position]'
		: '#moreYouMayLikeContainer .moreYouLikeLink';
	const moreYouMayLikeLinks = document.querySelectorAll(selector);
	moreYouMayLikeLinks.forEach((linkElement) => {

		linkElement.addEventListener('click', () => {

			const { position } = linkElement.dataset;
			if (position) {

				setGenericEvent({
					event_name: 'sort_moreyoumaylike_click',
					item_position_number: position
				});

			}

		}, {
			once: true
		});

	});

};

export default trackSortMoreYouMayLike;
