const getAppliedAttributeValues = (attributeGroups) => attributeGroups.reduce((accum, curr) => {

	curr.Attributes.forEach((attr) => {

		// list of attribute value ids as strings
		const attrVals = [];
		attr.AttributeValueGroups
			.forEach((attrValGrp) => attrValGrp.AttributeValues
				.filter(({ IsSelected }) => IsSelected)
				.forEach((val) => attrVals.push(val.AttributeValueId.toString())));

		if (attrVals.length) {

			accum[attr.Name] = attrVals;

		}

	});

	return accum;

}, {});

export default getAppliedAttributeValues;
