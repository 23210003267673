const VideoPlayer = (playerId, {
	videoId, width, height, playerVars, events
}) => {

	const { YT } = window;
	const youtubeNoCookieHost = 'https://www.youtube-nocookie.com';

	if (!YT) {

		return null;

	}

	const videoPlayer = new YT.Player(playerId, {
		width,
		height,
		videoId,
		playerVars,
		events,
		host: youtubeNoCookieHost
	});

	return videoPlayer;

};

export default VideoPlayer;
