import PropTypes from 'prop-types';
import React from 'react';

import { CollapsibleContent, CollapsibleTitle, useCollapsibleState } from 'components/Collapsible';

import SortFilterAppliedFilters from './SortFilterAppliedFilters';

import './SortFilterAppliedFiltersCollapsible.scss';

const SortFilterAppliedFiltersCollapsible = ({ appliedFilters, initiallyOpen }) => {

	const appliedFilterWithoutPageAttribute = appliedFilters.filter(
		(appliedFilter) => appliedFilter.DisplayAttributeName.toLowerCase() !== 'page'
	);
	const collapsible = useCollapsibleState(initiallyOpen);
	const appliedAttributeValuesCount = appliedFilterWithoutPageAttribute.reduce(
		(acc, val) => acc + val.DisplayAttributeValues.length,
		0
	);
	const appliedFiltersHeaderText = `Applied Filters (${appliedAttributeValuesCount})`;

	return (
		appliedAttributeValuesCount > 0 && (
			<div className="SortFilterAppliedFiltersCollapsible">
				<CollapsibleTitle {...collapsible}>
					{appliedFiltersHeaderText}
				</CollapsibleTitle>
				<CollapsibleContent {...collapsible}>
					<SortFilterAppliedFilters appliedFilters={appliedFilterWithoutPageAttribute} />
				</CollapsibleContent>
			</div>
		)
	);

};

SortFilterAppliedFiltersCollapsible.propTypes = {
	appliedFilters: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				Name: PropTypes.string,
				AttributeValues: PropTypes.arrayOf(PropTypes.string),
				StepbackLink: PropTypes.string
			})
		)
	]).isRequired,
	initiallyOpen: PropTypes.bool
};

export default SortFilterAppliedFiltersCollapsible;
