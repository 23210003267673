import React from 'react';
import PropTypes from 'prop-types';

import SortFilterThumbnail from '../SortFilterThumbnail';
import filterDataSortingCallback from '../../filterDataSortingCallback';
import SortSearchFilterWrapper from '../SortSearchFilterWrapper';
import isMappedTo from '../../isMappedTo';

import mapSelectionTypeToAttrComponent from './mapSelectionTypeToAttrComponent';

const MappedAttributeComponent = ({
	attribute,
	isSingleValueAttribute,
	sliderOverMinMax = true,
	attributeGroupName
}) => {

	const {
		Name,
		BodyImage,
		BodyImageAltTag,
		AttributeFilterDisplayTypeId,
		DisplayTypeData,
		AttributeValueGroups,
		ShowAttributeValueCounts,
		CanonicalOrder
	} = attribute || {};

	const isAttributeSearchable = isMappedTo.attribute.searchable(AttributeFilterDisplayTypeId);
	const isGridView = isMappedTo.attribute.attrValGridView.any(AttributeFilterDisplayTypeId);
	const hideAttributeValues = isMappedTo.attribute.hideAttrVals(AttributeFilterDisplayTypeId);

	const sorted = AttributeValueGroups.sort(filterDataSortingCallback);
	const MappedSingleComponent = mapSelectionTypeToAttrComponent(AttributeFilterDisplayTypeId);
	const SingleComponent = isAttributeSearchable ? SortSearchFilterWrapper : MappedSingleComponent;
	const selectionType = isMappedTo.attribute.selectionType.which(AttributeFilterDisplayTypeId);
	const needsData = [ 'rangeSlider', 'slider', 'minMaxInputs' ].includes(selectionType);
	const parsedData = needsData && DisplayTypeData ? JSON.parse(DisplayTypeData) : null;

	// prevents entire display set from crashing if DisplayTypeData isn't passed from the data
	if (needsData && !parsedData) {

		// eslint-disable-next-line no-console
		console.error("DisplayTypeData wasn't provided for the slider");
		return null;

	}

	const showAttributeBodyImg = isMappedTo.attribute.showBodyImage(AttributeFilterDisplayTypeId);

	return (
		<>
			{showAttributeBodyImg && (
				<SortFilterThumbnail
					src={BodyImage}
					alt={BodyImageAltTag}
					className="SortFilterThumbnail__attrBodyImage"
				/>
			)}
			<SingleComponent
				htmlName={Name}
				attributeValueGroups={sorted}
				Child={isAttributeSearchable ? MappedSingleComponent : null}
				isGridView={isGridView}
				hideAttributeValues={hideAttributeValues}
				showAttributeValueCounts={ShowAttributeValueCounts}
				attrDisplayTypeId={AttributeFilterDisplayTypeId}
				isSingleValueAttribute={isSingleValueAttribute}
				// range props
				displayTypeData={parsedData}
				sliderOverMinMax={sliderOverMinMax}
				canonicalOrder={CanonicalOrder}
				attributeGroupName={attributeGroupName}
			/>
		</>
	);

};

MappedAttributeComponent.propTypes = {
	attribute: PropTypes.shape({
		ImageUrl: PropTypes.string,
		Name: PropTypes.string,
		SortOrder: PropTypes.number,
		Type: PropTypes.number,
		AttributeValueGroups: PropTypes.arrayOf(
			PropTypes.shape({
				DisplayName: PropTypes.string,
				ImageUrl: PropTypes.string,
				SortOrder: PropTypes.number,
				Url: PropTypes.string
			})
		),
		CanonicalOrder: PropTypes.number
	}).isRequired,
	attributeGroupName: PropTypes.string,
	isSingleValueAttribute: PropTypes.bool,
	sliderOverMinMax: PropTypes.bool
};

export default MappedAttributeComponent;
