import { utagViewArtificialPageView } from 'features/tealium';
import { getQueryStringValue } from 'utilities/browser';

function initCouponModal () {

	const trackArtificalPageViewEvent = utagViewArtificialPageView();
	// if url has coupon query string, open the coupon information in a modal popup

	if (getQueryStringValue('a') || getQueryStringValue('b')) {

		const couponHdr = document.querySelector('#couponHdr');
		const couponSmallPrint = document.querySelector('#couponSmallPrint');

		function setModalHeight () {

			const couponHdrText = couponHdr?.innerText.length >= 60;

			if (getQueryStringValue('a')) {

				return couponHdrText ? 400 : 370;

			}
			if (getQueryStringValue('b')) {

				return couponHdrText ? 300 : 285;

			}

		}

		if (couponSmallPrint) {

			if (couponSmallPrint?.innerText.length >= 1000) {

				couponSmallPrint.classList.add('scrollCouponPrint');

			} else {

				couponSmallPrint.classList.remove('scrollCouponPrint');

			}

		}

		$('body').lpModal({
			onInit: true,
			width: 792,
			height: setModalHeight(),
			content: $('#couponOverlay'),
			closeEvt: () => {

				trackArtificalPageViewEvent({ pageName: '' });

			}
		});

	}

}

export default initCouponModal;
