import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { uniqueId } from 'utilities/dom';
import { Input } from 'components/Form';
import ModelContext from 'features/sort/modelContext';

import applyRangeValues from '../../applyRangeValues';
import SortFilterAttributeValueList from '../SortFilterAttributeValueList';
import sortFilterUtagLink from '../../sortFilterUtagLink';

import './SortFilterMinMax.scss';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

const SortFilterMinMax = ({
	htmlName,
	attributeValueGroups,
	displayTypeData,
	hideAttributes,
	showAttributeValueCounts,
	canonicalOrder
}) => {

	const baseClassName = 'SortFilterMinMax';
	const filterInputRegex = /(\d{0,6})?(\.(\d{1,2})?)?/g;
	const idMin = useRef(uniqueId()).current;
	const idMax = useRef(uniqueId()).current;
	const { getValues, setValue } = useFormContext();
	const { CanonicalOrderedUrlFragments } = useContext(ModelContext);

	const {
		Prefix: prefix,
		DecimalTerm,
		IntegerTerm,
		InputTerm,
		MinAndUpSearchTerm,
		RangeSearchTerm,
		UnderAndMaxSearchTerm,
		SearchFilterPrefix,
		MinInputPlaceholder,
		MinInputTitle,
		MaxInputPlaceholder,
		MaxInputTitle,
		SubmitBtnLabel
	} = displayTypeData;

	const onChangeInputHandler = (e, isMin) => {

		const id = isMin ? idMin : idMax;
		const validMatches = e.target.value.match(filterInputRegex);

		if (validMatches && validMatches.length) {

			setValue(id, validMatches[0]);

		} else {

			setValue(id, '');

		}

	};

	const applyClickHandler = () => {

		const values = Object.values(getValues([ idMin, idMax ]));
		const [ min, max ] = values.map((val) => parseFloat(val.trim()) || '');
		let eventLabel = '';

		if (min && max) {

			eventLabel = min > max || max < min ? `$${max} - $${min}` : `$${min} - $${max}`;

		} else if (min) {

			eventLabel = `$${min} and up`;

		} else if (max) {

			eventLabel = `Under $${max}`;

		} else {

			eventLabel = `$${min} - $${max}`;

		}

		sortFilterUtagLink('click-Price-Custom', eventLabel, false);
		sortFilterGenericEvent('filter_click', 'Price', 'Custom', true);

		if (values && values.some((val) => val && val !== '')) {

			applyRangeValues(
				values[0],
				values[1],
				DecimalTerm,
				IntegerTerm,
				InputTerm,
				MinAndUpSearchTerm,
				UnderAndMaxSearchTerm,
				RangeSearchTerm,
				SearchFilterPrefix,
				CanonicalOrderedUrlFragments,
				canonicalOrder
			);

		}

	};

	const onBlurHandler = (e, isMin) => {

		const val = e.target.value;
		const inputId = isMin ? idMin : idMax;

		if (val === '' || Number.isNaN(Number(val))) {

			setValue(inputId, '');
			return;

		}

		if (!val.includes('.')) {

			setValue(inputId, parseFloat(val));

		} else {

			setValue(inputId, parseFloat(val).toFixed(2));

		}

	};

	const onKeyPressInputHandler = (e, isMin) => {

		if (e.which === 13) {

			onBlurHandler(e, isMin);
			applyClickHandler();

		}

	};

	const labelClassName = `${baseClassName}__label`;

	return (
		<>
			<div className={baseClassName}>
				<div className={`${baseClassName}__wrapper`}>
					<span className={labelClassName}>
						{prefix}
					</span>
					<Input
						ariaLabel="min price"
						className={`${baseClassName}__input`}
						name={idMin}
						id={idMin}
						type="tel"
						placeholder={MinInputPlaceholder}
						title={MinInputTitle}
						onChange={(e) => onChangeInputHandler(e, true)}
						onKeyPress={(e) => onKeyPressInputHandler(e, true)}
						onBlur={(e) => onBlurHandler(e, true)}
					/>
					<span className={labelClassName}>to</span>
					<Input
						ariaLabel="max price"
						className={`${baseClassName}__input`}
						name={idMax}
						id={idMax}
						type="tel"
						placeholder={MaxInputPlaceholder}
						title={MaxInputTitle}
						onChange={onChangeInputHandler}
						onKeyPress={onKeyPressInputHandler}
						onBlur={(e) => onBlurHandler(e)}
					/>
					<button
						type="button"
						className={`${baseClassName}__arrowApplyBtn`}
						aria-label={SubmitBtnLabel}
						onClick={applyClickHandler}
					/>
				</div>
			</div>

			{!hideAttributes && attributeValueGroups && attributeValueGroups.length && (
				<SortFilterAttributeValueList
					htmlName={htmlName}
					attributeValueGroups={attributeValueGroups}
					showAttributeValueCounts={showAttributeValueCounts}
				/>
			)}
		</>
	);

};

SortFilterMinMax.propTypes = {
	htmlName: PropTypes.string,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]),
	displayTypeData: PropTypes.shape({
		MinimumRangeValue: PropTypes.number,
		MaximumRangeValue: PropTypes.number,
		Prefix: PropTypes.string,
		PlusOnMax: PropTypes.bool,
		DecimalTerm: PropTypes.string,
		IntegerTerm: PropTypes.string,
		MinAndUpSearchTerm: PropTypes.string,
		RangeSearchTerm: PropTypes.string,
		UnderAndMaxSearchTerm: PropTypes.string,
		SearchFilterPrefix: PropTypes.string,
		InputTerm: PropTypes.string,
		MinInputPlaceholder: PropTypes.string,
		MinInputTitle: PropTypes.string,
		MaxInputPlaceholder: PropTypes.string,
		MaxInputTitle: PropTypes.string,
		SubmitBtnLabel: PropTypes.string
	}),
	hideAttributes: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool,
	canonicalOrder: PropTypes.number
};

export default SortFilterMinMax;
