/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { FormContext, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import React from 'react';

import './Form.scss';

const Form = ({
	className, defaultValues, validationSchema, children, onSubmit, method, mode = 'onBlur', ...rest
}) => {

	const methods = useForm({ defaultValues, validationSchema, mode });
	const { handleSubmit } = methods;

	return (
		<FormContext {...methods}>
			<form
				className={classNames('Form', className)}
				method={method}
				onSubmit={(e) => {

					e.preventDefault();

					if (method === 'POST') {

						return null;

					}

					return handleSubmit(onSubmit)();

				}}
				{...rest}
			>
				{children}
			</form>
		</FormContext>
	);

};

Form.propTypes = {
	className: PropTypes.string,
	defaultValues: PropTypes.objectOf(PropTypes.any),
	validationSchema: PropTypes.shape({}),
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	onSubmit: PropTypes.func,
	errors: PropTypes.shape({}),
	method: PropTypes.string,
	mode: PropTypes.oneOf([ 'onSubmit', 'onBlur', 'onChange', 'all' ])
};

export default Form;
