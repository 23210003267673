import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { Button } from 'components/Button';

import './SortFilterDropdownFooter.scss';

const SortFilterDropdownFooter = ({
	className,
	applyDisabled,
	clearDisabled,
	loading,
	applyBtnOnClick,
	clearBtnOnClick
}) => {

	const baseClassName = 'SortFilterDropdownFooter';
	const fullClassName = classNames(baseClassName, className);

	return (
		<div className={fullClassName}>
			<Button
				buttonStyleVariant="secondary"
				aria-disabled={applyDisabled}
				loading={loading}
				onClick={applyBtnOnClick}
			>
				Apply
			</Button>
			<Button buttonStyleVariant="tertiary" aria-disabled={clearDisabled} onClick={clearBtnOnClick}>
				Clear All
			</Button>
		</div>
	);

};

SortFilterDropdownFooter.propTypes = {
	className: PropTypes.string,
	applyDisabled: PropTypes.bool,
	clearDisabled: PropTypes.bool,
	loading: PropTypes.bool,
	applyBtnOnClick: PropTypes.func,
	clearBtnOnClick: PropTypes.func
};

export default SortFilterDropdownFooter;
