class CanonicalFragment {

	/**
	 * @param {Number} attributeId
	 * @param {Number} canonicalNumber
	 * @param {String} idBasedFragment
	 * @param {String} textBasedFragment
	 */
	constructor (attributeId, canonicalNumber, idBasedFragment, textBasedFragment) {

		this.attributeId = attributeId;
		this.canonicalNumber = canonicalNumber ?? 999;
		this.idBasedFragment = idBasedFragment;
		this.textBasedFragment = textBasedFragment;

	}

	get isMultiSelect () {

		return this.idBasedFragment.includes('~a') || this.idBasedFragment.includes('~o');

	}

}

export default CanonicalFragment;
