/**
 * @param {Object} canonicalOrderedUrlFragments
 * @param {string[]} canonicalOrderedUrlFragments.IdBasedList
 * @returns {boolean} if we should render the search bar in the filter menu
 */
const canShowFilterSearchBar = (canonicalOrderedUrlFragments) => {

	if (!canonicalOrderedUrlFragments) {

		return false;

	}

	const alreadySearched = canonicalOrderedUrlFragments.IdBasedList.some((fragment) => fragment.startsWith('s_'));

	return !alreadySearched && !lp.globals.isHospitality;

};

export default canShowFilterSearchBar;
