import PropTypes from 'prop-types';
import React from 'react';

import { utagLinkSort } from 'features/tealium';
import { ButtonLink } from 'components/Button';

import './SortFilterAppliedFilterClearButton.scss';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

const onClearClick = (value, name) => {

	utagLinkSort('Sort-Filter-Menu')({
		eventAction: `Remove-${name}`,
		eventLabel: value
	});
	sortFilterGenericEvent('filter_remove', name, value, true);

};

const SortFilterAppliedFilterClearButton = ({
	attributeName, attributeValue, stepbackLink, children
}) => (
	<span
		className="SortFilterAppliedFilterClearButton__container"
		role="button"
		onClick={() => {

			onClearClick(attributeValue, attributeName);

		}}
		onKeyUp={() => {}}
		tabIndex="-1"
	>
		<ButtonLink
			href={stepbackLink}
			className="SortFilterAppliedFilterClearButton"
			buttonStyleVariant="tertiary"
			title="remove"
			label={`Remove ${attributeValue} from results`}
		>
			{children}
		</ButtonLink>
	</span>
);

SortFilterAppliedFilterClearButton.propTypes = {
	attributeName: PropTypes.string.isRequired,
	attributeValue: PropTypes.string.isRequired,
	stepbackLink: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default SortFilterAppliedFilterClearButton;
