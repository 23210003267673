import { trackSortMoreYouMayLike, trackSortH1, utagLinkSort } from 'features/tealium';
import { prefetchWebpackAssetSet } from 'features/webpack';
import { domReady, initOnIntersection } from 'utilities/dom';
import { recentlyViewedGAEvents } from 'features/recently-viewed';
import { setGenericEvent, setSearch } from 'features/tealium/ga4/custom';
import LPGlider from 'ui/glider';

import dynamicImport from '../../../features/webpack/dynamicImport.macro';
import { initializeTrimHybridContent } from '../../../../Scripts/hybridContent/index';

import addRetryImage from './addRetryImage';
import initAddSchemeToWishList from './addSchemeToWishList';
import initBackToTop from './backToTop';
import initBreadCrumb from './breadCrumb';
import initCaptureProductImpressions from './captureProductImpressions';
import initDataCapture from './dataCapture';
import initFilterDisplaySet from './filterDisplaySet';
import initLandingDescription from './landingDescription';
import initMoreLikeThis from './moreLikeThis';
import initOpenBox from './openBox';
import initShopAll from './shopAll';
import initSplashContent from './splashContent';
import initStickyAttributeMenu from './stickyAttributeMenu';
import initTrackProductClick from './trackProductClick';
import initCouponModal from './couponModal';
import initSearchMoreLikeThisEvent from './trackSearchPageMoreLikeThis';
import initSearchSale from './saleSearch';
import initImageHoverEffect from './imageHoverEffect';
import initContextualSearchBar from './contextualSearchBar';
import initWishList from './wishlist';
import initFreeShippingTooltip from './freeShippingTooltip';
import initSortBy from './sortBy';

domReady(() => {

	const { globals, certona } = window.lp;
	const { canShowCertona, isKiosk } = globals;
	const sortResultContainers = document.querySelectorAll('.sortResultContainer');
	const sortSkuViews = document.querySelectorAll('.sortResultProdImg[data-sku]');

	// PLA pages have their own Recently Viewed widget bindings
	if (!window.lp._ProductListingAd) {

		// bind GA events when Recently Viewed Widget is ready
		document.addEventListener('recommendedItems:loaded', () => {

			recentlyViewedGAEvents(utagLinkSort());
			trackSortMoreYouMayLike();

		});

	}

	// Initialize Sort Hover Image Effect on Chandeliers (for now)
	if (window.location.pathname.startsWith('/products/chandeliers/')) {

		const sortHoverImages = document.querySelectorAll('.sortResultProdImg[data-hover-src]');
		initImageHoverEffect(sortHoverImages);

	}

	initBreadCrumb();
	initSplashContent();

	// Coupon information in a modal popup
	initCouponModal();

	// TODO: Prefix with if open box?
	initOpenBox();

	initSearchSale();

	initContextualSearchBar();

	initWishList();

	initializeTrimHybridContent('sortPagehybridContent');

	initFreeShippingTooltip();

	const addScheme = document.querySelector('.addScheme');
	if (addScheme) {

		// TODO: Maybe dynamic import for hospitality user only
		initAddSchemeToWishList(addScheme);

	}
	// Data Capture
	initDataCapture(sortResultContainers, sortSkuViews);
	// init product impressions capturing for utag
	initCaptureProductImpressions(sortSkuViews);

	// assign function to window for Dynamic Yield to use if it changes data after render
	window.initFilterDisplaySet = initFilterDisplaySet;
	initFilterDisplaySet();
	initSortBy();
	initStickyAttributeMenu();

	// Sort Items
	addRetryImage('.sortResultImgContainer img');
	initTrackProductClick(sortResultContainers);

	initMoreLikeThis();

	initShopAll();

	initLandingDescription();

	initBackToTop();

	initSearchMoreLikeThisEvent();

	// certona
	if (canShowCertona) {

		certona.getRecommendedItems();

	}

	// Sort list type, sort position, and sort sku tracking for PDP via recommendedItems
	// clear storageSession
	const $certonaItems = $('#certonaItems, #recentlyViewedContainer');
	$certonaItems.click(() => {

		sessionStorage.setItem('listType', '');
		sessionStorage.setItem('sortPosition', '');
		sessionStorage.setItem('sortSku', '');

	});

	const pixleeElement = document.getElementById('shopByRoomPixleeWidget');
	const marker = document.getElementById('pixleeWidgetScrollMarker');

	if (pixleeElement && globals) {

		const pixleeWidgetShowNextPrevButtonShopByRoomPageToggleOn = JSON.parse(
			globals.pixleeWidgetShowNextPrevButtonShopByRoomPageToggleOn
		);
		initOnIntersection(
			marker,
			() => {

				dynamicImport('desktop/sort-shopByRoom-pixleeInstagramFeed', './shopByRoomPixleeInstagramFeed').then(
					(module) => {

						const initShopByRoomInstagramFeed = module.default;
						initShopByRoomInstagramFeed(
							pixleeElement,
							pixleeWidgetShowNextPrevButtonShopByRoomPageToggleOn
						);

					}
				);

			},
			'0px 0px 500px 0px'
		);

	}
	const scrollButton = document.querySelector('#sortScrollButton');

	// animated scroll
	const sortScrollTo = (target) => {

		const element = document.getElementById(target);
		element.scrollIntoView({ behavior: 'smooth' });

	};

	if (scrollButton) {

		scrollButton.addEventListener('click', (e) => {

			e.preventDefault();
			sortScrollTo('sortFilterWrapper');

		});

	}

	prefetchWebpackAssetSet('desktop/product');

	if (isKiosk) {

		dynamicImport('desktop/sort-availableInStore', './availableInStore').then((module) => module.default());

	}

	// Track H1 values
	trackSortH1();

	const parallaxContainers = document.querySelectorAll('.lpParallaxContainer[data-parallax]');

	// parallax
	if (parallaxContainers.length) {

		dynamicImport('desktop/initParallax', './initParallax/index.js').then((module) => {

			const initParallax = module.default;
			initParallax();

		});

	}

	// GA4 custom event for search
	if (window.utag_data && window.utag_data.page_type === 'searchresults') {

		setSearch({
			search_keyword: window.utag_data?.search_keyword ?? '',
			search_provider: window.utag_data?.search_provider ?? '',
			search_results: window.utag_data?.search_results ?? '0'
		});

	}

	const fromProductFinderChandelier = document.referrer.indexOf('/product-finder/chandeliers/') !== -1;

	if (fromProductFinderChandelier) {

		setGenericEvent({
			event_name: 'finder_chandeliers_results_load'
		});

	}
	
	const sortGlider = document.querySelector('.sortGlider__container');

	if (sortGlider) {

		const SLIDES_TO_SHOW = 5;
		const gliderWrapperSelector = '.sortGlider';
		const gliderOptions = {
			slidesToShow: SLIDES_TO_SHOW,
			slidesToScroll: SLIDES_TO_SHOW,
			dots: false,
			rewind: false,
			duration: 0.3,
			arrows: {
				prev: `${gliderWrapperSelector} .glider-prev`,
				next: `${gliderWrapperSelector} .glider-next`
			}
		};

		document.querySelector(gliderWrapperSelector).classList.remove('hidden');

		sortGlider.addEventListener('glider-loaded', function () {

			const glider = LPGlider(this);
			glider.setActiveSlide(0);
			this.classList.remove('glider-loading');

		});

		new LPGlider(sortGlider, gliderOptions);

	}

});
