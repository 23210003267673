import { checkIfVideoAPIReady, VideoPlayer } from 'features/youtube';

function initLandingDescription () {

	const $playerDiv = $('#jsVideo');

	if ($playerDiv.length > 0) {

		checkIfVideoAPIReady().then(() => VideoPlayer('jsVideo', {
			videoId: $playerDiv.attr('data-videoid'),
			width: 560,
			height: 315,
			playerVars: {
				rel: 0
			}
		}));

	}

}

export default initLandingDescription;
