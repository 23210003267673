import utagLinkGeneric from './utagLinkGeneric';

function trackSortH1 () {

	// find first h1 on the page
	const h1 = document.querySelector('#lpContainer h1');
	const { isHospitality } = window.lp.globals;
	const { pathname, search } = window.location;
	const {
		isFreeTextSearch, isImageSearch, isShopByTrend, isShopByRoom
	} = window.lp.pageData;
	// sort page (e.g. not PLA iframe - /popups/QuickLook.aspx)
	const isSortPage = /\/products\/.+$/gi.test(pathname);
	const isClearanceOrSale = /((clearance|onsale)_view)|ds_daily-savings/gi.test(pathname);
	const isSubsequentPage = /\/page_.+$/gi.test(pathname);
	const isMobile = window.utag_data && window.utag_data.is_mobile;

	// abandon if no h1
	if (!h1) {

		return;

	}

	if (
		isSortPage
		&& !isHospitality
		&& !isShopByRoom
		&& !isShopByTrend
		&& !isClearanceOrSale
		&& !isFreeTextSearch
		&& !isImageSearch
		&& !isSubsequentPage
	) {

		const copyH1 = /^\/products\/openbox_view-open-box-items\//.test(pathname)
			? isMobile === '1'
				? document.querySelector('#lpContainer .heading.fanHeading h1').cloneNode(true)
				: document.querySelector('#lpContainer #SortPageH1Tag').cloneNode(true)
			: h1.cloneNode(true);

		const resultTag = copyH1.querySelector('.result');

		if (resultTag) {

			copyH1.removeChild(resultTag);

		}

		const value = copyH1.textContent.replace(/\s+/g, ' ').trim();

		utagLinkGeneric({
			eventCategory: 'Sort',
			eventAction: `H1 - ${value}`,
			eventLabel: `${pathname}${search}`,
			nonInteraction: 1
		});

	}

}

export default trackSortH1;
