import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import './Button.scss';

const ButtonLink = ({
	href, children, buttonStyleVariant, className, rel, target, title, label
}) => {

	const fullClassName = classNames(
		'Button',
		{ [`Button--${buttonStyleVariant}`]: Boolean(buttonStyleVariant) },
		className
	);

	return (
		<a href={href} className={fullClassName} rel={rel} target={target} title={title} aria-label={label}>
			{children}
		</a>
	);

};

ButtonLink.defaultProps = {
	buttonStyleVariant: undefined,
	className: undefined,
	rel: undefined,
	target: undefined
};

ButtonLink.propTypes = {
	href: PropTypes.string.isRequired,
	buttonStyleVariant: PropTypes.string,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string,
	rel: PropTypes.string,
	target: PropTypes.string,
	title: PropTypes.string,
	label: PropTypes.string
};

export default ButtonLink;
