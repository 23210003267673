import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';

function OverlayDisclosure ({
	onButtonClick, children, showOverlay, show, ...rest
}) {

	return (
		<Button onClick={onButtonClick ?? showOverlay} aria-haspopup aria-expanded={show} {...rest}>
			{children}
		</Button>
	);

}

OverlayDisclosure.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	showOverlay: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	onButtonClick: PropTypes.func
};

export default OverlayDisclosure;
