import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styleSettings from 'style/settings';
import { usePrevious } from 'utilities/react';

import './CollapsibleContent.scss';

function CollapsibleContent ({
	open, collapsibleContentId, collapsibleDisclosureId, children, onClose, onOpen
}) {

	const previousOpen = usePrevious(open);
	const contentRef = useRef();
	const [ height, setHeight ] = useState(!open ? 0 : undefined);
	const [ isAnimating, setIsAnimating ] = useState(false);
	const clearSyncAnimationTimerRef = useRef();
	const clearDelayAnimationTimerRef = useRef();

	const syncAnimationTiming = async () => {

		clearTimeout(clearSyncAnimationTimerRef.current);
		setIsAnimating(true);
		await (function () {

			return new Promise((resolve) => {

				clearSyncAnimationTimerRef.current = setTimeout(resolve, styleSettings.surfaceTransitionDuration);

			});

		}());
		setIsAnimating(false);

	};

	const animateOpen = async () => {

		const offsetHeight = contentRef.current ? contentRef.current.offsetHeight : '';
		setHeight(offsetHeight);
		await syncAnimationTiming();
		setHeight('');

	};
	const animateClose = async () => {

		clearTimeout(clearDelayAnimationTimerRef.current);
		const offsetHeight = contentRef.current ? contentRef.current.offsetHeight : 0;
		setIsAnimating(true);
		setHeight(offsetHeight);
		// Wait at least a frame so we render offsetHeight, and can animate transition to height 0
		await (function () {

			return new Promise((resolve) => {

				clearDelayAnimationTimerRef.current = setTimeout(resolve, 25);

			});

		}());
		setHeight(0);
		await syncAnimationTiming();

	};

	useEffect(() => {

		(async function () {

			// Handle animation and onClose, onOpen events excluding initial open state
			if (open === previousOpen || previousOpen === undefined) {

				return;

			}
			if (open) {

				await animateOpen();
				if (onOpen) {

					onOpen();

				}

			} else {

				await animateClose();
				if (onClose) {

					onClose();

				}

			}

		}());

	}, [ onClose, onOpen, open, previousOpen ]);

	return (
		<div
			style={{ height }}
			className="CollapsibleContent"
			id={collapsibleContentId}
			aria-hidden={!open}
			aria-labelledby={collapsibleDisclosureId}
			role="region"
		>
			<div className="CollapsibleContent__content" ref={contentRef}>
				{(open || previousOpen || isAnimating) && children}
			</div>
		</div>
	);

}

CollapsibleContent.defaultProps = {
	onClose: undefined,
	onOpen: undefined
};

CollapsibleContent.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	collapsibleContentId: PropTypes.string.isRequired,
	collapsibleDisclosureId: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	open: PropTypes.bool.isRequired
};

export default CollapsibleContent;
