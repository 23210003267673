import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';

const DropdownDisclosure = forwardRef(({
	onButtonClick, children, className, toggleDropdown, show, ...rest
}, ref) => {

	const fullClassName = classNames(className, { 'Button--active': show });

	return (
		<Button
			ref={ref}
			onClick={onButtonClick ?? toggleDropdown}
			className={fullClassName}
			aria-haspopup
			aria-expanded={show}
			{...rest}
		>
			{children}
		</Button>
	);

});

DropdownDisclosure.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string,
	toggleDropdown: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	onButtonClick: PropTypes.func
};

export default DropdownDisclosure;
