import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import classNames from 'classnames';

import { Form } from 'components/Form';
import {
	Drawer, OverlayContent, useOverlayState
} from 'components/Overlay';
import { utagViewArtificialPageView } from 'features/tealium';
import useVisibleAttributesFilter from 'features/sort/useVisibleAttributesFilter';
import getAppliedAttributeValues from 'features/sort/getAppliedAttributeValues';
import ModelContext from 'features/sort/modelContext';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import DrawerDisclosure from '../DrawerDisclosure';
import { SortFilterNestedDrawer, AttrGroupSingleValueLink } from '../SortFilterAttrGroupViews';
import SortFilterAttributeGroup from '../SortFilterAttributeGroup';
import SearchBarFilter from '../SearchBarFilter';
import canShowFilterSearchBar from '../SearchBarFilter/canShowFilterSearchBar';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import filterDataSortingCallback from '../../filterDataSortingCallback';
import { SortFilterAppliedFiltersCollapsible } from '../SortFilterAppliedFilters';
import isMappedTo from '../../isMappedTo';

import './SortFilterDisplaySetDrawer.scss';

const trackArtificalPageViewEvent = utagViewArtificialPageView();

const SortFilterDisplaySetDrawer = ({
	attributeGroups = [],
	appliedFilters = [],
	className = '',
	appliedFiltersInitiallyOpen
}) => {

	const defaultClassName = 'SortFilterDisplaySetDrawer';
	const fullClassName = classNames(defaultClassName, className);
	const filterButtonTriggerClassName = 'SortFilterButtonTrigger';
	const drawer = useOverlayState(false, '#filterDrawer');
	const { hideOverlay } = drawer;
	// Since we only need to know if there's at least 1 applied filter, we don't need to flatten the applied filters
	const hasAppliedFilters = appliedFilters && appliedFilters.length > 0;
	const headerClassName = classNames({ [`${defaultClassName}__header--withBorder`]: !hasAppliedFilters });
	const filterBtnHandler = () => {

		const eventLabel = window.location.pathname + window.location.search;
		sortFilterUtagLink('Open-Filters', eventLabel, false);
		sortFilterGenericEvent('filter_open_mobile');
		trackArtificalPageViewEvent({ pageName: 'Filters' });

	};
	const getVisibleAttributes = useVisibleAttributesFilter();
	const defaultValues = getAppliedAttributeValues(attributeGroups);
	const { CanonicalOrderedUrlFragments } = useContext(ModelContext);

	return (
		<div className={filterButtonTriggerClassName}>
			<div onClick={filterBtnHandler}>
				{hasAppliedFilters ? (
					<DrawerDisclosure text="Filter" imgSrc="/img/sort/filter-icon.svg" drawer={drawer} />
				) : (
					''
				)}
			</div>

			<Drawer
				{...drawer}
				className={fullClassName}
				aria-label="Filters Menu"
				anchorEdge="right"
				underlayClickExits={false}
				focusDialog
				scrollDisabled
				escapeExits
				fullScreen
			>
				<OverlayContent>
					<header className={headerClassName}>Filters</header>
					<main>
						{hasAppliedFilters && (
							<SortFilterAppliedFiltersCollapsible
								appliedFilters={appliedFilters}
								initiallyOpen={appliedFiltersInitiallyOpen}
							/>
						)}
						<Form method="POST" defaultValues={defaultValues}>
							{attributeGroups
								.sort(filterDataSortingCallback)
								.map(
									({
										Name,
										Attributes,
										AttributeGroupFilterDisplayTypeId,
										HeaderImage,
										HeaderImageAltTag,
										BodyImage,
										BodyImageAltTag
									}) => {

										const showAttributeGroupBodyImg = isMappedTo.attributeGroup.showBodyImage(
											AttributeGroupFilterDisplayTypeId
										);
										const attributeGroupBodyImage = showAttributeGroupBodyImg ? BodyImage : null;
										const hasNoDrawer = isMappedTo.attributeGroup.singleValueAttributeGroup(
											AttributeGroupFilterDisplayTypeId
										);

										const visibleAttributes = getVisibleAttributes(Attributes);
										if (visibleAttributes.length === 0) {

											return null;

										}

										if (hasNoDrawer) {

											return (
												<AttrGroupSingleValueLink
													key={Name}
													name={Name}
													attribute={visibleAttributes[0]}
													attributeGroupFilterDisplayTypeId={
														AttributeGroupFilterDisplayTypeId
													}
													headerImageSrc={HeaderImage}
													headerImageAltTag={HeaderImageAltTag}
													className="attrGroupNoDrawer"
												/>
											);

										}

										return (
											<SortFilterNestedDrawer
												key={Name}
												attrGroupLevel
												groupName={Name}
												hideParentOverlay={hideOverlay}
												attributes={visibleAttributes}
												attributeGroupFilterDisplayTypeId={AttributeGroupFilterDisplayTypeId}
												headerImageSrc={HeaderImage}
												headerImageAltTag={HeaderImageAltTag}
												render={(hideParentOverlay) => (
													<SortFilterAttributeGroup
														attributeGroupFilterDisplayTypeId={
															AttributeGroupFilterDisplayTypeId
														}
														attributes={visibleAttributes}
														name={Name}
														bodyImageSrc={attributeGroupBodyImage}
														bodyImageAltTag={BodyImageAltTag}
														hideParentOverlay={hideParentOverlay}
														drawerMode
													/>
												)}
											/>
										);

									}
								)}
							{canShowFilterSearchBar(CanonicalOrderedUrlFragments) && (
								<SearchBarFilter
									isMobile
									attributeGroups={attributeGroups}
									canonicalOrderedUrlFragments={CanonicalOrderedUrlFragments}
								/>
							)}
						</Form>
					</main>
				</OverlayContent>
			</Drawer>
		</div>
	);

};

SortFilterDisplaySetDrawer.defaults = {};

SortFilterDisplaySetDrawer.propTypes = {
	attributeGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired,
	className: PropTypes.string,
	appliedFilters: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				Name: PropTypes.string,
				AttributeValues: PropTypes.arrayOf(PropTypes.string),
				StepbackLink: PropTypes.string
			})
		)
	]),
	canonicalOrderedUrlFragments: PropTypes.shape({
		IdBasedList: PropTypes.arrayOf(PropTypes.string),
		TextBasedList: PropTypes.arrayOf(PropTypes.string),
		ShouldUseIdBasedUrl: PropTypes.bool
	}),
	appliedFiltersInitiallyOpen: PropTypes.bool
};

export default SortFilterDisplaySetDrawer;
