import React from 'react';
import PropTypes from 'prop-types';

import getSortDisplayTypeComponent from 'features/sort/getSortDisplayTypeComponent';

function AppSortAttributeFilters ({
	className, filterDisplayTypeId, defaultVisibleMenuCount, attributeGroups
}) {

	const SortDisplayTypeComponent = getSortDisplayTypeComponent(filterDisplayTypeId);

	return (
		<SortDisplayTypeComponent
			className={className}
			attributeGroups={attributeGroups}
			defaultVisibleMenuCount={defaultVisibleMenuCount}
		/>
	);

}

AppSortAttributeFilters.propTypes = {
	className: PropTypes.string,
	filterDisplayTypeId: PropTypes.number,
	defaultVisibleMenuCount: PropTypes.number,
	attributeGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired
};

export default AppSortAttributeFilters;
