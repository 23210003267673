/**
 * Mapping table used in isMappedTo helper.
 * Nested feature sets (such as attribute.selectionType) should use unique display type ids across all
 * the nested properties (should function as OR instead of AND)
 */
const displayTypeIdMappings = {
	set: {
		interfaceType: {
			horizontal: [ 1 ],
			drawer: [ 2 ]
		}
	},
	attributeGroup: {
		whiteButton: [ 9, 10, 11, 12, 13, 14, 30 ],
		headerStyle: {
			textOnly: [ 1, 4, 5, 8, 9, 12, 15, 16, 19 ],
			imageOnly: [ 3, 7, 11, 14 ],
			imageWithText: [ 2, 6, 10, 13, 17, 18, 20 ]
		},
		// think 'Pros Specials'
		singleValueAttributeGroup: [ 12, 13, 14, 19, 20, 32 ],
		showBodyImage: [ 4, 8 ],
		// adds a submenu dropdown on desktop, or an extra drawer level on mobile
		nestedAttributes: [ 5, 6, 7, 8, 16, 18 ],
		attrHeaderVariation: {
			textOnly: [ 5, 6, 7, 8, 16, 18 ],
			textWithImage: [],
			imageOnly: []
		},
		// makes all attributes into a single select list, only showing first value per attribute
		// uses attribute display name in place of the value name
		// used for sale dropdown, presents attributes as single attribute values
		singleValueAttribute: [ 29, 30, 31 ]
	},
	attribute: {
		selectionType: {
			valueList: [
				1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 34, 35,
				36, 37, 38, 39, 40, 41, 42, 43, 44, 47, 48, 49, 50, 51, 52, 57, 58, 61, 62, 63, 64, 67, 68, 69, 70
			],
			rangeSlider: [ 9, 10, 32, 33 ],
			slider: [ 7, 8, 30, 31 ],
			minMaxInputs: [ 45, 46 ],
			panelGrid: [ 53, 54, 65, 66 ],
			valueListWithColumns: [ 59, 60 ]
		},
		multiSelect: [
			6, 11, 12, 13, 14, 15, 16, 18, 20, 22, 23, 25, 27, 29, 35, 37, 40, 41, 42, 43, 44, 49, 52, 54, 66, 69, 70
		],
		searchable: [ 5, 6, 26, 27 ],
		attrValHeaderImageSize: {
			'70x70': [ 21, 22 ],
			'90x17': [ 47, 50 ],
			'96x64': [ 61 ],
			'105x18': [ 55 ],
			'135x90': [ 62, 63, 64 ],
			'150x100': [ 19, 20, 28, 29, 67, 68, 69, 70 ],
			'170x29': [ 56 ]
		},
		attrValGroupHeaderVariation: {
			textOnly: [
				1, 2, 4, 5, 6, 11, 12, 13, 15, 17, 18, 23, 24, 25, 26, 27, 34, 35, 36, 37, 39, 40, 42, 44, 45, 46, 51,
				52, 57, 58
			],
			imageWithText: [ 3, 14, 16, 38, 41, 43 ],
			imageOnly: []
		},
		attrValGroupHeaderStyle: {
			largeTextWithLine: [ 57, 58 ]
		},
		valGroupIndentation: {
			bodyAndValues: [
				1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 17, 18, 24, 25, 26, 27, 34, 35, 36, 37, 38, 39, 40, 41, 42,
				43, 45, 46, 51, 52
			],
			wholeGroup: [ 23, 44 ]
		},
		showAttrValGroupBodyImage: [ 4, 39 ],
		hideAttrVals: [ 7, 9, 30, 32 ],
		attrValGridView: {
			twoColumns: [ 19, 20, 28, 29, 68, 70 ],
			threeColumns: [ 61, 62, 67, 69 ],
			fourColumns: [ 21, 22, 63 ],
			fiveColumns: [ 64 ]
		},
		valueListColumns: {
			threeCol: [ 59 ],
			fourCol: [ 60 ]
		},
		attrValHeaderVariation: {
			textOnly: [
				1, 3, 4, 5, 6, 8, 10, 11, 13, 14, 23, 24, 25, 26, 27, 31, 33, 38, 39, 40, 41, 44, 45, 46, 48, 49, 57,
				58, 59, 60
			],
			textWithDescription: [ 17, 18, 34, 35 ],
			imageWithText: [ 2, 12, 15, 16, 19, 20, 21, 22, 28, 29, 36, 37, 42, 43, 61, 62, 63, 64, 67, 68, 69, 70 ],
			imageWithTextAndDescription: [ 53, 54, 65, 66 ],
			imageOnly: [],
			// use description as display name, for ex. used for customer rating "& Up"
			imageWithTextDescriptionAsDisplay: [ 47, 50, 55, 56 ],
			textOnlyDescriptionAsDisplay: [ 51, 52 ]
		},
		showBodyImage: [],
		multiColumnTenPerColumn: [ 48, 49 ]
	},
	attributeValueGroup: {
		// "flag" to display group styling
		// actual styling based on attribute DT mapping above (attrValGroupHeaderVariation, attrValGroupHeaderStyle, valGroupIndentation)
		groupDisplay: {
			show: [ 5, 6 ]
		}
	}
};

export default displayTypeIdMappings;
