import { utagLinkSort } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

function initBreadCrumbShowMoreBtn () {

	const showMoreBtn = document.querySelector('.showMoreBreadCrumbsBtn');
	const iconWrapper = showMoreBtn && showMoreBtn.querySelector('.showMoreBreadCrumbsBtn__icon');
	const btnTextWrapper = showMoreBtn && showMoreBtn.querySelector('.showMoreBreadCrumbsBtn__text-wrapper');
	const hiddenBreadCrumbs = document.querySelectorAll(
		'.breadCrumbValueWrapper.hidden, .breadCrumbValueWrapper .breadCrumbSeparator.hidden'
	);

	if (!showMoreBtn) {

		return;

	}

	showMoreBtn.addEventListener('click', () => {

		const breadCrumbsLists = document.querySelectorAll('.sortBreadCrumbWrapper .breadCrumbValueWrapper');
		const breadCrumbsListItems = [ ...breadCrumbsLists ].filter((node) => !node.classList.contains('hidden')).length;

		hiddenBreadCrumbs.forEach((bc) => bc.classList.toggle('hidden'));
		iconWrapper.innerText = iconWrapper.innerText === '+' ? '-' : '+';
		btnTextWrapper.innerText = btnTextWrapper.innerText === 'Show More' ? 'Show Less' : 'Show More';
		utagLinkSort('Breadcrumb')({
			eventAction: btnTextWrapper.innerText === 'Show More' ? 'Show-Less' : 'Show-More',
			eventLabel: breadCrumbsListItems
		});
		setGenericEvent({
			event_name: 'breadcrumb_click',
			breadcrumb_info: breadCrumbsListItems
		});

	});

}

export default initBreadCrumbShowMoreBtn;
