import { debounce } from 'lodash-es';

let debouncedResizeHandler;

function stickyClassHandler (element, threshold, offset) {

	const headerSearchInput = document.getElementById('search');
	const hasStickyFilter = element.classList.contains('stickyFilter');
	const isHeaderSearchElement = element.id === 'searchContainer';

	if (window.scrollY > threshold) {

		if (!hasStickyFilter) {

			let nonStickyHeaderSearchWidth;

			if (isHeaderSearchElement) {

				nonStickyHeaderSearchWidth = headerSearchInput.offsetWidth;

				// Recalculate Search box width when window is resized or rotated
				debouncedResizeHandler = debounce(() => {

					// Remove animation to avoid flicker
					element.classList.add('noSlideDownAnimation');

					// Remove sticky to get computed width of Search box in its original position in the header
					element.classList.remove('stickyFilter');

					headerSearchInput.style.width = '';
					headerSearchInput.style.width = `${headerSearchInput.offsetWidth}px`;

					element.classList.add('stickyFilter');

				}, 10);

				window.addEventListener('resize', debouncedResizeHandler);

			}

			element.classList.add('stickyFilter');

			if (isHeaderSearchElement) {

				// Sst sticky Search width the same as when it's not sticky
				headerSearchInput.style.width = `${nonStickyHeaderSearchWidth}px`;

			}

			document.body.style.marginTop = `${offset}px`;

		}

	} else if (hasStickyFilter) {

		element.classList.remove('stickyFilter');

		if (isHeaderSearchElement) {

			headerSearchInput.style.width = '';
			element.classList.remove('noSlideDownAnimation');
			window.removeEventListener('resize', debouncedResizeHandler);

		}

		document.body.style.marginTop = 0;

	}

}

function initStickyAttributeMenu () {

	let attributeWrapper;

	if (window.utag_data && window.utag_data.page_type) {

		const pageType = window.utag_data.page_type;

		if (pageType === 'searchresults') {

			attributeWrapper = document.querySelector('.searchContainer');

		} else {

			attributeWrapper = document.querySelector('.setDT-1.stickyEnabled');

		}

	}

	if (!attributeWrapper) {

		return;

	}

	// Timeout allows us to make sure analytics are captured for product image impressions.
	window.setTimeout(() => {

		const bodyMargin = attributeWrapper.offsetHeight;
		const heightBuffer = bodyMargin * 4;
		const attributeWrapperOffsetY = attributeWrapper.offsetTop + heightBuffer;

		stickyClassHandler(attributeWrapper, attributeWrapperOffsetY, bodyMargin);

		window.addEventListener(
			'scroll',
			debounce(() => {

				stickyClassHandler(attributeWrapper, attributeWrapperOffsetY, bodyMargin);

			}, 10)
		);

	}, 1000);

}

export default initStickyAttributeMenu;
