import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import MultiSelectCheckboxField from '../MultiSelectCheckboxField';
import SortFilterAttrValueHeader from '../SortFilterAttrValueHeader';
import SortFilterAttrValueGroupHeader from '../SortFilterAttrValueGroupHeader';
import filterDataSortingCallback from '../../filterDataSortingCallback';
import SortFilterThumbnail from '../SortFilterThumbnail';
import isMappedTo from '../../isMappedTo';
import isValueGroupIndented from '../../isValueGroupIndented';
import getAttrValHeaderImageDimensions from '../../getAttrValHeaderImageDimensions';

import './SortFilterAttributeMultiValueList.scss';

const CheckboxList = ({
	attributeValueGroups, attrDisplayTypeId, showAttributeValueCounts, htmlName, isGridView
}) => attributeValueGroups.map(
	({
		AttributeValueGroupFilterDisplayTypeId,
		Name,
		AttributeValues,
		HeaderImage,
		HeaderImageAltTag,
		BodyImage,
		BodyImageAltTag
	}) => {

		const showAttrValueGroupBodyImage = isMappedTo.attribute.showAttrValGroupBodyImage(attrDisplayTypeId);
		const showGroupView = isMappedTo.attributeValueGroup.groupDisplay.show(
			AttributeValueGroupFilterDisplayTypeId
		);
		const { isHeaderIndented, isBodyAndValuesIndented } = isValueGroupIndented(
			attrDisplayTypeId,
			AttributeValueGroupFilterDisplayTypeId
		);
		const { height: valueHeaderImageHeight, width: valueHeaderImageWidth } =				getAttrValHeaderImageDimensions(attrDisplayTypeId);

		const valueGroupHeaderBaseClass = 'valueGroupHeader';
		const valueGroupBodyImageBaseClass = 'valueGroupBodyImage';

		const multiValueGroupClassName = classNames({ valueGroup: isBodyAndValuesIndented });
		const valueGroupHeaderClass = classNames(valueGroupHeaderBaseClass, {
			[`${valueGroupHeaderBaseClass}--indented`]: isHeaderIndented
		});
		const valueGroupBodyImageClass = classNames(valueGroupBodyImageBaseClass, {
			[`${valueGroupBodyImageBaseClass}--indented`]: isBodyAndValuesIndented
		});

		return (
			<React.Fragment key={Name}>
				{showGroupView && (
					<>
						<SortFilterAttrValueGroupHeader
							attrDisplayTypeId={attrDisplayTypeId}
							Name={Name}
							className={valueGroupHeaderClass}
							headerImageSrc={HeaderImage}
							headerImageAltTag={HeaderImageAltTag}
						/>

						{showAttrValueGroupBodyImage && (
							<SortFilterThumbnail
								className={valueGroupBodyImageClass}
								src={BodyImage}
								alt={BodyImageAltTag}
							/>
						)}
					</>
				)}

				{AttributeValues.sort(filterDataSortingCallback).map(
					({
						AttributeValueId,
						Name: valueName,
						HeaderImage: valueHeaderImage,
						HeaderImageAltTag: valueHeaderImageAltTag,
						Description,
						ProductCount
					}) => {

						const thumbnail = {
							src: valueHeaderImage,
							alt: valueHeaderImageAltTag,
							width: valueHeaderImageWidth,
							height: valueHeaderImageHeight
						};

						const productCount = showAttributeValueCounts ? ProductCount : null;

						// Append attribute group name to make value unique.
						// For attr groups, a val "Blue" could be in both Canopy Color and Shade Color groups
						const uniqueKey = `${htmlName}${Name}${valueName}${AttributeValueId}`.replace(/[^\w]/g, '');

						const checkboxComponent = (
							<MultiSelectCheckboxField
								className={`${multiValueGroupClassName}`}
								name={htmlName}
								id={uniqueKey}
								value={`${AttributeValueId}`}
								key={uniqueKey}
							>
								<SortFilterAttrValueHeader
									displayName={valueName}
									thumbnail={thumbnail}
									description={Description}
									isGridView={isGridView}
									productCount={productCount}
									attrDisplayTypeId={attrDisplayTypeId}
								/>
							</MultiSelectCheckboxField>
						);

						return checkboxComponent;

					}
				)}
			</React.Fragment>
		);

	}
);

CheckboxList.propTypes = {
	attrDisplayTypeId: PropTypes.number,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired,
	htmlName: PropTypes.string.isRequired,
	isGridView: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool
};

export default CheckboxList;
