import { deleteSessionStorage } from 'utilities/browser';

function deleteReferringSortSessionData () {

	[ 'sortPosition', 'sortSku', 'sortPath', 'skuInputType', 'searchSkuCount' ].map((key) => deleteSessionStorage(key));

}

function getReferringSortSessionData () {

	// add listType, sortPosition, sortSku, sortPath from sessionStorage
	const listType = sessionStorage.getItem('listType');
	const sortPosition = sessionStorage.getItem('sortPosition');
	const sortSku = sessionStorage.getItem('sortSku');
	const sortPath = sessionStorage.getItem('sortPath');
	const skuInputType = sessionStorage.getItem('skuInputType');
	const searchSkuCount = sessionStorage.getItem('searchSkuCount');

	return {
		listType,
		sortPosition,
		sortSku,
		sortPath,
		skuInputType,
		searchSkuCount
	};

}

function setReferringSortSessionData (listType, sortResultContainer) {

	const sku = sortResultContainer.getAttribute('data-sku');
	const position = sortResultContainer.getAttribute('data-position');
	const skuInputType = sortResultContainer.getAttribute('data-sku-input-type');
	const searchSkuCount = sortResultProducts.getAttribute('data-search-sku-count');

	if (position && sku) {

		window.sessionStorage.setItem('sortPosition', position);
		window.sessionStorage.setItem('sortSku', sku);
		window.sessionStorage.setItem('skuInputType', skuInputType);

	}

	sessionStorage.setItem('listType', listType);

	if (searchSkuCount) {

		sessionStorage.setItem('searchSkuCount', searchSkuCount);

	}

}

export { deleteReferringSortSessionData, getReferringSortSessionData, setReferringSortSessionData };
