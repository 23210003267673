import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getSearchUrl } from 'features/search';
import { utagLinkSort } from 'features/tealium';
import { decodeUrlSearchTerm } from 'features/sort';

import './SearchBarFilter.scss';

const trackUtag = utagLinkSort();

const SearchBarFilter = ({ isMobile, attributeGroups, canonicalOrderedUrlFragments }) => {

	const [ searchValue, setSearchValue ] = useState('');
	const [ isTracked, setIsTracked ] = useState(false);
	const [ appliedCategory, setAppliedCategory ] = useState('');
	const searchAllLabel = `Search All ${appliedCategory}`;
	const className = classNames('SearchBarFilter', { 'SearchBarFilter--mobile': isMobile });

	useEffect(() => {

		const firstUrlChunk = canonicalOrderedUrlFragments.TextBasedList[0];
		const categoryIsSelected = !attributeGroups.some((attrGrp) => attrGrp.Name === 'Category');
		const notMultipleCategoriesSelected = !firstUrlChunk?.includes('~o') && !firstUrlChunk?.includes('~a');

		if (!firstUrlChunk?.includes('_') && categoryIsSelected && notMultipleCategoriesSelected) {

			const decodedCategoryName = !firstUrlChunk.includes(' ')
				? decodeUrlSearchTerm(firstUrlChunk)
				: firstUrlChunk;
			const sentenceCasedCategory = decodedCategoryName
				.split(' ')
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');

			setAppliedCategory(sentenceCasedCategory);

		}

	}, []);

	const handleSearchChange = (e) => {

		setSearchValue(e.target.value);

	};

	const handleSearchSubmit = () => {

		const value = searchValue.trim();

		if (!value) {

			return;

		}

		const searchUrl = getSearchUrl(value).replace('#', '');

		if (!isTracked) {

			trackUtag({
				eventAction: `Filter-${appliedCategory || 'No-Category'}-Search-Submit`,
				eventLabel: value
			});
			setIsTracked(true);

		}

		const { origin, pathname } = window.location;
		// redirect to search result page.
		// pagination set to page 1
		// Building url with origin and pathname makes sure to remove other possible query params like wopr
		window.location.href = `${origin + pathname.replace(/\/page_\d+/, '')}${searchUrl}?s=1`;

	};

	const handleKeyDown = (e) => {

		if (e.key === 'Enter') {

			handleSearchSubmit();

		}

	};

	return (
		<div id="SearchBarFilter" className={className}>
			{isMobile ? (
				<>
					<div className="fieldPairInline">
						<div className="field grow">
							<input
								className="adjacentButton"
								type="search"
								placeholder={searchAllLabel}
								aria-label={searchAllLabel}
								value={searchValue}
								onChange={handleSearchChange}
								onKeyDown={handleKeyDown}
								maxLength={window.lp.pageData.searchBarMaxQueryLength}
							/>
						</div>
						<div className="fieldHelper">
							<button type="button" className="calloutBtn" onClick={handleSearchSubmit}>
								Search
							</button>
						</div>
					</div>
				</>
			) : (
				<div className="searchContainer">
					<input
						className="searchInput"
						type="search"
						placeholder={searchAllLabel}
						aria-label={searchAllLabel}
						value={searchValue}
						onChange={handleSearchChange}
						onKeyDown={handleKeyDown}
					/>
					<button
						type="button"
						className="searchArrowBtn lpIcon-search"
						aria-label={searchAllLabel}
						onClick={handleSearchSubmit}
					/>
				</div>
			)}
		</div>
	);

};

SearchBarFilter.propTypes = {
	isMobile: PropTypes.bool,
	attributeGroups: PropTypes.arrayOf(
		PropTypes.shape({
			Name: PropTypes.string
		})
	),
	canonicalOrderedUrlFragments: PropTypes.shape({
		TextBasedList: PropTypes.arrayOf(PropTypes.string)
	}).isRequired
};

export default SearchBarFilter;
