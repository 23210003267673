import { v1 as uuidv1 } from 'uuid';

type ObservedElement = Element & { [k: string]: ReturnType<typeof setTimeout> };

function visibilityObserver (
	entryCallback: <T>(element: Element) => T,
	thresholdTime: number = 500,
	intersectionPercentage: number = 0.75
) {

	const timerId = `productVisibilityObserverTimer-${uuidv1()}`;
	const observer = new IntersectionObserver(
		(entries) => {

			entries.forEach((entry) => {

				const element = entry.target as ObservedElement;
				if (entry.intersectionRatio >= intersectionPercentage) {

					/* eslint-disable-next-line no-param-reassign */
					element[timerId] = setTimeout(() => {

						entryCallback(element);
						observer.unobserve(element);

					}, thresholdTime);

				} else {

					clearTimeout(element[timerId]);

				}

			});

		},
		{
			root: null,
			rootMargin: '0px',
			threshold: intersectionPercentage
		}
	);

	return observer;

}

export default visibilityObserver;
