import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { FieldGroup } from 'components/Form';

import getTotalNumberOfRowsMultiColumn from '../../getTotalNumberOfRowsMultiColumn';

import CheckboxList from './CheckboxList';

import './SortFilterAttributeMultiValueList.scss';

const SortFilterAttributeMultiValueList = ({
	attributeValueGroups,
	attrDisplayTypeId,
	checkedAttributeValueGroups = [],
	className,
	htmlName,
	isGridView,
	showAttributeValueCounts = false
}) => {

	const defaultClassName = 'SortFilterAttributeMultiValueList';
	const fullClassName = classNames(defaultClassName, className);

	const attributeValuesCount = attributeValueGroups[0]?.AttributeValues.length;

	const totalNumberOfRows = getTotalNumberOfRowsMultiColumn(attributeValuesCount);

	const attributeValueGroupsWithImage = _.deepClone(attributeValueGroups);
	const attributeValueGroupsWithoutImage = _.deepClone(attributeValueGroups);
	let attributeValueWithImage;
	let attributeValueWithoutImage;

	if (attributeValueGroupsWithImage.length > 0) {

		attributeValueWithImage = attributeValueGroupsWithImage[0]?.AttributeValues.filter(
			(AttributeValue) => AttributeValue.HeaderImage
		);
		attributeValueGroupsWithImage[0].AttributeValues = attributeValueWithImage;

	}

	if (attributeValueGroupsWithoutImage.length > 0) {

		attributeValueWithoutImage = attributeValueGroupsWithoutImage[0]?.AttributeValues.filter(
			(AttributeValue) => !AttributeValue.HeaderImage
		);
		attributeValueGroupsWithoutImage[0].AttributeValues = attributeValueWithoutImage;

	}
	return (
		<div className={fullClassName} style={{ '--numberOfRows': totalNumberOfRows }}>
			{attributeValueWithImage?.length > 0 && (
				<FieldGroup>
					<CheckboxList
						attributeValueGroups={attributeValueGroupsWithImage}
						attrDisplayTypeId={attrDisplayTypeId}
						htmlName={htmlName}
						isGridView={isGridView}
						showAttributeValueCounts={showAttributeValueCounts}
					/>
				</FieldGroup>
			)}
			{attributeValueWithoutImage?.length > 0 && (
				<FieldGroup>
					<CheckboxList
						attributeValueGroups={attributeValueGroupsWithoutImage}
						attrDisplayTypeId={attrDisplayTypeId}
						htmlName={htmlName}
						isGridView={isGridView}
						showAttributeValueCounts={showAttributeValueCounts}
					/>
				</FieldGroup>
			)}
			{/* checked items that separately appear on the bottom of a searched list */}
			{checkedAttributeValueGroups.length > 0 && (
				<>
					<div className={`${defaultClassName}__separator`} />
					<CheckboxList
						attributeValueGroups={checkedAttributeValueGroups}
						attrDisplayTypeId={attrDisplayTypeId}
						htmlName={htmlName}
						isGridView={isGridView}
						showAttributeValueCounts={showAttributeValueCounts}
					/>
				</>
			)}
		</div>
	);

};

SortFilterAttributeMultiValueList.propTypes = {
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired,
	attrDisplayTypeId: PropTypes.number,
	checkedAttributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]),
	className: PropTypes.string,
	htmlName: PropTypes.string.isRequired,
	isGridView: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool
};

export default SortFilterAttributeMultiValueList;
