import { primaryAttributesMapping } from '../configs';

/**
 * Returns Primary Attributes from dataset for a sku. If primary attribute is not found then returns empty values
 * @param {DOMStringMap} dataset
 * @returns {Object} primary attributes from dataset in tealium format
 */
const getPrimaryAttributesFromDataset = (dataset) => Object.keys(primaryAttributesMapping).reduce((acc, name) => {

	acc[name] = dataset[name] ?? '';
	return acc;

}, {});

export default getPrimaryAttributesFromDataset;
