import getPersistedQueryParameters from "./getPersistedQueryParameters";

const getSortByUrl = (sortByNumber: string) => {
	const { origin, pathname } = window.location;
	const sortByQueryParameter = sortByNumber === '1'
		? ''
		: getPersistedQueryParameters([{ key: 'sb', value: sortByNumber }]);
	return `${origin}${pathname}${sortByQueryParameter}`;
};

export default getSortByUrl;
