import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import classNames from 'classnames';

import { Form } from 'components/Form';
import { Button } from 'components/Button';
import useVisibleAttributesFilter from 'features/sort/useVisibleAttributesFilter';
import getAppliedAttributeValues from 'features/sort/getAppliedAttributeValues';
import ModelContext from 'features/sort/modelContext';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import { SortFilterAttrGroupDropdown, AttrGroupSingleValueLink } from '../SortFilterAttrGroupViews';
import SortFilterAttributeGroup from '../SortFilterAttributeGroup';
import SearchBarFilter from '../SearchBarFilter';
import canShowFilterSearchBar from '../SearchBarFilter/canShowFilterSearchBar';
import filterDataSortingCallback from '../../filterDataSortingCallback';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import isMappedTo from '../../isMappedTo';
import getHorizBtnClassName from '../../getHorizBtnClassName';

import './SortFilterDisplaySetDropdowns.scss';

const SortFilterDisplaySetDropdowns = ({ attributeGroups = [], defaultVisibleMenuCount, className = '' }) => {

	const fullClassName = classNames('SortFilterDisplaySetDropdowns', className);
	const isPssSortFilterOrderEnabled = window.lp.globals.pssEnableSortFilterOrder;
	const { path } = window.lp.pageData;
	const showAllSortFiltersStorage = path && sessionStorage.getItem('lp.showAllSortFilters') === path;
	const [ isShowingAll, setIsShowingAll ] = useState(!isPssSortFilterOrderEnabled || showAllSortFiltersStorage);
	const { CanonicalOrderedUrlFragments } = useContext(ModelContext);
	const canShowSearch = canShowFilterSearchBar(CanonicalOrderedUrlFragments);
	const getVisibleAttributes = useVisibleAttributesFilter();
	const defaultValues = getAppliedAttributeValues(attributeGroups);

	const moreFiltersBtnHandler = () => {

		setIsShowingAll(true);
		sessionStorage.setItem('lp.showAllSortFilters', path);
		const eventLabel = window.location.pathname + window.location.search;
		sortFilterUtagLink('Open-More-Filters', eventLabel, false);
		sortFilterGenericEvent('filter_open_more_desktop');

	};

	if (!showAllSortFiltersStorage) {

		sessionStorage.removeItem('lp.showAllSortFilters');

	}

	const onlyVisibleAttributesGroups = attributeGroups
		.map((ag) => ({ ...ag, visibleAttributes: getVisibleAttributes(ag.Attributes) }))
		.filter((ag) => ag.visibleAttributes.length !== 0);

	return (
		<div className={fullClassName}>
			<Form method="POST" defaultValues={defaultValues}>
				{onlyVisibleAttributesGroups
					.sort(filterDataSortingCallback)
					.slice(0, isShowingAll ? onlyVisibleAttributesGroups.length : defaultVisibleMenuCount)
					.map(
						({
							Name,
							visibleAttributes,
							BodyImage,
							BodyImageAltTag,
							AttributeGroupFilterDisplayTypeId,
							HeaderImage,
							HeaderImageAltTag
						}) => {

							const showAttributeGroupBodyImg = isMappedTo.attributeGroup.showBodyImage(
								AttributeGroupFilterDisplayTypeId
							);
							const attributeGroupBodyImage = showAttributeGroupBodyImg ? BodyImage : null;
							const isSingleValueFilter = isMappedTo.attributeGroup.singleValueAttributeGroup(
								AttributeGroupFilterDisplayTypeId
							);

							if (isSingleValueFilter) {

								return (
									<AttrGroupSingleValueLink
										key={Name}
										name={Name}
										attribute={visibleAttributes[0]}
										attributeGroupFilterDisplayTypeId={AttributeGroupFilterDisplayTypeId}
										headerImageSrc={HeaderImage}
										headerImageAltTag={HeaderImageAltTag}
										className={getHorizBtnClassName(AttributeGroupFilterDisplayTypeId, true)}
									/>
								);

							}

							return (
								<SortFilterAttrGroupDropdown
									key={Name}
									name={Name}
									attributes={visibleAttributes}
									attributeGroupFilterDisplayTypeId={AttributeGroupFilterDisplayTypeId}
									headerImageSrc={HeaderImage}
									headerImageAltTag={HeaderImageAltTag}
									isFocusTrapActive
								>
									<SortFilterAttributeGroup
										attributeGroupFilterDisplayTypeId={AttributeGroupFilterDisplayTypeId}
										name={Name}
										attributes={visibleAttributes}
										bodyImageSrc={attributeGroupBodyImage}
										bodyImageAltTag={BodyImageAltTag}
										dropdownMode
									/>
								</SortFilterAttrGroupDropdown>
							);

						}
					)}
				{!isShowingAll && onlyVisibleAttributesGroups.length > defaultVisibleMenuCount && (
					<Button
						type="button"
						className="moreFiltersBtn"
						onClick={moreFiltersBtnHandler}
						buttonStyleVariant="link"
					>
						<span>More Filters</span>
						<strong> +</strong>
					</Button>
				)}

				{canShowSearch && (isShowingAll || onlyVisibleAttributesGroups.length <= defaultVisibleMenuCount) && (
					<SearchBarFilter
						attributeGroups={onlyVisibleAttributesGroups}
						canonicalOrderedUrlFragments={CanonicalOrderedUrlFragments}
					/>
				)}
			</Form>
		</div>
	);

};

SortFilterDisplaySetDropdowns.propTypes = {
	attributeGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired,
	defaultVisibleMenuCount: PropTypes.number,
	className: PropTypes.string
};

export default SortFilterDisplaySetDropdowns;
