import PropTypes from 'prop-types';
import * as React from 'react';

const ThumbLabel = ({
	values, index, isDragged, max, plusOnMax, displayedMax, displayedValues
}) => {

	const displayMax = displayedMax !== undefined ? displayedMax : max;
	const displayValues = displayedValues !== undefined ? displayedValues : values;
	// We don't want to show plus on first thumb's label if there are multiple thumbs
	const showPlus = plusOnMax && ((values.length > 1 && index !== 0) || values.length === 1);

	return (
		<div
			className={`RangeSlider__thumbLabel ${!isDragged ? 'RangeSlider__thumbLabel--hidden' : ''}`}
			data-label={index}
		>
			{/* The number in the label */}
			{showPlus && values[index] === max ? `${displayMax}+` : displayValues[index]}
		</div>
	);

};

ThumbLabel.defaultProps = {
	values: [],
	index: undefined,
	isDragged: false,
	max: undefined,
	plusOnMax: undefined
};

ThumbLabel.propTypes = {
	values: PropTypes.arrayOf(PropTypes.number),
	index: PropTypes.number,
	isDragged: PropTypes.bool,
	max: PropTypes.number,
	plusOnMax: PropTypes.bool,
	displayedMax: PropTypes.number,
	displayedValues: PropTypes.arrayOf(PropTypes.number)
};

export default ThumbLabel;
