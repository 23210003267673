import React from 'react';
import { render } from 'react-dom';

import SortByDropdown from 'features/sort/components/SortByDropdown';

function initSortBy () {

	const container = document.getElementById('sortBy');

	if (container) {

		render(<SortByDropdown />, container);

	}

}

export default initSortBy;
