import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import DebouncedInput from 'components/DebouncedInput';
import { Label } from 'components/Form';
import { uniqueId } from 'utilities/dom';

import './SortSearchFilterWrapper.scss';

const SortSearchFilterWrapper = ({
	attributeValueGroups,
	htmlName,
	Child,
	gridView = false,
	showAttributeValueCounts = false,
	...rest
}) => {

	const { getValues, triggerValidation } = useFormContext();
	const id = useRef(uniqueId()).current;
	const isInCheckedValues = (valId) => getValues()[htmlName] && getValues()[htmlName].includes(valId);
	const getReduceFunc = (filterFunc) => (accum, cur) => {

		const curCopy = { ...cur };
		curCopy.AttributeValues = curCopy.AttributeValues.filter(filterFunc);

		if (curCopy.AttributeValues.length > 0) {

			accum.push(curCopy);

		}

		return accum;

	};

	const [ searchedValues, setSearchedValues ] = useState(attributeValueGroups);
	// checked values that separately appear on the bottom of a search
	const [ checkedValues, setCheckedValues ] = useState([]);

	useEffect(() => {

		// TODO we should investigate why we need to run this twice for react-hook-forms to sync
		// This fixes sync issues with the apply and cancel button when filtering a search
		triggerValidation(null, true);
		triggerValidation(null, true);

	}, [ attributeValueGroups, checkedValues ]);

	const onChange = (inputValue) => {

		const formattedInput = inputValue.trim().toLowerCase();

		if (formattedInput === '') {

			setSearchedValues(attributeValueGroups);
			setCheckedValues([]);
			return;

		}

		const searchedList = attributeValueGroups.reduce(
			getReduceFunc(
				(val) => val.Name
					&& val.Name.toLowerCase().includes(formattedInput)
					&& !isInCheckedValues(`${val.AttributeValueId}`)
			),
			[]
		);

		const checkedList = attributeValueGroups.reduce(
			getReduceFunc((val) => isInCheckedValues(`${val.AttributeValueId}`)),
			[]
		);

		setSearchedValues(searchedList);
		setCheckedValues(checkedList);

	};
	const onKeyPress = (e) => {

		if (e.which === 13) {

			e.preventDefault();

		}

	};

	return (
		<>
			<div className="sortSearchInput">
				<Label htmlFor={id}>Search</Label>
				<DebouncedInput id={id} onChange={onChange} onKeyPress={onKeyPress} debounceMs={250} clearable />
			</div>

			{searchedValues.length === 0 && <div className="sortSearchNoResults">No results for search</div>}

			{(searchedValues.length > 0 || checkedValues.length > 0) && Child && (
				<Child
					attributeValueGroups={searchedValues}
					htmlName={htmlName}
					gridView={gridView}
					showAttributeValueCounts={showAttributeValueCounts}
					checkedAttributeValueGroups={checkedValues}
					{...rest}
				/>
			)}
		</>
	);

};

SortSearchFilterWrapper.propTypes = {
	attributeValueGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
	htmlName: PropTypes.string.isRequired,
	Child: PropTypes.func.isRequired,
	gridView: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool
};

export default SortSearchFilterWrapper;
