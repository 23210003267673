import PropTypes from 'prop-types';
import React, { useState } from 'react';

import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import SortFilterAttrGroupHeader from '../SortFilterAttrGroupHeader';
import sortFilterUtagLink from '../../sortFilterUtagLink';

// Shared between Set DT 1 and 2 (Desktop and Mobile)
const AttrGroupSingleValueLink = ({
	name,
	attribute,
	attributeGroupFilterDisplayTypeId,
	headerImageSrc,
	headerImageAltTag,
	className
}) => {

	const [ utagCalled, setUtagCalled ] = useState(false);

	const { Url: firstValUrl, Name: firstValName } = attribute.AttributeValueGroups[0].AttributeValues[0];
	const handleValueClick = () => {

		if (!utagCalled) {

			const { managedSortModels } = window.lp.pageData.sortFilter || {};
			if (managedSortModels?.[0]?.AttributeGroups) {

				const matchedAttr = managedSortModels?.[0]?.AttributeGroups.reduce(
					(attr, { Attributes }) => [ ...attr, ...Attributes ],
					[]
				).find(({ Name: attrName }) => attrName === name);
				if (matchedAttr?.DisplayType) {

					window.utag_data.fds_display_type = matchedAttr?.DisplayType;

				} else {

					const matchedAttrVar = managedSortModels?.[0]?.AttributeGroups.find(
						({ Name: attrName }) => attrName === name
					).Attributes;
					if (matchedAttrVar?.[0].DisplayType) {

						window.utag_data.fds_display_type = matchedAttrVar?.[0].DisplayType;

					}

				}

			}

			sortFilterUtagLink(name, firstValName);
			sortFilterGenericEvent('filter_click', name, firstValName, true);
			setUtagCalled(true);

		}

	};

	return (
		<a href={firstValUrl} onClick={handleValueClick} className={className}>
			<SortFilterAttrGroupHeader
				name={name}
				attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
				headerImageSrc={headerImageSrc}
				headerImageAltTag={headerImageAltTag}
			/>
		</a>
	);

};

AttrGroupSingleValueLink.propTypes = {
	name: PropTypes.string.isRequired,
	attribute: PropTypes.shape({
		AttributeValueGroups: PropTypes.arrayOf(
			PropTypes.shape({
				AttributeValues: PropTypes.arrayOf(
					PropTypes.shape({
						Url: PropTypes.string,
						Name: PropTypes.string
					})
				)
			})
		)
	}),
	attributeGroupFilterDisplayTypeId: PropTypes.number,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string,
	className: PropTypes.string
};

export default AttrGroupSingleValueLink;
