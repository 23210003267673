import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Overlay from './Overlay';
import OverlayCloseButton from './OverlayCloseButton';

function getDrawerTransitionsByAnchorEdge (edge) {

	switch (edge) {

		case 'top': {

			return {
				enter: { transform: 'translateY(0)' },
				from: { transform: 'translateY(-100%)' },
				leave: { transform: 'translateY(-100%)' }
			};

		}
		case 'right': {

			return {
				enter: { transform: 'translateX(0)' },
				from: { transform: 'translateX(100%)' },
				leave: { transform: 'translateX(100%)' }
			};

		}
		case 'bottom': {

			return {
				enter: { transform: 'translateY(0)' },
				from: { transform: 'translateY(100%)' },
				leave: { transform: 'translateY(100%)' }
			};

		}
		case 'left': {

			return {
				enter: { transform: 'translateX(0)' },
				from: { transform: 'translateX(-100%)' },
				leave: { transform: 'translateX(-100%)' }
			};

		}
		default: {

			return {
				enter: undefined,
				from: undefined,
				leave: undefined
			};

		}

	}

}

function Drawer ({
	'aria-hidden': ariaHidden,
	'aria-label': ariaLabel,
	anchorEdge,
	children,
	className,
	escapeExits,
	focusTrapPaused,
	fullScreen,
	hideOverlay,
	show,
	showCloseButton,
	showUnderlay,
	underlayClickExits,
	focusDialog,
	initialFocus,
	scrollDisabled
}) {

	const fullClassName = classNames(className, 'Overlay--Drawer', `Overlay--Drawer-${anchorEdge}`, {
		'Overlay--Drawer-fullscreen': fullScreen
	});

	return (
		<Overlay
			aria-hidden={ariaHidden}
			aria-label={ariaLabel}
			className={fullClassName}
			escapeExits={escapeExits}
			focusTrapPaused={focusTrapPaused}
			hideOverlay={hideOverlay}
			show={show}
			showUnderlay={showUnderlay}
			underlayClickExits={underlayClickExits}
			focusDialog={focusDialog}
			initialFocus={initialFocus}
			scrollDisabled={scrollDisabled}
		>
			{showCloseButton && <OverlayCloseButton hideOverlay={hideOverlay} />}
			{children}
		</Overlay>
	);

}

Drawer.defaultProps = {
	'aria-hidden': false,
	className: undefined,
	focusTrapPaused: false,
	fullScreen: false,
	showCloseButton: true,
	showUnderlay: true,
	underlayClickExits: true,
	focusDialog: true,
	initialFocus: undefined,
	scrollDisabled: true
};

Drawer.propTypes = {
	'aria-label': PropTypes.string.isRequired,
	'aria-hidden': PropTypes.bool,
	anchorEdge: PropTypes.oneOf([ 'top', 'right', 'bottom', 'left' ]).isRequired,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string,
	escapeExits: PropTypes.bool,
	focusTrapPaused: PropTypes.bool,
	fullScreen: PropTypes.bool,
	hideOverlay: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showCloseButton: PropTypes.bool,
	showUnderlay: PropTypes.bool,
	underlayClickExits: PropTypes.bool,
	focusDialog: PropTypes.bool,
	initialFocus: PropTypes.oneOfType([ PropTypes.node, PropTypes.string, PropTypes.func ]),
	scrollDisabled: PropTypes.bool
};

export default Drawer;
