import React from 'react';
import PropTypes from 'prop-types';

import './CollapsibleDisclosure.scss';

function CollapsibleDisclosure ({
	open, setOpen, collapsibleContentId, collapsibleDisclosureId, children, onClick
}) {

	const className = [
		'CollapsibleDisclosure',
		`CollapsibleDisclosure--CollapsibleContent-${open ? 'open' : 'closed'}`
	].join(' ');

	return (
		<button
			className={className}
			id={collapsibleDisclosureId}
			aria-controls={collapsibleContentId}
			aria-expanded={open}
			onClick={(e) => {

				setOpen(!open);
				if (typeof onClick === 'function') {

					onClick(e, !open);

				}

			}}
			type="button"
		>
			{children}
		</button>
	);

}

CollapsibleDisclosure.propTypes = {
	children: PropTypes.node.isRequired,
	collapsibleContentId: PropTypes.string.isRequired,
	collapsibleDisclosureId: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	onClick: PropTypes.func
};

export default CollapsibleDisclosure;
