import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import classNames from 'classnames';

import isMappedTo from 'features/sort/isMappedTo';

import './SortFilterDropdownContent.scss';

const SortFilterDropdownContent = ({
	children,
	isMultiSelect,
	hasAnyNested,
	attributeFilterDisplayTypeId,
	totalAttributes
}) => {

	const [ isOverflowing, setIsOverflowing ] = useState(false);

	const measuredRef = useCallback((node) => {

		if (node) {

			setIsOverflowing(node.scrollHeight > node.clientHeight);

		}

	}, []);

	const gridViewVariation = isMappedTo.attribute.attrValGridView.which(attributeFilterDisplayTypeId);
	const gridImageSizeVariation = isMappedTo.attribute.attrValHeaderImageSize.which(attributeFilterDisplayTypeId);
	const isGridViewContainer = !hasAnyNested && gridViewVariation;

	const isMultiColumn = isMappedTo.attribute.multiColumnTenPerColumn(attributeFilterDisplayTypeId);
	const isFlexibleWidthContainer =		isMappedTo.attribute.selectionType.panelGrid(attributeFilterDisplayTypeId)
		|| isMappedTo.attribute.selectionType.valueListWithColumns(attributeFilterDisplayTypeId);

	const baseClassName = 'SortFilterDropdownContent';
	const gridBaseClassName = 'SortFilterAttrGroupGridView';
	const fullClassName = classNames(baseClassName, {
		[`${baseClassName}--multiSelect`]: !hasAnyNested && isMultiSelect,
		[`${baseClassName}--multipleMenu`]: hasAnyNested,
		[`${baseClassName}--overflowing`]: isOverflowing && isMultiSelect,
		[gridBaseClassName]: isGridViewContainer,
		[`${gridBaseClassName}--${gridViewVariation}`]: isGridViewContainer,
		[`${gridBaseClassName}--${gridImageSizeVariation}`]: isGridViewContainer && gridImageSizeVariation,
		[`${baseClassName}--multiColumn`]: isMultiColumn,
		[`${baseClassName}--multiColumnOneColumn`]: isMultiColumn && totalAttributes < 11,
		[`${baseClassName}--multiColumnTwoColumns`]: isMultiColumn && totalAttributes > 10 && totalAttributes < 21,
		[`${baseClassName}--multiColumnThreeColumns`]: isMultiColumn && totalAttributes > 20,
		[`${baseClassName}--flexibleWidth`]: isFlexibleWidthContainer
	});
	return (
		<div className={fullClassName} ref={measuredRef}>
			{children}
		</div>
	);

};

SortFilterDropdownContent.propTypes = {
	children: PropTypes.element,
	isMultiSelect: PropTypes.bool,
	hasAnyNested: PropTypes.bool,
	attributeFilterDisplayTypeId: PropTypes.number,
	totalAttributes: PropTypes.number
};

export default SortFilterDropdownContent;
