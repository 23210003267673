import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { uniqueId } from 'utilities/dom';
import { Input } from 'components/Form';

import './DebouncedInput.scss';

let timeout;

const DebouncedInput = ({
	id,
	name,
	onChange,
	onKeyPress,
	placeholder,
	debounceMs = 150,
	type = 'text',
	clearable = false
}) => {

	const inputName = useRef(name || uniqueId()).current;
	const { watch, setValue } = useFormContext();
	const watchValue = watch(inputName);
	const showClearBtn = clearable && watchValue;
	const inputRef = useRef(null);
	const wrapperClassName = classNames('debouncedInput__wrapper', {
		'debouncedInput__wrapper--btnPadding': clearable
	});
	const btnClassName = classNames('debouncedInput__clearBtn', 'lpIcon-close02');

	const changeHandler = (e) => {

		const inputValue = e.target.value;

		window.clearTimeout(timeout);
		timeout = window.setTimeout(() => onChange(inputValue), debounceMs);

	};

	const clearBtnClickHandler = () => {

		window.clearTimeout(timeout);
		setValue(inputName, '');
		onChange('');

		inputRef.current.focus();

	};

	return (
		<span className={wrapperClassName}>
			<Input
				type={type}
				name={inputName}
				id={id}
				onChange={changeHandler}
				onKeyPress={onKeyPress}
				placeholder={placeholder}
				externalRef={inputRef}
			/>
			{showClearBtn && (
				<button
					className={btnClassName}
					type="button"
					onClick={clearBtnClickHandler}
					aria-label="Clear input"
				/>
			)}
		</span>
	);

};

DebouncedInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onKeyPress: PropTypes.func,
	placeholder: PropTypes.string,
	debounceMs: PropTypes.number,
	type: PropTypes.string,
	clearable: PropTypes.bool
};

export default DebouncedInput;
