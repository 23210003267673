/* eslint-disable react/button-has-type */

import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import './Button.scss';

const Button = forwardRef(
	(
		{
			id,
			'aria-disabled': ariaDisabled,
			'aria-expanded': ariaExpanded,
			'aria-haspopup': ariaHasPopup,
			'aria-label': ariaLabel,
			buttonStyleVariant,
			children,
			loading,
			onClick,
			onKeyDown,
			type,
			name,
			className,
			size
		},
		ref
	) => {

		const fullClassName = classNames(
			'Button',
			{ [`Button--${buttonStyleVariant}`]: Boolean(buttonStyleVariant) },
			{ 'Button--loading': loading },
			{ [`Button--${size}`]: Boolean(size) },
			className
		);

		return (
			<button
				id={id}
				disabled={ariaDisabled}
				aria-disabled={ariaDisabled}
				aria-expanded={ariaExpanded}
				aria-haspopup={ariaHasPopup}
				aria-label={ariaLabel}
				className={fullClassName}
				onClick={onClick}
				onKeyDown={onKeyDown}
				type={type}
				name={name}
				ref={ref}
			>
				{loading && <span className="Button__loadingIcon" />}
				<span className="Button__label">
					{children}
				</span>
			</button>
		);

	}
);

Button.defaultProps = {
	'aria-disabled': false,
	loading: false,
	type: 'button',
	name: undefined
};

Button.propTypes = {
	id: PropTypes.string,
	'aria-disabled': PropTypes.bool,
	'aria-expanded': PropTypes.bool,
	'aria-haspopup': PropTypes.bool,
	'aria-label': PropTypes.string,
	buttonStyleVariant: PropTypes.string,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	type: PropTypes.oneOf([ 'button', 'submit', 'reset' ]),
	name: PropTypes.string,
	className: PropTypes.string,
	// size possible values: small, normal (default), large
	size: PropTypes.string
};

export default Button;
