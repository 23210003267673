import { initOpenBoxSearch } from 'features/search/openBox';
import { utagLinkSort } from 'features/tealium';

function initOpenBox () {

	initOpenBoxSearch('#searchOpenBox', '#searchBtnOpenBox');

}

export default initOpenBox;
