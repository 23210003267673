import getFdsAbTestId from './getFdsAbTestId';

const getManagedSortModels = () => {

	const {
		sortFilter: { managedSortModels }
	} = window.lp.pageData;

	if (!Array.isArray(managedSortModels)) {

		return [];

	}

	const fdsAbTestId = getFdsAbTestId();
	const setWithAbTestId = managedSortModels.find((set) => set.FilterDisplaySetId === fdsAbTestId);

	if (fdsAbTestId && setWithAbTestId) {

		// filters out Filter Display Sets that have the same DT as the FDS that is being AB tested
		return managedSortModels.filter(
			(set) => set.FilterDisplaySetId === fdsAbTestId
				|| set.FilterDisplayTypeId !== setWithAbTestId.FilterDisplayTypeId
		);

	}

	return managedSortModels;

};

export default getManagedSortModels;
