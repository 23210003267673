import React from 'react';
import PropTypes from 'prop-types';

import './OverlayContent.scss';

function OverlayContent ({ children }) {

	return (
		<div className="OverlayContent">
			{/*
				Expects header, main, footer HTML elements for
				sticky header/footer and CSS vars to set
				non-default header/footer heights
				.OverlayContent {
					--overlayContentStickyHeaderHeight: 64px;
					--overlayContentStickyFooterHeight: 50px;
				}

				Set an override to these (i.e. to 0) if not using sticky header and footer
			*/}
			{children}
		</div>
	);

}

OverlayContent.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired
};

export default OverlayContent;
