import React from 'react';
import { unmountComponentAtNode } from 'react-dom';

import { getManagedSortModels, ModelContext } from 'features/sort';
import forceDisplayTypeId from 'apps/sortAttributeFilters/forceDisplayTypeId';
import { AppSortAttributeFilters } from 'apps/sortAttributeFilters/desktop';
import { renderWithStore } from 'state';

function initFilterDisplaySet () {

	forceDisplayTypeId();

	const className = 'lpForm';
	const managedSortModels = getManagedSortModels();
	const { canAttributesMapToFds, fdsQueryRulesChoices, fdsFacetCanonicalData } = lp.pageData.sortFilter;

	managedSortModels.forEach((model) => {

		const {
			AttributeGroups, FilterDisplayTypeId, DefaultVisibleMenuCount, CanonicalOrderedUrlFragments
		} = model;

		const container = document.querySelector(`.setDT-${FilterDisplayTypeId}`);

		if (!container || !AttributeGroups) {

			return;

		}

		unmountComponentAtNode(container);

		renderWithStore(
			<ModelContext.Provider
				value={{
					canAttributesMapToFds,
					fdsQueryRulesChoices,
					CanonicalOrderedUrlFragments,
					fdsFacetCanonicalData
				}}
			>
				<AppSortAttributeFilters
					className={className}
					filterDisplayTypeId={FilterDisplayTypeId}
					defaultVisibleMenuCount={DefaultVisibleMenuCount}
					attributeGroups={AttributeGroups}
				/>
			</ModelContext.Provider>,
			container
		);

	});

}

export default initFilterDisplaySet;
