import PropTypes from 'prop-types';
import React from 'react';

import RangeSlider from '../RangeSlider';
import RangeMinMaxLabels from '../RangeSlider/RangeMinMaxLabels';
import Thumb from '../RangeSlider/Thumb';

import scaleTransform from './scaleTransform';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 2000;

const ExpoRangeSlider = ({
	className,
	onFinalChange,
	onChange,
	values,
	min,
	max,
	plusOnMax,
	allowOverlap,
	exponentialSteps,
	showRangeLabels,
	rangeLabelPrefix
}) => {

	const [ DISCRETE_MAX, expoValueTransform ] = scaleTransform(min, max, exponentialSteps);
	const getExpoValues = (vals) => vals.map((v) => expoValueTransform(v));
	const onChangeHandler = (scaleVals) => onChange(scaleVals, getExpoValues(scaleVals));
	const onFinalChangeHandler = (scaleVals) => onFinalChange && onFinalChange(scaleVals, getExpoValues(scaleVals));

	return (
		<>
			<RangeSlider
				allowOverlap={allowOverlap}
				className={className}
				values={values}
				step={1}
				min={0}
				max={DISCRETE_MAX}
				onChange={onChangeHandler}
				onFinalChange={onFinalChangeHandler}
				renderThumb={Thumb(values, DISCRETE_MAX, plusOnMax, max, getExpoValues(values))}
			/>
			{showRangeLabels && (
				<RangeMinMaxLabels min={min} max={max} prefix={rangeLabelPrefix} plusOnMax={plusOnMax} />
			)}
		</>
	);

};

ExpoRangeSlider.defaultProps = {
	className: null,
	onFinalChange: null,
	min: DEFAULT_MIN,
	max: DEFAULT_MAX,
	plusOnMax: false,
	allowOverlap: false
};

ExpoRangeSlider.propTypes = {
	className: PropTypes.string,
	onFinalChange: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	plusOnMax: PropTypes.bool,
	allowOverlap: PropTypes.bool,
	values: PropTypes.arrayOf(PropTypes.number),
	exponentialSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
	showRangeLabels: PropTypes.bool,
	rangeLabelPrefix: PropTypes.string
};

export default ExpoRangeSlider;
