//  "exponential step" curry function
//   return tuple where
//   [0] is the number of inputs we need our slider to have
//   [1] is our output transform function
function scaleTransform (min, max, intervals) {

	// Determine how many steps we need
	const distributions = intervals.length;
	const discretePoints = Math.ceil((max - min) / intervals.reduce((total, step) => total + step / distributions, 0));

	return [
		discretePoints,
		(input) => {

			// If min / max of exponential slider passed, return full min or max
			// Min is always 0 because ExpoRangeSlider component discreet min is always 0
			if (input === 0) {

				return min;

			}

			if (input === discretePoints) {

				return max;

			}

			const stepTransforms = intervals.map((s, i) => {

				const setCount = Math.min(
					Math.ceil(input - (discretePoints * i) / distributions),
					Math.round(discretePoints / distributions)
				);
				return setCount > 0 ? setCount * s : 0;

			});

			let lastStep = 0;
			const out =				Math.round(
				stepTransforms.reduce((total, num, i) => {

					if (num) {

						lastStep = i;

					}

					return total + num;

				})
			) + min;

			const currentUnit = intervals[lastStep];
			const returnVal = Math.round(out / currentUnit) * currentUnit;

			// Cap the calculated values at the min and max
			if (returnVal > max) {

				return max;

			}

			if (returnVal < min) {

				return min;

			}

			return returnVal;

		}
	];

}

export default scaleTransform;
