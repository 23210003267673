import { scrollTo } from 'ui/animation';

function initBackToTop () {

	const $backToTop = $('.backToTop .backToTopButton');

	// back to top
	$backToTop.on('click', (e) => {

		e.preventDefault();
		scrollTo(800, 0);

	});

	$backToTop.on('keypress', (e) => {

		e.preventDefault();
		if (e.keyCode === 32 || e.keyCode === 13) {

			scrollTo(800, 0);

		}

	});

}

export default initBackToTop;
