import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Range } from 'react-range';
import classNames from 'classnames';

import RangeMinMaxLabels from './RangeMinMaxLabels';
import Track from './Track';
import Thumb from './Thumb';

import './RangeSlider.scss';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 1000;
const DEFAULT_STEP = 10;

const RangeSlider = ({
	className,
	onFinalChange,
	onChange,
	values,
	min,
	max,
	step,
	plusOnMax,
	allowOverlap,
	renderThumb,
	showRangeLabels,
	rangeLabelPrefix
}) => {

	const defaultClassName = 'RangeSlider';
	const fullClassName = classNames(defaultClassName, className);
	const rangeRef = useRef();
	const onChangeHandler = (newVals) => onChange(newVals);

	return (
		<>
			<Range
				allowOverlap={allowOverlap}
				values={values}
				ref={rangeRef}
				step={step}
				min={min}
				max={max}
				onChange={onChangeHandler}
				onFinalChange={onFinalChange}
				renderTrack={Track(fullClassName)}
				renderThumb={renderThumb || Thumb(values, max, plusOnMax)}
			/>
			{showRangeLabels && (
				<RangeMinMaxLabels min={min} max={max} plusOnMax={plusOnMax} prefix={rangeLabelPrefix} />
			)}
		</>
	);

};

RangeSlider.defaultProps = {
	className: null,
	onFinalChange: null,
	min: DEFAULT_MIN,
	max: DEFAULT_MAX,
	step: DEFAULT_STEP,
	plusOnMax: false,
	allowOverlap: false
};

RangeSlider.propTypes = {
	className: PropTypes.string,
	onFinalChange: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	plusOnMax: PropTypes.bool,
	allowOverlap: PropTypes.bool,
	values: PropTypes.arrayOf(PropTypes.number).isRequired,
	renderThumb: PropTypes.func,
	showRangeLabels: PropTypes.bool,
	rangeLabelPrefix: PropTypes.string
};

export default RangeSlider;
