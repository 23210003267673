/**
 * Redirect to Sale page searchUrl (search page result)
 * @param {string} searchUrl
 */
function redirectToSaleSearch (searchUrl) {

	const adjustedSearchUrl = searchUrl.replace('#', '');
	const searchPartialUrl = `/products/onsale_view-on-sale-items/${adjustedSearchUrl}?s=1`;

	// redirect to search result page
	window.location.href = searchPartialUrl;

}

export default redirectToSaleSearch;
