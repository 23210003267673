import React, { memo } from 'react';
import PropTypes from 'prop-types';

import isMappedTo from '../../isMappedTo';
import SortFilterThumbnail from '../SortFilterThumbnail';

const SortFilterAttrValueGroupHeader = memo(
	({
		attrDisplayTypeId, Name, className, headerImageSrc, headerImageAltTag
	}) => {

		const pxDimension = 24;
		const ImgComponent = () => (
			<SortFilterThumbnail
				src={headerImageSrc}
				alt={headerImageAltTag}
				height={pxDimension}
				width={pxDimension}
			/>
		);

		const attrValGroupHeaderVariationType =			isMappedTo.attribute.attrValGroupHeaderVariation.which(attrDisplayTypeId);

		switch (attrValGroupHeaderVariationType) {

			case 'textOnly':
				return (
					<div className={className}>
						{Name}
					</div>
				);
			case 'imageWithText':
				return (
					<div className={className}>
						<ImgComponent />
						{Name}
					</div>
				);
			case 'imageOnly':
				return (
					<div className={className}>
						<ImgComponent />
					</div>
				);
			default:
				return null;

		}

	}
);

SortFilterAttrValueGroupHeader.propTypes = {
	attrDisplayTypeId: PropTypes.number,
	className: PropTypes.string,
	Name: PropTypes.string,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string
};

export default SortFilterAttrValueGroupHeader;
