import React from 'react';
import PropTypes from 'prop-types';

import SortFilterThumbnail from '../SortFilterThumbnail';

const PanelImgAndText = ({
	baseClassName,
	showHeaderImg,
	showDescription,
	showProdCount,
	imgSrc,
	imgAlt,
	name,
	description
}) => (
	<>
		{showHeaderImg && <SortFilterThumbnail className={`${baseClassName}__image`} src={imgSrc} alt={imgAlt} />}
		<div>
			<div className={`${baseClassName}__name`}>
				{`${name} ${showProdCount && `(${showProdCount})`}`}
			</div>
			{showDescription && (
				<div className={`${baseClassName}__description`}>
					{description}
				</div>
			)}
		</div>
	</>
);

PanelImgAndText.propTypes = {
	baseClassName: PropTypes.string,
	showHeaderImg: PropTypes.bool,
	showDescription: PropTypes.bool,
	imgSrc: PropTypes.string,
	imgAlt: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string
};

export default PanelImgAndText;
