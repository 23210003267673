import { updateCounts } from 'features/asset/desktop';
import { utagLinkPDP } from 'features/tealium/';
import { setGenericEvent } from 'features/tealium/ga4/custom';

/**
	Add item(s) to Wish List.
	@param arrData Array of objects to add. Object should be in this format:
		{
			ShortSku: 'xxxxxxx',
			Quantity: 1,
			PatternColorCombination: { // optional
				// refer to LampsPlus.Commerce.Core.Products.PatternColorCombination
			}
		}
	@param successCalback Function to call when ajax succeeds
	@param errorCallback Function to call when ajax fails
	@param wishlistToken The Wishlist token (only passed when called from Wishlist page)
*/

const trackPdpEvents = utagLinkPDP();

function addToWishList (arrData, successCallback, errorCallback, wishlistToken, useCustomTracking) {

	if (!useCustomTracking) {

		trackPdpEvents({
			eventAction: 'Save-To-Wishlist',
			eventLabel: `${window.shortSku}-${window.productCategory}`
		});

		setGenericEvent({
			event_name: 'pdp_wishlist_save',
			pdp_item_id: window.shortSku,
			pdp_prime_cat: window.productCategory
		});

	}

	const jsonData = {
		Items: arrData,
		IsAssetPage: /\/wish-list\//.test(window.location.pathname), // check if in Wish List homepage
		WishListToken: wishlistToken
	};

	return $.ajax({
		type: 'POST',
		url: '/api/globalwishlists/items',
		data: JSON.stringify(jsonData),
		contentType: 'application/json',
		success (response) {

			if (response.Success) {

				if (typeof successCallback === 'function') {

					updateCounts();
					successCallback.call(this, response);

				}

			} else if (typeof errorCallback === 'function') {

				const maxItemCountReached =					response.ErrorMessage === 'FailureWishListFull'
					|| response.ErrorMessage === 'FailureTryingToAddTooManyItems';
				errorCallback.call(this, maxItemCountReached, response);

			}

		},
		error () {

			if (typeof errorCallback === 'function') {

				errorCallback.call(this, false);

			}

		}
	});

}

export default addToWishList;
