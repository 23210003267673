/**
 * Redirect to Sale page searchUrl (search page result)
 * @param {string} searchUrl
 */
function redirectToContextualSearch (searchUrl) {

	const adjustedSearchUrl = searchUrl.replace('#', '');
	const { origin, pathname } = window.location;

	// redirect to search result page
	window.location.href = `${origin + pathname.replace(/\/page_\d+/, '')}${adjustedSearchUrl}?s=1`;

}

export default redirectToContextualSearch;
