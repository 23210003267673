import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const Input = ({
	ariaLabel,
	className,
	name,
	type,
	id,
	defaultValue,
	value,
	placeholder,
	title,
	externalRef,
	checked,
	onBlur,
	onChange,
	onKeyPress,
	...rest
}) => {

	const { errors, register, triggerValidation } = useFormContext();

	const handleBlur = (e) => {

		triggerValidation(name);

		if (onBlur) {

			onBlur(e);

		}

	};

	const handleChange = (e) => {

		if (errors[name]) {

			triggerValidation(name);

		}

		if (onChange) {

			onChange(e);

		}

	};

	return (
		<input
			aria-label={ariaLabel}
			id={id}
			className={classNames(className, {
				error: Boolean(errors[name])
			})}
			name={name}
			onChange={handleChange}
			onBlur={handleBlur}
			onKeyPress={onKeyPress}
			placeholder={placeholder}
			ref={(e) => {

				register(e);
				if (externalRef) {

					// eslint-disable-next-line no-param-reassign
					externalRef.current = e;

				}

			}}
			type={type}
			defaultValue={defaultValue}
			value={value || undefined}
			title={title || null}
			defaultChecked={checked}
			{...rest}
		/>
	);

};

Input.propTypes = {
	ariaLabel: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	title: PropTypes.string,
	externalRef: PropTypes.shape({
		current: PropTypes.any
	}),
	checked: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onKeyPress: PropTypes.func
};

export default Input;
