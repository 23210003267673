import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MultiSelectCheckboxField from '../MultiSelectCheckboxField';
import sortFilterUtagLink from '../../sortFilterUtagLink';
import isMappedTo from '../../isMappedTo';

import PanelImgAndText from './PanelImgAndText';

import './SortFilterPanelGrid.scss';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

const baseClassName = 'SortFilterPanelGrid';

const SortFilterPanelGrid = ({ htmlName, attrDisplayTypeId, attributeValueGroups }) => {

	const isMultiSelect = isMappedTo.attribute.multiSelect(attrDisplayTypeId);
	const whichHeaderVariation = isMappedTo.attribute.attrValHeaderVariation.which(attrDisplayTypeId);
	const showHeaderImg = [ 'imageWithText', 'imageWithTextAndDescription' ].includes(whichHeaderVariation);
	const showDescription = [ 'textWithDescription', 'imageWithTextAndDescription' ].includes(whichHeaderVariation);
	const className = classNames(baseClassName, {
		[`${baseClassName}--multiSelect`]: isMultiSelect
	});

	const [ utagCalled, setUtagCalled ] = useState(false);

	const handleValueClick = (valueName) => {

		if (!utagCalled) {

			sortFilterUtagLink(htmlName, valueName);
			sortFilterGenericEvent('filter_click', htmlName, valueName, true);
			setUtagCalled(true);

		}

	};

	return (
		<div className={className}>
			{attributeValueGroups.map((grp) => grp.AttributeValues.map((val) => {

				const name = val.Name;
				const description = val.Description;
				const url = val.Url;
				const imgSrc = val.HeaderImage;
				const imgAlt = val.HeaderImageAltTag;
				const valId = val.AttributeValueId;
				const prodCount = val.ProductCount;

				if (isMultiSelect) {

					const uniqueId = `${name}${valId}`.replace(/[^\w]/g, '');

					return (
						<MultiSelectCheckboxField
							className={`${baseClassName}__panel`}
							name={htmlName}
							value={`${valId}`}
							id={uniqueId}
							key={valId}
						>
							<PanelImgAndText
								baseClassName={baseClassName}
								showHeaderImg={showHeaderImg}
								showDescription={showDescription}
								showProdCount={prodCount}
								imgSrc={imgSrc}
								imgAlt={imgAlt}
								name={name}
								description={description}
							/>
						</MultiSelectCheckboxField>
					);

				}

				return (
					<a
						href={url}
						className={`${baseClassName}__panel`}
						onClick={() => handleValueClick(name)}
						key={valId}
					>
						<PanelImgAndText
							baseClassName={baseClassName}
							showHeaderImg={showHeaderImg}
							showDescription={showDescription}
							showProdCount={prodCount}
							imgSrc={imgSrc}
							imgAlt={imgAlt}
							name={name}
							description={description}
						/>
					</a>
				);

			}))}
		</div>
	);

};

SortFilterPanelGrid.propTypes = {
	htmlName: PropTypes.string,
	attrDisplayTypeId: PropTypes.number,
	attributeValueGroups: PropTypes.arrayOf(
		PropTypes.shape({
			AttributeValues: PropTypes.arrayOf(
				PropTypes.shape({
					Url: PropTypes.string,
					Name: PropTypes.string
				})
			)
		})
	)
};

export default SortFilterPanelGrid;
