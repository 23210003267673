import React from 'react';
import { OverlayDisclosure } from 'components/Overlay';

import './DrawerDisclosure.scss';

const DrawerDisclosure = ({ imgSrc, text, drawer, onButtonClick }) => {
	const imgClass = imgSrc
		? `DrawerDisclosure__img-${imgSrc.substring(imgSrc.lastIndexOf('/') + 1, imgSrc.lastIndexOf('.'))}`
		: undefined;

	return (
		<OverlayDisclosure {...drawer} onButtonClick={onButtonClick} buttonStyleVariant="tertiary" className="DrawerDisclosure">
			{imgSrc ? (<img src={imgSrc} className={imgClass} aria-hidden="true" alt={text} />) : null}
			{text}
		</OverlayDisclosure>
	)
};

export default DrawerDisclosure;
