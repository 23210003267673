import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import classNames from 'classnames';

import styleSettings from 'style/settings';
import { uniqueId } from 'utilities/dom';
import { usePrevious } from 'utilities/react';

import './Overlay.scss';

const classNameTransitionShow = 'Overlay--transition-show';
const classNameTransitionHide = 'Overlay--transition-hide';

function Overlay ({
	'aria-hidden': ariaHidden,
	'aria-label': ariaLabel,
	children,
	className,
	escapeExits = true,
	focusDialog,
	focusTrapPaused,
	hideOverlay,
	initialFocus,
	preventFocusScrollOnOpen,
	show,
	showUnderlay = true,
	underlayClickExits = true,
	scrollDisabled,
	underlayClickExitHandler
}) {

	const previousOpen = usePrevious(show);
	const [ transitionClassName, setTransitionClassName ] = useState(
		show ? classNameTransitionShow : classNameTransitionHide
	);
	const [ isAnimating, setIsAnimating ] = useState(false);
	const clearSyncAnimationTimerRef = useRef();

	const syncAnimationTiming = async () => {

		clearTimeout(clearSyncAnimationTimerRef.current);
		setIsAnimating(true);
		await (function () {

			return new Promise((resolve) => {

				clearSyncAnimationTimerRef.current = setTimeout(resolve, styleSettings.surfaceTransitionDuration);

			});

		}());
		setIsAnimating(false);

	};

	const animateOpen = async () => {

		setTransitionClassName(classNameTransitionShow);
		await syncAnimationTiming();

	};
	const animateClose = async () => {

		setTransitionClassName(classNameTransitionHide);
		await syncAnimationTiming();

	};
	const handleUnderlayExit = () => {

		if (underlayClickExitHandler) {

			return underlayClickExitHandler();

		}

		return hideOverlay();

	};

	useEffect(() => {

		(async function () {

			// Handle animation excluding initial open state
			if (show === previousOpen || previousOpen === undefined) {

				return;

			}
			if (show) {

				await animateOpen();
				// onOpen callback if we need it would run here

			} else {

				await animateClose();
				// onClose callback if we need it would run here

			}

		}());

	}, [ show, previousOpen ]);

	const [ dialogId ] = useState(uniqueId());
	const focusTrapOptions = {
		preventScroll: preventFocusScrollOnOpen,
		fallbackFocus: `#${dialogId}`
	};

	const composedClassName = classNames('Overlay', transitionClassName, className);

	return (
		(show || previousOpen || isAnimating) && (
			<AriaModal
				dialogId={dialogId}
				applicationNode={document.getElementById('applicationNode')}
				aria-hidden={ariaHidden}
				escapeExits={escapeExits}
				focusDialog={focusDialog}
				focusTrapPaused={focusTrapPaused}
				includeDefaultStyles={false}
				initialFocus={initialFocus}
				onExit={hideOverlay}
				scrollDisabled={scrollDisabled}
				titleText={ariaLabel}
				focusTrapOptions={focusTrapOptions}
				underlayClickExits={false}
			>
				<div className={composedClassName}>
					{showUnderlay && (
						<div
							className="Overlay__underlay"
							onClick={underlayClickExits ? handleUnderlayExit : undefined}
						/>
					)}
					<div className="Overlay__contentWrapper">
						{children}
					</div>
				</div>
			</AriaModal>
		)
	);

}

Overlay.defaultProps = {
	'aria-hidden': false,
	focusDialog: true,
	focusTrapPaused: false,
	initialFocus: undefined,
	preventFocusScrollOnOpen: true,
	scrollDisabled: true
};

Overlay.propTypes = {
	'aria-hidden': PropTypes.bool,
	'aria-label': PropTypes.string.isRequired,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string,
	escapeExits: PropTypes.bool.isRequired,
	focusDialog: PropTypes.bool,
	focusTrapPaused: PropTypes.bool,
	hideOverlay: PropTypes.func.isRequired,
	initialFocus: PropTypes.oneOfType([ PropTypes.node, PropTypes.string, PropTypes.func ]),
	preventFocusScrollOnOpen: PropTypes.bool,
	scrollDisabled: PropTypes.bool,
	show: PropTypes.bool.isRequired,
	showUnderlay: PropTypes.bool.isRequired,
	underlayClickExits: PropTypes.bool.isRequired,
	underlayClickExitHandler: PropTypes.func
};

export default Overlay;
