import unveil from 'ui/lazy-load/unveil';
import './broken-image.scss';

function addRetryImage (selector) {

	// cache icon image
	$('<img>').attr('src', '/img/global/icon-alert.jpg');

	const wrapper = '<div class="brokenImage></div>';
	const template = '<div class="brokenImageLink"></div>';
	const retrySelector = '.brokenImageLink';

	$(selector).wrap(wrapper).after(template);

	$(retrySelector).on('click', (e) => {

		e.stopPropagation();

		$('.brokenImage').addClass('brokenImageRetry').removeClass('brokenImage');

		$('.brokenImageRetry img').each(function () {

			const img = new Image();
			const that = this;

			img.onload = function () {

				$(that).parent().removeClass('brokenImageRetry');

			};
			img.onerror = function () {

				$(that).parent().removeClass('brokenImageRetry').addClass('brokenImage');

			};
			img.src = $(that).attr('src');

		});

		// if we're on sort page, fire unveil to refresh images
		unveil('img[data-src]', 300);

	});

	// Detect error on image load of sort page
	$(selector).on('error', function () {

		$(this).parent().removeClass('brokenImageRetry').addClass('brokenImage');

	});

}

export default addRetryImage;
