import { useState } from 'react';

import { uniqueId } from 'utilities/dom';

function useCollapsibleState (initiallyOpen = false) {

	const [ collapsibleContentId ] = useState(uniqueId());
	const [ collapsibleDisclosureId ] = useState(uniqueId());

	const [ open, setOpen ] = useState(initiallyOpen);
	const openCollapsible = () => setOpen(true);
	const closeCollapsible = () => setOpen(false);
	const toggleCollapsible = () => {

		setOpen(!open);

	};

	return {
		closeCollapsible,
		collapsibleContentId,
		collapsibleDisclosureId,
		open,
		openCollapsible,
		setOpen,
		toggleCollapsible
	};

}

export default useCollapsibleState;
