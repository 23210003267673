/**
 * Creates the url path from the canonicalOrderedUrlFragments array
 * and inserts the new url segment to the correct position
 * @param {Object} canonicalOrderedUrlFragments - canonically ordered id based fragments of the url
 * @param {string[]} canonicalOrderedUrlFragments.IdBasedList
 * @param {string[]} canonicalOrderedUrlFragments.TextBasedList
 * @param {Boolean} canonicalOrderedUrlFragments.ShouldUseIdBasedUrl
 * @param {Number} canonicalOrder
 * @param {string} newUrlSegment
 * @param {Boolean} isMultiSelected - If applying multiple attribute values. Forces IdBasedList to be used.
 */
const applyUrlSegmentCanonically = (
	{ IdBasedList, TextBasedList, ShouldUseIdBasedUrl },
	canonicalOrder,
	newUrlSegment,
	isMultiSelected
) => {

	// TextBasedList is only used for applying custom ranges when no other selection has been multi-selected
	const list = ShouldUseIdBasedUrl || isMultiSelected ? IdBasedList : TextBasedList;
	const listCopy = [ ...list ];
	// insert the urlSegment at the index specified by CanonicalOrder
	listCopy.splice(canonicalOrder, 0, newUrlSegment);

	// "href = pathname" removes the query strings and hashes from url, replicates BED logic
	window.location.href = `/products/${listCopy.join('/')}/`;

};

export default applyUrlSegmentCanonically;
