import { Reporter, visibilityObserver } from 'features/dataCapture';
import { dataCaptureTimeout } from 'ui/config';

const { reporter } = window.lp.dataCapture;

function initializeDataCaptureClickHandler (sortResultContainer) {

	if (!window.lp.globals.enableDataCapture) {

		return;

	}

	let pendingHref;
	const dataCaptureClickHandler = (e) => {

		const closestAnchor = e.target.closest('a');

		if (!closestAnchor || reporter.isSkuViewDone(sortResultContainer)) {

			return;

		}

		const record = reporter.getRecordByElement(sortResultContainer);
		// if skuview is pending, lets use that promise
		const request = record
			? record.promise
			: reporter.track(Reporter.getSkuViewData(sortResultContainer), { immediate: true });
		const { type } = e;
		const isMiddle = e.which === 2;
		const ctrlOrCmd = e.ctrlKey || e.metaKey;

		// Delay navigation ONLY when it is a regular click.
		if (type === 'auxclick' || type === 'contextmenu' || (type === 'click' && (ctrlOrCmd || isMiddle))) {

			return;

		}

		e.preventDefault();

		const { href } = closestAnchor;
		const navigate = () => {

			if (pendingHref !== href) {

				pendingHref = href;
				window.location.href = href;

			}

		};

		request.finally(navigate);
		window.setTimeout(navigate, dataCaptureTimeout);

	};

	// Send skuview events on click
	sortResultContainer.addEventListener('click', dataCaptureClickHandler);
	// Chrome fires auxclick for middle button, Safari fires click.
	sortResultContainer.addEventListener('auxclick', (e) => {

		// auxclick event also fires for right click
		// but we only want to use auxclick for middle button. contextmenu is more reliable for right click.
		if (e.which !== 3) {

			dataCaptureClickHandler(e);

		}

	});
	sortResultContainer.addEventListener('contextmenu', dataCaptureClickHandler);

}

function initDataCapture (sortResultContainers, sortSkuViews) {

	if (!window.lp.globals.enableDataCapture) {

		return;

	}

	sortResultContainers.forEach((sortResultContainer) => {

		initializeDataCaptureClickHandler(sortResultContainer);

	});

	const observer = visibilityObserver((element) => {

		const data = Reporter.getSkuViewData(element);
		reporter.track(data);

	});

	sortSkuViews.forEach((element) => observer.observe(element));

	document.addEventListener('recommendedItems:sortpage:loaded', () => {

		const moreYouMayLikeSkuViews = document.querySelectorAll('.sortMYMLProdContainer img[data-sku]');
		moreYouMayLikeSkuViews.forEach((element) => observer.observe(element));

	});

}

export default initDataCapture;
