import { utagLinkSort } from 'features/tealium';

function initSearchPageMoreLikeThis () {

	const sortProductsContainer = document.getElementById('sortResultProducts');
	const listType = sortProductsContainer.getAttribute('data-list-type');

	if (listType && listType.toLowerCase() === 'search') {

		const sortMoreLikeThisBtns = document.querySelectorAll('.sortMoreLikeThisBtn');
		sortMoreLikeThisBtns.forEach((moreLikeThisLink) => {

			const { sku } = moreLikeThisLink.closest('.sortResultContainer').dataset;

			moreLikeThisLink.addEventListener('click', () => {

				utagLinkSort('Search Pages')({
					eventAction: 'Click-More-Like-This',
					eventLabel: sku
				});

			});

		});

	}

}

export default initSearchPageMoreLikeThis;
