const errorMessages = {
	Success: '',
	Failure: "Sorry, we've encountered an error! Please try your request again.",
	Conflict: 'Sorry, you already have a Wish List saved with this name. Please use a new name and try again.',
	FailureNameAlreadyExists:
		'Sorry, you already have a Wish List saved with this name. Please use a new name and try again.',
	FailureWishListFull:
		'Sorry, your Wish List contains too many items! There is a limit of 50 items. Please remove items and try again.',
	FailureNameTooLong:
		'Sorry, the Wish List name you have entered is too long. Please use a shorter name and try again.',
	FailureInvalidWishListId: 'Sorry, this Wish List is no longer valid.',
	FailureInvalidItemId: 'Sorry, that product style number does not exist. Please check the number and try again.',
	FailureInvalidQuantity:
		"Sorry, we don't recognize the quantity you entered. Please check your entry and try again.",
	FailureMaximumAnonymousWishListCountReached:
		'Sorry! To save additional an Wish List you will need to create a free account with us.',
	FailureMaximumWishListCountReached:
		'Sorry! You have reached the maximum number of Wish Lists. You can delete older lists or add these designs to an existing list.',
	FailureMaximumUniqueWishListItemCountReached:
		'Sorry! Your Wish List is full and no new designs can be added to it. Please delete unneeded designs or consider starting a new list.',
	FailureNoCurrentWishList: "Sorry! You don't have a Wish List created. Please click to create one.",
	FailureErrorCreatingWishList: 'Sorry, we ran into an error trying to create your Wish List. Please try again.',
	FailureTryingToAddTooManyItems:
		'Sorry! You have reached the maximum number of items that can be added at one time. Please remove some from your list and try again.',
	FailureAddingMultiplesOfTheSameItem:
		'Sorry! The following items were listed twice on your list. Please remove the duplicates and try again.',
	FailureInvalidShortsku:
		'The product Style # entered is not valid. Please check the number and try again or browse for other options.',
	FailureExceededMaxItemQuantity:
		"We're sorry, online item quantities must be between 1 and 999. Please contact Customer Service to place larger orders.",
	FailureUnableToMerge: "Sorry, we've encountered an error merging the items. Please try your request again.",
	FailureUnableToMergeTooManyItems:
		'Sorry! There are too many items to merge. Please remove some items and try again.',
	FailureRewardNumbersDoNotMatch: 'Sorry! Rewards numbers do not match.',
	FailureTryingToAddItemsAlreadyAdded: 'Sorry! The items you were adding were already added.',
	FailureCouldNotEmail: "Sorry, we've encountered an email error! Please try your request again.",
	FailureUnauthorizedAction: 'Sorry! You are not authorized to perform this operation.',
	FailureInvalidViewType: 'Sorry! The specified Wish List view is invalid.'
};

export default errorMessages;
