import classNames from 'classnames';

import isMappedTo from './isMappedTo';

// Centralizes btn class name styling for dropdown disclosure and attribute group anchor buttons (attr groups with
// no dropdowns, like pros specials )
const getHorizBtnClassName = (attrGroupDisplayTypeId, hasNoDropdown) => {

	const btnBaseClassName = 'attributeGroupBtn';
	const isWhiteButton = isMappedTo.attributeGroup.whiteButton(attrGroupDisplayTypeId);
	const isHeaderImgOnly = isMappedTo.attributeGroup.headerStyle.imageOnly(attrGroupDisplayTypeId);

	return classNames(btnBaseClassName, {
		[`${btnBaseClassName}--noDropdown`]: hasNoDropdown,
		'attributeGroupBtn--white': isWhiteButton,
		'attributeGroupBtn--imgOnly': hasNoDropdown && isHeaderImgOnly
	});

};

export default getHorizBtnClassName;
