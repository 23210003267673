import PropTypes from 'prop-types';
import React from 'react';

const FieldGroup = ({ children }) => (
	<div className="fieldGroup">
		{children}
	</div>
);

FieldGroup.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired
};

export default FieldGroup;
