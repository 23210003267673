import { wishListErrorMessages } from 'features/wishList/desktop';

function showErrorModal (options) {

	const $errorContent = $('#errorContentModal');
	const defaults = {
		onInit: true,
		width: 500,
		height: 100,
		lpModalClass: 'lpModalError',
		content: $errorContent,
		openEvt () {

			$('.btnClose', $errorContent).on('click', () => {

				$('#lpModalClose').trigger('click');

			});

		},
		appendContentToForm: false
	};

	options = $.extend({}, defaults, options);

	$('.errorMessage', $errorContent).html(options.errorMessage);
	$('body').lpModal(options);

}

function requestWishListSchema (e) {

	e.preventDefault();
	$.ajax({
		type: 'POST',
		url: `/api/globalwishlists/schemes/${$(e.target).data('schemeid')}?hotelFlag=${$(e.target).data('hotelflag')}`,
		contentType: 'application/json;charset=UTF-8',
		success (response) {

			if (response) {

				if (response.Success) {

					window.location = `${window.lp.globals.secureSiteURL}/wish-list`;

				} else {

					showErrorModal({
						errorMessage:
							wishListErrorMessages[response.ErrorMessage || 'Failure'] || wishListErrorMessages.Failure
					});

				}

			} else {

				showErrorModal({ errorMessage: wishListErrorMessages.Failure });

			}

		},
		error () {

			showErrorModal({ errorMessage: wishListErrorMessages.Failure });

		}
	});

}

function initAddSchemeToWishList (addScheme) {

	addScheme.addEventListener('click', requestWishListSchema);

}

export default initAddSchemeToWishList;
