import './AppProductPriceLegalNotice.scss';

// For now, this is a regular plain JS app rather than a React app due to jQuery dependency for lpTooltip plugin.
// The HTML markup used for the tooltip is generated from the _ProductPriceLegalNotice partial view
// This can be converted into a React app in the future once we have a native React Tooltip component
function AppProductPriceLegalNotice (container, tooltipTrigger, {
	evtType = 'hover', openEvt, closeEvt, ...rest
} = {}, tooltipElSel = '.AppProductPriceLegalNotice',
tooltipContentSel = '.AppProductPriceLegalNotice__content') {

	if (!container || !tooltipTrigger) {

		return;

	}

	const tooltipEl = container.querySelector(tooltipElSel);
	if (!tooltipEl) {

		return;

	}

	const tooltip = tooltipEl.querySelector(tooltipContentSel);
	if (!tooltip) {

		return;

	}

	const $this = $(tooltipEl);

	$this.lpTooltip({
		useParentOffset: true,
		evtType,
		showCloseBtn: true,
		keyboardNavigationTriggerElement: tooltipTrigger,
		closeOnTooltipBtnBlur: true,
		vertAlign: 'bottom',
		arrow: true,
		arrowBorderWidth: 1,
		arrowSize: 14,
		offsetX: 1,
		offsetY: 8,
		borderRadius: '5px',
		autoClose: true,
		boxShadow: {
			enabled: true
		},
		tooltip: $(tooltip),
		openEvt: () => {

			tooltipTrigger.setAttribute('aria-expanded', true);

			// Close all other visible tooltips
			$('body .lpTooltip.showUp').filter((i, el) => el !== tooltip).trigger('forceClose.lpTooltip');

			if (typeof openEvt === 'function') {

				openEvt($this);

			}

		},
		closeEvt: () => {

			tooltipTrigger.setAttribute('aria-expanded', false);

			if (typeof closeEvt === 'function') {

				closeEvt($this);

			}

		},
		...rest
	});

}

export default AppProductPriceLegalNotice;
