/*

	Limited use, only for DOM ID that have no extrinsic value

*/
import { v1 as uuidv1 } from 'uuid';

function uniqueId () {

	return `lp-${uuidv1()}`;

}

export default uniqueId;
