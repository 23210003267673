// for attribute.multiColumnTenPerColumn DT's
function getTotalNumberOfRowsMultiColumn (attributeValuesCount) {

	let totalNumberOfRows = attributeValuesCount;

	if (attributeValuesCount > 10 && attributeValuesCount < 31) {

		totalNumberOfRows = 10;

	} else if (attributeValuesCount > 30) {

		totalNumberOfRows = Math.ceil(attributeValuesCount / 3);

	}

	return totalNumberOfRows;

}

export default getTotalNumberOfRowsMultiColumn;
