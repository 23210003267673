import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SortFilterThumbnail from '../SortFilterThumbnail';
import SortFilterGenericHeader from '../SortFilterGenericHeader';
import isMappedTo from '../../isMappedTo';

const className = 'SortFilterAttrHeader';

const SortFilterAttrHeader = memo(({
	attributeGroupFilterDisplayTypeId, name, headerImageSrc, headerImageAltTag
}) => {

	const pxDimension = 24;
	const ImgComponent = () => (
		<SortFilterThumbnail src={headerImageSrc} alt={headerImageAltTag} height={pxDimension} width={pxDimension} />
	);

	const headerVariationType = isMappedTo.attributeGroup.attrHeaderVariation.which(attributeGroupFilterDisplayTypeId);

	switch (headerVariationType) {

		case 'textWithImage':
			return (
				<SortFilterGenericHeader className={className}>
					<>
						<ImgComponent />
						{name}
					</>
				</SortFilterGenericHeader>
			);
		case 'imageOnly':
			return (
				<SortFilterGenericHeader className={className}>
					<ImgComponent />
				</SortFilterGenericHeader>
			);
		case 'textOnly':
			return (
				<SortFilterGenericHeader className={className}>
					<>
						{name}
					</>
				</SortFilterGenericHeader>
			);
		default:
			return null;

	}

});

SortFilterAttrHeader.propTypes = {
	name: PropTypes.string,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string,
	attributeGroupFilterDisplayTypeId: PropTypes.number
};

export default SortFilterAttrHeader;
