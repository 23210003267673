import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import SortFilterThumbnail from '../SortFilterThumbnail';
import SortFilterAttributeValueDescription from '../SortFilterAttributeValueDescription';

const baseClassName = 'SortFilterAttributeValue';

const SortFilterAttributeValue = ({
	displayName,
	showDisplayName = false,
	description,
	showDescription = false,
	thumbnail,
	showThumbnail = false,
	isGridView = false,
	productCount = null,
	showDescriptionAsDisplayName = false
}) => {

	const {
		src, width, height, alt
	} = thumbnail || {};

	const displayNameText = showDescriptionAsDisplayName ? description : displayName;
	// Align bottom for showDescriptionAsDisplayName because text that supplements image, like "& Up", looks awkward
	// when it is centered with an image that is taller than the text
	const className = classNames(baseClassName, { [`${baseClassName}--alignBottom`]: showDescriptionAsDisplayName });

	return (
		<>
			{isGridView && showThumbnail && src && (
				<SortFilterThumbnail src={src} width={width} height={height} alt={alt} />
			)}

			<div className={className} data-unbxd-facet-name={displayNameText}>
				{!isGridView && showThumbnail && src && (
					<SortFilterThumbnail src={src} width={width} height={height} alt={alt} />
				)}
				<div className={`${baseClassName}__inner`}>
					{showDisplayName && (
						<div className={`${baseClassName}__name`}>
							{displayNameText}
							{Number.isFinite(productCount) && ` (${productCount})`}
						</div>
					)}
					{showDescription && <SortFilterAttributeValueDescription description={description} />}
				</div>
			</div>
		</>
	);

};

SortFilterAttributeValue.defaultProps = {
	description: undefined,
	thumbnail: undefined
};

SortFilterAttributeValue.propTypes = {
	description: PropTypes.string,
	showDescription: PropTypes.bool,
	displayName: PropTypes.string,
	isGridView: PropTypes.bool,
	showDisplayName: PropTypes.bool,
	productCount: PropTypes.number,
	thumbnail: PropTypes.oneOfType([ PropTypes.object ]),
	showThumbnail: PropTypes.bool,
	showDescriptionAsDisplayName: PropTypes.bool
};

export default SortFilterAttributeValue;
