import getSearchUrl from '../getSearchUrl';

import redirectToOpenBoxSearch from './redirectToOpenBoxSearch';

function doOpenBoxSearch (query) {

	if (query) {

		const searchUrl = getSearchUrl(query);
		redirectToOpenBoxSearch(searchUrl);

	}

}

export default doOpenBoxSearch;
