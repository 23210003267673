import isMappedTo from './isMappedTo';

const isValueGroupIndented = (attrDisplayTypeId, attrValGroupDisplayTypeId) => {

	const showGroupView = isMappedTo.attributeValueGroup.groupDisplay.show(attrValGroupDisplayTypeId);
	let isHeaderIndented = false;
	let isBodyAndValuesIndented = false;

	if (!showGroupView) {

		return { isHeaderIndented, isBodyAndValuesIndented };

	}

	switch (isMappedTo.attribute.valGroupIndentation.which(attrDisplayTypeId)) {

		case 'bodyAndValues':
			isBodyAndValuesIndented = true;
			break;
		case 'wholeGroup':
			isHeaderIndented = true;
			isBodyAndValuesIndented = true;
			break;
		default:
			isHeaderIndented = false;
			isBodyAndValuesIndented = false;

	}

	return { isHeaderIndented, isBodyAndValuesIndented };

};

export default isValueGroupIndented;
