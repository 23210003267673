/**
 * Redirect to Open Box searchUrl (search page result)
 * @param {string} searchUrl
 */
function redirectToOpenBoxSearch (searchUrl) {

	const adjustedSearchUrl = searchUrl.replace('#', '');
	const searchPartialUrl = `/products/openbox_view-open-box-items/${adjustedSearchUrl}?s=1`;

	// redirect to search result page
	window.location.href = searchPartialUrl;

}

export default redirectToOpenBoxSearch;
