import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Label = ({
	children, htmlFor, required = false, visuallyHidden = false, className = ''
}) => {

	const labelClass = classNames({
		requiredLabel: required,
		'sr-only': visuallyHidden,
		[className]: Boolean(className)
	});
	const labelProps = {};
	if (labelClass) {

		labelProps.className = labelClass;

	}

	return (
		<label htmlFor={htmlFor} {...labelProps}>
			{children}
		</label>
	);

};

Label.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	htmlFor: PropTypes.string,
	required: PropTypes.bool,
	visuallyHidden: PropTypes.bool
};

export default Label;
