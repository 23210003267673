import displayTypeIdMappings from './displayTypeIdMappings';

// helper that allows you to map an objects properties
const objMap = (obj, mapCallback) => Object.fromEntries(Object.entries(obj).map(mapCallback));
const getIncludesHelper = (arr) => (displayTypeId) => arr.includes(displayTypeId);

/**
 * Object of helper functions. Built from displayTypeIdMappings structure.
 * Can reach into object structure as mapped
 * Returns boolean if displayTypeId is mapped to a certain feature. ie. isMappedTo.attributeGroup.whiteButton(4)
 * For nested feature sets, you get two helpers
 ** any: isMappedTo.attribute.selectionType.any(4) : returns bool if displayTypeId is contained in any of the nested features
 ** which: isMappedTo.attribute.selectionType.which(4) : returns string of key name which displayTypeId is contained in, or null
 */
const isMappedTo = objMap(displayTypeIdMappings, ([ levelKey, levelMappingObj ]) => [
	levelKey,
	objMap(levelMappingObj, ([ featureKey, feature ]) => {

		let returnVal;

		if (Array.isArray(feature)) {

			// the feature array becomes a function to check if it contains display type id
			// (ie. isMappedTo.attributeGroup.whiteButton(4))
			returnVal = getIncludesHelper(feature);

		} else {

			// else if is an object with multiple arrays

			// map through and convert all feature arrays the same way as above
			const mappedNestedFeatures = objMap(feature, ([ nestedKey, nestedArr ]) => [
				nestedKey,
				getIncludesHelper(nestedArr)
			]);

			returnVal = {
				...mappedNestedFeatures,
				any: (displayTypeId) => Object.values(mappedNestedFeatures).some((includes) => includes(displayTypeId)),
				which: (displayTypeId) => {

					const entry = Object.entries(mappedNestedFeatures).find(([ k, includes ]) => includes(displayTypeId));

					// return key name if found
					return entry ? entry[0] : null;

				}
			};

		}

		return [ featureKey, returnVal ];

	})
]);

export default isMappedTo;
