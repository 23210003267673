const filterDataSortingCallback = (a, b) => {

	const aOrder = a.SortOrder;
	const bOrder = b.SortOrder;

	if (aOrder === bOrder) {

		return 0;

	}

	return aOrder > bOrder ? 1 : -1;

};

export default filterDataSortingCallback;
