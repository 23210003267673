import isMappedTo from './isMappedTo';

/**
 * Sets height and width on the html element.
 * @param {Number} attrDisplayTypeId
 * @returns {Object} - Returns object of { width: Number, height: Number }
 */
const getAttrValHeaderImageDimensions = (attrDisplayTypeId) => {

	// TODO grid image sizing is completely handled in scss. The rest should follow soon
	if (isMappedTo.attribute.attrValGridView.any(attrDisplayTypeId)) {

		return {
			width: null,
			height: null
		};

	}

	const attrValHeaderImageSize = isMappedTo.attribute.attrValHeaderImageSize.which(attrDisplayTypeId);
	let width = 24;
	let height = 24;

	if (attrValHeaderImageSize) {

		const [ w, h ] = attrValHeaderImageSize.split('x');
		width = w;
		height = h;

	}

	return {
		width,
		height
	};

};

export default getAttrValHeaderImageDimensions;
