import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import SortFilterAttributeValueList from '../SortFilterAttributeValueList';
import isMappedTo from '../../isMappedTo';

import './SortFilterMultiColumnValueList.scss';

const SortFilterMultiColumnValueList = ({ attrDisplayTypeId, attributeValueGroups }) => {

	const baseClassName = 'SortFilterMultiColumnValueList';
	const valueListBaseClassName = `${baseClassName}__valueList`;

	const valueListColumnType = isMappedTo.attribute.valueListColumns.which(attrDisplayTypeId);
	const isThreeColList = valueListColumnType === 'threeCol';
	const isFourColList = valueListColumnType === 'fourCol';
	const getAmountOfRows = () => {

		if (!valueListColumnType) {

			return null;

		}

		const { length } = attributeValueGroups[0].AttributeValues;

		if (isThreeColList) {

			return Math.ceil(length / 3);

		}

		if (isFourColList) {

			return Math.ceil(length / 4);

		}

		return 3;

	};
	const amountOfRows = getAmountOfRows();

	const valueListClassName = classNames(
		valueListBaseClassName,
		{ [`${valueListBaseClassName}--threeColList`]: isThreeColList },
		{ [`${valueListBaseClassName}--fourColList`]: isFourColList }
	);

	const style = amountOfRows ? { '--valueListWithColumns-rows': amountOfRows } : undefined;

	return (
		<div className={baseClassName} style={style}>
			<SortFilterAttributeValueList
				className={valueListClassName}
				attributeValueGroups={attributeValueGroups}
				attrDisplayTypeId={null}
			/>
		</div>
	);

};

SortFilterMultiColumnValueList.propTypes = {
	attrDisplayTypeId: PropTypes.number,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]).isRequired
};

export default SortFilterMultiColumnValueList;
