import { setGenericEvent } from 'features/tealium/ga4/custom';

/**
 * @param {string} eventName - typically the Event name
 * @param {string} filterName - typically the attribute group name
 * @param {string} attributeValue - typically the attribute value name
 * @param {Boolean} includeFds - should it include fds_display_type in event data
 */
const sortFilterGenericEvent = (eventName, filterName = '', attributeValue = '', includeFds = false) => {

	const eventObj = { event_name: eventName };
	if (filterName) {

		eventObj.filter_name = filterName;

	}
	if (attributeValue) {

		eventObj.attribute_value = attributeValue;

	}
	if (includeFds) {

		const { fds_display_type } = window.utag_data;
		eventObj.fds_type = fds_display_type;

	}
	setGenericEvent(eventObj);

};

export default sortFilterGenericEvent;
