import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { SortFilterNestedDrawer } from '../SortFilterAttrGroupViews';
import SortFilterThumbnail from '../SortFilterThumbnail';
import filterDataSortingCallback from '../../filterDataSortingCallback';
import isMappedTo from '../../isMappedTo';
import SortFilterAttrHeader from '../SortFilterAttrHeader';

import MappedAttributeComponent from './MappedAttributeComponent';
import NestedAttributeDropdown from './NestedAttributeDropdown';

import './SortFilterAttributeGroup.scss';

const SortFilterAttributeGroup = ({
	attributeGroupFilterDisplayTypeId,
	name,
	attributes = [],
	className = '',
	bodyImageSrc,
	bodyImageAltTag,
	hideParentOverlay,
	dropdownMode,
	drawerMode
}) => {

	const defaultClassName = 'SortFilterAttributeGroup';
	const baseInlineAttrContainerClass = `${defaultClassName}__inlineAttrContainer`;
	const fullClassName = classNames(defaultClassName, className);
	const bodyImageClassName = 'SortFilterThumbnail__attrGroupBodyImage';
	const hasNestedAttributes = isMappedTo.attributeGroup.nestedAttributes(attributeGroupFilterDisplayTypeId);
	// used for specials menu, attributes are treated as single values, kind of like 'Pros Specials' at attr group level
	// except that this is for the attribute level
	const isSingleValueAttribute = isMappedTo.attributeGroup.singleValueAttribute(attributeGroupFilterDisplayTypeId);

	attributes.sort(filterDataSortingCallback);

	return (
		<>
			<div className={fullClassName}>
				{bodyImageSrc && (
					<SortFilterThumbnail src={bodyImageSrc} alt={bodyImageAltTag} className={bodyImageClassName} />
				)}

				{attributes.map((attribute) => {

					if (drawerMode && hasNestedAttributes) {

						return (
							<SortFilterNestedDrawer
								key={attribute.Name}
								attributes={[ attribute ]}
								hideParentOverlay={hideParentOverlay}
								attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
								render={() => (
									<MappedAttributeComponent attribute={attribute} sliderOverMinMax={false} />
								)}
							/>
						);

					}

					if (dropdownMode && hasNestedAttributes) {

						return (
							<NestedAttributeDropdown
								attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
								attributeGroupName={name}
								attribute={attribute}
								key={attribute.Name}
							/>
						);

					}

					const largeValGroupHeaderStyle = isMappedTo.attribute.attrValGroupHeaderStyle.largeTextWithLine(
						attribute.AttributeFilterDisplayTypeId
					);
					const inlineAttrContainerClassName = classNames(
						// we don't want to add the top margin between attributes if
						// they are emulated as a list of attribute values (specials menu)
						{ [baseInlineAttrContainerClass]: !isSingleValueAttribute },
						{
							[`${baseInlineAttrContainerClass}--topBorder`]:
								!largeValGroupHeaderStyle && !isSingleValueAttribute
						}
					);

					return (
						<div className={inlineAttrContainerClassName} key={attribute.Name}>
							<SortFilterAttrHeader
								attributeGroupFilterDisplayTypeId={attributeGroupFilterDisplayTypeId}
								name={attribute.Name}
								headerImageSrc={attribute.HeaderImage}
								headerImageAltTag={attribute.HeaderImageAltTag}
							/>
							<MappedAttributeComponent
								key={attribute.Name}
								attribute={attribute}
								isSingleValueAttribute={isSingleValueAttribute}
								sliderOverMinMax={!drawerMode}
								attributeGroupName={name}
							/>
						</div>
					);

				})}
			</div>
		</>
	);

};

SortFilterAttributeGroup.propTypes = {
	attributeGroupFilterDisplayTypeId: PropTypes.number,
	name: PropTypes.string,
	attributes: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				ImageUrl: PropTypes.string,
				Name: PropTypes.string,
				SortOrder: PropTypes.number,
				Type: PropTypes.number,
				AttributeValueSeparator: PropTypes.string,
				AttributeValueGroups: PropTypes.arrayOf(
					PropTypes.shape({
						DisplayName: PropTypes.string,
						ImageUrl: PropTypes.string,
						SortOrder: PropTypes.number,
						Url: PropTypes.string
					})
				)
			})
		)
	]).isRequired,
	className: PropTypes.string,
	bodyImageSrc: PropTypes.string,
	bodyImageAltTag: PropTypes.string,
	hideParentOverlay: PropTypes.func,
	dropdownMode: PropTypes.bool,
	drawerMode: PropTypes.bool
};

export default SortFilterAttributeGroup;
