import React, {
	forwardRef, useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import './Dropdown.scss';

const scrollbarPadding = 30;

const Dropdown = forwardRef(
	(
		{
			show,
			alignEdge,
			anchorEdge,
			children,
			hideDropdown,
			hideOnOutsideClick,
			hideOnEscape,
			hideOnResize,
			'aria-labelledby': ariaLabelledBy,
			isFocusTrapActive,
			focusTrapOptions
		},
		disclosureRef
	) => {

		// auto horizontal will default to left and get nudged as much as necessary to fit in the container
		const isAutoHorizontal = alignEdge === 'autoHorizontal';
		const alignEdgeValue = isAutoHorizontal ? 'left' : alignEdge;
		const [ autoHorizontalOffset, setAutoHorizontalOffset ] = useState(0);
		const dropdownRef = useRef();
		const className = `Dropdown Dropdown-anchor-${anchorEdge} Dropdown-align-${alignEdgeValue}`;

		const handleOutsideMousedown = useCallback(
			(event) => {

				if (dropdownRef.current && !dropdownRef.current.parentElement.contains(event.target)) {

					hideDropdown();

				}

			},
			[ hideDropdown ]
		);

		const handleOutsideEnterOrSpaceKey = useCallback(
			(event) => {

				if (
					(event.keyCode === 13 || event.keyCode === 32)
					&& dropdownRef.current
					&& !dropdownRef.current.parentElement.contains(event.target)
				) {

					hideDropdown();

				}

			},
			[ hideDropdown ]
		);

		const handleEscapeKeyDown = useCallback(
			(event) => {

				if (event.key === 'Escape') {

					if (
						disclosureRef
						&& disclosureRef.current
						&& disclosureRef.current.parentElement.contains(event.target)
					) {

						disclosureRef.current.focus();

					}
					hideDropdown();

				}

			},
			[ hideDropdown ]
		);

		const fitsInViewport = (edge, currentRef) => {

			const rect = currentRef.getBoundingClientRect();

			switch (edge) {

				case 'left': {

					return rect.right + scrollbarPadding <= window.innerWidth;

				}
				case 'right':
					return rect.left >= 0;
				default:
					return true;

			}

		};

		useEffect(() => {

			if (hideOnOutsideClick) {

				document.addEventListener('mousedown', handleOutsideMousedown);
				document.addEventListener('keydown', handleOutsideEnterOrSpaceKey);

			}
			if (hideOnEscape) {

				document.addEventListener('keydown', handleEscapeKeyDown);

			}
			if (hideOnResize) {

				window.addEventListener('resize', hideDropdown);

			}

			return () => {

				document.removeEventListener('mousedown', handleOutsideMousedown);
				document.removeEventListener('keydown', handleOutsideEnterOrSpaceKey);
				document.removeEventListener('keydown', handleEscapeKeyDown);
				window.removeEventListener('resize', hideDropdown);

			};

		});

		useEffect(() => {

			if (isAutoHorizontal && !fitsInViewport(alignEdgeValue, dropdownRef.current)) {

				const rect = dropdownRef.current.getBoundingClientRect();
				setAutoHorizontalOffset(window.innerWidth - (rect.right + scrollbarPadding));

			}

		}, []);

		const leftStyleObj = autoHorizontalOffset !== 0 ? { left: `${autoHorizontalOffset}px` } : {};

		return (
			isFocusTrapActive
				? (
					<FocusTrap focusTrapOptions={{
						initialFocus: false,
						clickOutsideDeactivates: true,
						...focusTrapOptions
					}}
					>
						<div
							ref={dropdownRef}
							className={className}
							style={leftStyleObj}
							aria-labelledby={ariaLabelledBy}
						>
							{children}
						</div>
					</FocusTrap>
				)
				: (
					<div
						ref={dropdownRef}
						className={className}
						style={leftStyleObj}
						aria-labelledby={ariaLabelledBy}
					>
						{children}
					</div>
				)
		);

	}
);

Dropdown.defaultProps = {
	alignEdge: 'left',
	anchorEdge: 'bottom',
	hideOnEscape: true,
	hideOnOutsideClick: true,
	hideOnResize: true,
	isFocusTrapActive: false
};

Dropdown.propTypes = {
	alignEdge: PropTypes.oneOf([ 'top', 'right', 'bottom', 'left', 'autoHorizontal' ]),
	'aria-labelledby': PropTypes.string,
	anchorEdge: PropTypes.oneOf([ 'top', 'right', 'bottom', 'left' ]),
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	hideDropdown: PropTypes.func.isRequired,
	hideOnEscape: PropTypes.bool,
	hideOnOutsideClick: PropTypes.bool,
	show: PropTypes.bool.isRequired,
	hideOnResize: PropTypes.bool,
	isFocusTrapActive: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	focusTrapOptions: PropTypes.object
};

export default Dropdown;
