import applyUrlSegmentCanonically from './applyUrlSegmentCanonically';

/**
 * Parses a number from the string, or returns null
 * @param {string} val - Input value from range form
 * @returns {Number}
 */
const parseValue = (val) => {

	if (!val) {

		return null;

	}

	return val.includes('.') ? parseFloat(val) : parseInt(val, 10);

};

const braceRegex = /{(\w+)}/g;
const bracketRegex = /\[\w+\]/;
/**
 * Goes through term template and replaces each {} placeholder.
 * Uses the term key inside the {} to know which user input term to use (IntegerTerm vs DecimalTerm).
 * @param {Number[]} values
 * @param {Object} terms - { template(minTerm | maxTerm | rangeTerm), InputTerm, IntegerTerm, DecimalTerm }
 */
const parseTerm = (values, terms) => {

	// splits out values into flat array of int and decimal for ex. ['10', '99', '69', '99']
	const splitValues = values
		.map((val) => {

			if (!val) {

				return [ null, null ];

			}

			let valString = `${val}`;
			valString = valString.includes('.') ? val.toFixed(2) : valString;
			const split = valString.split('.');

			// [1] is expected to be undefined if its an integer
			return [ split[0], split[1] ];

		})
		.flat();

	let counter = 0;

	// parses user input term templates with square brackets []
	const parseSquareBracketWithUserInputs = (bracketTerm) => {

		const val = splitValues[counter];
		counter += 1;

		return val ? bracketTerm.replace(bracketRegex, val) : '';

	};

	const parseTermRecursively = (template) => {

		// if term has brackets that means it needs to be filled in with user input
		if (template.includes('[') && template.includes(']')) {

			return parseSquareBracketWithUserInputs(template);

		}

		// ...matchAll returns array of arrays, each one containing the full match (braces included "{ExampleTerm}"),
		// and also the group match (without braces "ExampleTerm")
		return [ ...template.matchAll(braceRegex) ].reduce(
			(accum, [ fullMatch, groupMatch ]) => accum.replace(fullMatch, parseTermRecursively(terms[groupMatch])),
			template
		);

	};

	return parseTermRecursively(terms.template);

};

/**
 * Applies range inputs to sort. Accepts term templates from data and parses with user inputs.
 * @param {string} minInputVal
 * @param {string} maxInputVal
 * @param {string} DecimalTerm - User input term template referenced from min/max/range term
 * @param {string} IntegerTerm - User input term template referenced from min/max/range term
 * @param {string} InputTerm - User input term template referenced from min/max/range term
 * @param {string} minTerm - Term used for minimum value of range
 * @param {string} maxTerm - Term used for maximum value of range
 * @param {string} rangeTerm - Term used for range value of range
 * @param {string} searchPrefix - Prefix for the url segment
 * @param {Object} canonicalOrderedUrlFragments
 * @param {Number} canonicalOrder
 */
const applyRangeValues = (
	minInputVal,
	maxInputVal,
	DecimalTerm,
	IntegerTerm,
	InputTerm,
	minTerm,
	maxTerm,
	rangeTerm,
	searchPrefix,
	canonicalOrderedUrlFragments,
	canonicalOrder
) => {

	// eslint-disable-next-line eqeqeq
	if ((!minInputVal && !maxInputVal) || (minInputVal == 0 && maxInputVal == 0)) {

		return;

	}

	const parsedMin = parseValue(minInputVal);
	const parsedMax = parseValue(maxInputVal);
	const isReversed = parsedMin !== null && parsedMax !== null && parsedMax !== 0 && parsedMin > parsedMax;
	const minVal = isReversed ? parsedMax : parsedMin;
	const maxVal = isReversed ? parsedMin : parsedMax;
	// filter out null/0 value
	const values = [ minVal, maxVal ].filter((val) => val !== null && val !== 0);
	let template;

	if (minVal && maxVal) {

		template = rangeTerm;

	} else if (minVal) {

		template = minTerm;

	} else if (maxVal) {

		template = maxTerm;

	}

	const parsedTerm = parseTerm(values, {
		template,
		InputTerm,
		IntegerTerm,
		DecimalTerm
	});
	const urlSegment = `${searchPrefix}${parsedTerm}`;

	applyUrlSegmentCanonically(canonicalOrderedUrlFragments, canonicalOrder, urlSegment);

};

export default applyRangeValues;
