import PropTypes from 'prop-types';
import React from 'react';

import appliedFiltersSortingCallback from '../../appliedFiltersSortingCallback';
import flattenAppliedFilters from '../../flattenAppliedFilters';
import getPersistedQueryParameters from '../../getPersistedQueryParameters';
import SortFilterAppliedFilter from './SortFilterAppliedFilter';

import './SortFilterAppliedFilters.scss';

const SortFilterAppliedFilters = ({ appliedFilters }) => {

	const sortedFilters = appliedFilters.sort(appliedFiltersSortingCallback);
	const flattenedFilters = flattenAppliedFilters(sortedFilters);
	const hasMultipleFilters = flattenedFilters.length > 1;

	return (
		<ul className="SortFilterAppliedFilters">
			<li className="SortFilterAppliedFilter">
				{flattenedFilters.map(
					({ DisplayAttributeName, FilteredDisplayAttributeValue, StepbackLinkAttributeValue }, index) => {

						// We don't allow the last applied filter to be removed
						const stepbackLink = hasMultipleFilters
							? `${StepbackLinkAttributeValue}${getPersistedQueryParameters()}`
							: null;

						const key = `${DisplayAttributeName}-${index}`;
						return (
							<SortFilterAppliedFilter
								key={key}
								name={DisplayAttributeName}
								value={FilteredDisplayAttributeValue}
								stepbackLink={stepbackLink}
							/>
						);

					}
				)}
			</li>
		</ul>
	);

};

SortFilterAppliedFilters.propTypes = {
	appliedFilters: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				DisplayAttributeName: PropTypes.string,
				FilteredDisplayAttributeValues: PropTypes.arrayOf(PropTypes.string),
				StepbackLink: PropTypes.string
			})
		)
	]).isRequired
};

export default SortFilterAppliedFilters;
