/**
 * Gets Filter Display Set (FDS) AB Test mapping.
 * Mapping is added to window on page load by client-side AB Test API (Dynamic Yield)
 */
const getFdsAbTestId = () => {

	const { fdsAbTestId } = window;

	return Number.isInteger(fdsAbTestId) ? fdsAbTestId : null;

};

export default getFdsAbTestId;
