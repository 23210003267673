import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function DropdownWrapper ({
	children, id, className, ...rest
}) {

	const classes = classNames('DropdownWrapper', className);

	return (
		<div id={id} className={classes} {...rest}>
			{children}
		</div>
	);

}

DropdownWrapper.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string,
	id: PropTypes.string
};

export default DropdownWrapper;
