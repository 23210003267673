/**
 * Flatten array of applied filters to split out array of multiple attribute values into a single value. This applies
 * to multi-selected values per attribute, one or more sets of single-selected value per attribute and mixed of both.
 * @example
 * <caption>Sample output:</caption>
 *	[
 *		{
 *			DisplayAttributeName: 'Category',
 *			FilteredDisplayAttributeValue: 'Pendant Lighitng',
 *			StepbackLinkAttributeValue: '/products/29_1260~o1266/'
 *		},
 *		{
 *			DisplayAttributeName: 'Style',
 *			FilteredDisplayAttributeValue: 'Contemporary',
 *			StepbackLinkAttributeValue: '/products/pendant-lighting/style_farmhouse/'
 *		},
 *		{
 *			DisplayAttributeName: 'Style',
 *			FilteredDisplayAttributeValue: 'Farmhouse',
 *			StepbackLinkAttributeValue: '/products/pendant-lighting/style_contemporary/'
 *		}
 *	]
 * @param {Array} appliedFilters Array of applied filters
 * @returns The flattened array of filters
 */
const flattenAppliedFilters = (appliedFilters) => (Array.isArray(appliedFilters)
	? appliedFilters.reduce(
		(acc, val) => [
			...acc,
			...val.FilteredDisplayAttributeValues.reduce(
				(acc2, val2, index2) => [
					...acc2,
					{
						DisplayAttributeName: val.DisplayAttributeName,
						FilteredDisplayAttributeValue: val2,
						StepbackLinkAttributeValue: val.StepbackLinkAttributeValues[index2]
					}
				],
				[]
			)
		],
		[]
		  )
	: []);

export default flattenAppliedFilters;
