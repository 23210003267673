import { getQueryStringParameters, getQueryStringValue } from 'utilities/browser';
import filterDataSortingCallback from 'features/sort/filterDataSortingCallback';

const getIdList = (param) => {

	const value = getQueryStringValue(param);

	return value ? value.split('-').map((id) => Number(id)) : null;

};

// if only 1 cell in array, it wont remove it
const getNextId = (arr) => (arr.length > 1 ? arr.shift() : arr[0]);

// assigns a value recursively to all properties matching a certain key name
const assignValueToProperties = () => {

	const otherQueryStrings = Object.entries(getQueryStringParameters())
		.filter(([ key ]) => key !== 'setid' && key !== 'attrgroupid' && key !== 'attrid' && key !== 'attrvaluegroupid')
		.map(([ k, value ]) => {

			// if it contains quotes, remove them and assign string, if not, either its a bool or a number

			if (value.includes('"')) {

				const replacedQuotes = value.replace(/"/g, '');
				const isDimensions =					replacedQuotes.includes('x') && Number.isSafeInteger(parseInt(replacedQuotes.replace('x', ''), 10));

				// if its dimensions, assign a placeholder image href
				if (isDimensions) {

					return [ k, `https://via.placeholder.com/${replacedQuotes}` ];

				}

				return [ k, replacedQuotes ];

			}

			if (value === 'true' || value === 'false') {

				// eslint-disable-next-line no-unneeded-ternary
				return [ k, value === 'true' ? true : false ];

			}

			const parsed = parseInt(value, 10);

			if (Number.isSafeInteger(parsed)) {

				return [ k, parsed ];

			}

			return [ k, null ];

		});

	// recursive assignment
	const reassignProp = (obj, keyToReplace, newValue) => {

		// eslint-disable-next-line no-restricted-syntax
		for (const prop in obj) {

			// eslint-disable-next-line no-param-reassign
			if (prop.toLowerCase() === keyToReplace.toLowerCase()) obj[prop] = newValue;
			// eslint-disable-next-line no-unused-vars
			else if (typeof obj[prop] === 'object') reassignProp(obj[prop], keyToReplace, newValue);

		}

	};

	otherQueryStrings.forEach(([ k, value ]) => reassignProp(window.lp.pageData.sortFilter.managedSortModels, k, value));

};

/**
 * Use as so,
 * ?setid=1&attrgroupid=32&attrid=8&attrvaluegroupid=38-34-41-45
 * You can use dashed values to set each subsequent group to a different display type id.
 * If there exists more values than the dashed ids in a certain group, it will just set the remaining groups
 * to the last value in the dashed list (ie. 45)
 *
 * Can also set any property in data recursively to a certain value, use quotes for strings, INTxINT for placeholder img
 * for ex. ?setid=2&DisplayBodyAltTag="Test Alt"&BodyImage="30x30"&ShowAttributeValueCounts=true&ProductCount=18
 */
const forceDisplayTypeId = () => {

	if (!window.lp.pageData.enableDisplayTypeMocking) return;

	assignValueToProperties();

	const { managedSortModels } = window.lp.pageData.sortFilter;
	const setIds = getIdList('setid');
	const attrGroupIds = getIdList('attrgroupid');
	const attrIds = getIdList('attrid');
	const attrValueGroupIds = getIdList('attrvaluegroupid');

	managedSortModels.forEach((model) => {

		// sort everything first
		model.AttributeGroups.sort(filterDataSortingCallback).forEach((attrGroup) => {

			attrGroup.Attributes.sort(filterDataSortingCallback).forEach((attr) => attr.AttributeValueGroups.sort(filterDataSortingCallback));

		});

		if (setIds) {

			// eslint-disable-next-line no-param-reassign
			model.FilterDisplayTypeId = getNextId(setIds);

		}

		const attrGroupIdsCopy = attrGroupIds && [ ...attrGroupIds ];

		model.AttributeGroups.forEach((attrGroup) => {

			if (attrGroupIdsCopy) {

				// eslint-disable-next-line no-param-reassign
				attrGroup.AttributeGroupFilterDisplayTypeId = getNextId(attrGroupIdsCopy);

			}

			const attrIdsCopy = attrIds && [ ...attrIds ];

			attrGroup.Attributes.forEach((attr) => {

				if (attrIdsCopy) {

					// eslint-disable-next-line no-param-reassign
					attr.AttributeFilterDisplayTypeId = getNextId(attrIdsCopy);

				}

				const attrValueGroupIdsCopy = attrValueGroupIds && [ ...attrValueGroupIds ];

				if (attrValueGroupIdsCopy) {

					attr.AttributeValueGroups.forEach((attrValGroup) => {

						// eslint-disable-next-line no-param-reassign
						attrValGroup.AttributeValueGroupFilterDisplayTypeId = getNextId(attrValueGroupIdsCopy);

					});

				}

			});

		});

	});

};

export default forceDisplayTypeId;
