import { addToWishList, removeFromWishList } from 'features/wishList/desktop';

function initWishList () {

	const addToWishlistSuccessMessage = 'Added to \n Wish List';
	const removeFromWishlistSuccessMessage = 'Removed from \n Wish List';
	const wishlistGenericErrorMessage = 'An Error Occurred - Please Try Again';
	const addToWishlistLimitErrorMessage = 'Wish List Limit Reached';

	const helpers = {
		removeItemsFromWishlist (e) {

			const el = e.currentTarget;
			const targetElementForOverlay = el
				?.closest('.sortResultContainer')
				?.querySelector('.sortResultImgContainer');
			el.classList.add('disabled');

			if (el.classList.contains('lpIcon-favoriteselected')) {

				el.classList.remove('lpIcon-favoriteselected');
				el.classList.add('lpIcon-favorite');

			}

			const dataArray = [
				{
					wishlistToken: window.lp.wishlist.activeWishlistToken,
					wishlistItemId: el.dataset.wishlistItemId
				}
			];

			const successCallback = () => {

				helpers.showWishListMessages(targetElementForOverlay, removeFromWishlistSuccessMessage);
				el.classList.remove('disabled');

			};

			const errorCallback = () => {

				helpers.showWishListMessages(targetElementForOverlay, wishlistGenericErrorMessage, false);
				el.classList.remove('disabled');

			};

			// remove item from wishlist
			removeFromWishList(dataArray, successCallback, errorCallback);

		},

		showWishListMessages (targetElement, messageText, success = true) {

			const messageContainer = document.createElement('div');
			if (success) {

				messageContainer.classList.add('wishlistMessage');

			} else {

				messageContainer.classList.add('wishlistMessage', 'red');

			}
			messageContainer.innerText = messageText;
			messageContainer.addEventListener('animationend', (e) => {

				e.target.remove();

			});

			targetElement.appendChild(messageContainer);

		}
	};

	const ui = {
		addToWishlistButtons: document.querySelectorAll('#sortResultProducts .heart')
	};

	const events = {
		addItemsToWishlist (e) {

			e.preventDefault();
			const el = e.currentTarget;
			const targetElementForOverlay = el
				?.closest('.sortResultContainer')
				?.querySelector('.sortResultImgContainer');
			el.classList.add('disabled');

			if (el.classList.contains('lpIcon-favoriteselected')) {

				helpers.removeItemsFromWishlist(e);

			} else {

				const data = [
					{
						ShortSku: e.currentTarget.dataset.sku,
						Quantity: Number($('#QtyNormal').val()) || 1
					}
				];

				const successCallback = (response) => {

					el.classList.remove('lpIcon-favorite');
					el.classList.add('lpIcon-favoriteselected');

					window.lp.wishlist.activeWishlistToken = response.CurrentWishListToken;
					el.setAttribute('data-wishlist-item-id', response.Item.AddedShortSkus[0].WishListItemId);

					helpers.showWishListMessages(targetElementForOverlay, addToWishlistSuccessMessage);
					el.classList.remove('disabled');

				};

				const errorCallback = (error, obj) => {

					let messageText;

					if (typeof error === 'boolean') {

						messageText =							obj.ErrorMessage === 'FailureWishListFull'
							|| obj.ErrorMessage === 'FailureTryingToAddTooManyItems'
							? addToWishlistLimitErrorMessage
							: wishlistGenericErrorMessage;

					} else {

						messageText =							error.ErrorMessage === 'FailureWishListFull'
							|| error.ErrorMessage === 'FailureTryingToAddTooManyItems'
							? addToWishlistLimitErrorMessage
							: wishlistGenericErrorMessage;

					}
					// To display messages
					helpers.showWishListMessages(targetElementForOverlay, messageText, false);
					el.classList.remove('disabled');

				};
				// Add item to wishlist
				addToWishList(data, successCallback, errorCallback);

			}

		}
	};

	function init () {

		const { addToWishlistButtons } = ui;

		const { addItemsToWishlist } = events;

		if (addToWishlistButtons.length) {

			addToWishlistButtons.forEach((btn) => {

				btn.addEventListener('click', addItemsToWishlist);

			});

		}

	}
	init();

}

export default initWishList;
