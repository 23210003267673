import { setGenericEvent } from 'features/tealium/ga4/custom';

function initMoreLikeThis () {

	const sortMoreLikeThisBtns = document.querySelectorAll('.sortMoreLikeThisBtn');
	sortMoreLikeThisBtns.forEach((moreLikeThisLink) => {

		const { sku } = moreLikeThisLink.closest('.sortResultContainer').dataset;

		moreLikeThisLink.addEventListener('click', () => {

			// More Like This button tracking
			// TODO: Probably we should be tracking More Like This here, and disabling a Tealium extension, keeping the code
			// See LP-44020
			// trackSortPageEvents({
			// 	eventAction: 'Click-More-Like-This',
			// 	eventLabel: sku
			// });

			setGenericEvent({ event_name: 'sort_mlt_click', mlt_item_id: sku });

		});

	});

}

export default initMoreLikeThis;
