import { useEffect, useState, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import ModelContext from './modelContext';
import CanonicalFragment from './CanonicalFragment';
import findAttributeValueById from './findAttributeValueById';
import getPersistedQueryParameters from './getPersistedQueryParameters';

const getArray = (value) => (([ 'string', 'boolean' ].includes(typeof value) ? [ value ] : value) ?? []).filter(Boolean);

const getCanonicalFragment = (
	attributeId,
	encodedAttributeName,
	canonicalNumber,
	valueIds,
	valueEncodedStrings,
	valueSeparator
) => {

	if (!valueIds.length) {

		return new CanonicalFragment(attributeId, canonicalNumber, '', '');

	}

	const encodedName = encodedAttributeName.endsWith('_')
		? encodedAttributeName.replace('_', '')
		: encodedAttributeName;
	const nameLowered = encodedName.toLowerCase();
	let idBasedFragment;
	let textBasedFragment;

	if (nameLowered === 'category') {

		idBasedFragment = `${valueIds.join(valueSeparator)}`;
		textBasedFragment = valueEncodedStrings[0];

	} else if (nameLowered === 'p') {

		idBasedFragment = `${encodedName}_${valueEncodedStrings.join(valueSeparator)}`;
		textBasedFragment = `${encodedName}_${valueEncodedStrings[0]}`;

	} else {

		idBasedFragment = `${attributeId}_${valueIds.join(valueSeparator)}`;
		textBasedFragment = `${encodedName}_${valueEncodedStrings[0]}`;

	}

	return new CanonicalFragment(attributeId, canonicalNumber, idBasedFragment, textBasedFragment);

};

// used for each attribute
function useChecklistDraftState ({
	Name,
	AttributeId,
	AttributeValueGroups,
	AttributeValueSeparator,
	CanonicalNumber,
	EncodedAttributeName
}) {

	const {
		canAttributesMapToFds, fdsQueryRulesChoices, CanonicalOrderedUrlFragments, fdsFacetCanonicalData
	} =		useContext(ModelContext);
	const [ defaultValues, setDefaultValues ] = useState([]);
	const { setValue, watch } = useFormContext();

	const valueList = watch(Name);
	const currentListArr = getArray(valueList);
	const applyDisabled =		(!defaultValues?.length && !currentListArr?.length)
		|| (currentListArr?.every((id) => defaultValues?.includes(id))
			&& defaultValues?.length === currentListArr?.length);
	const clearDisabled = !currentListArr?.length;

	const isModifyingSearchKeyMapping =		fdsQueryRulesChoices.some((attr) => attr.Id === AttributeId) && canAttributesMapToFds;
	const isModifyingCategory = EncodedAttributeName.replace('_', '').toLowerCase() === 'category';
	const attributeValue = findAttributeValueById(AttributeValueGroups, currentListArr[0]);
	const currentSelection = getCanonicalFragment(
		AttributeId,
		EncodedAttributeName,
		CanonicalNumber,
		currentListArr,
		[ attributeValue?.EncodedValue ],
		AttributeValueSeparator
	);

	const searchKeyFragments = isModifyingSearchKeyMapping
		? fdsQueryRulesChoices.map(({
			Id, EncodedName, CanonicalOrder, AttributeValues, Operator
		}) => {

			const valueIds = AttributeValues.map((v) => v.Id);
			const encodedValues = AttributeValues.map((v) => v.EncodedValue);
			const operator = Operator.toLowerCase() === 'and' ? '~a' : '~o';

			return getCanonicalFragment(Id, EncodedName, CanonicalOrder, valueIds, encodedValues, operator);

		})
		: [];

	const userAppliedFragments = CanonicalOrderedUrlFragments.IdBasedList.filter(
		(idBasedFragment) => !(isModifyingSearchKeyMapping && idBasedFragment.startsWith('s_'))
	).map((idBasedFragment, i) => {

		const textBasedFragment = CanonicalOrderedUrlFragments.TextBasedList[i];

		if (idBasedFragment.startsWith('s_')) {

			return new CanonicalFragment(null, null, idBasedFragment, textBasedFragment);

		}

		const isCategory = !idBasedFragment.includes('_');
		let attrId;
		let attributeCanonical;

		if (isCategory) {

			attributeCanonical = fdsFacetCanonicalData?.find((data) => data.Name?.toLowerCase() === 'category')?.Number;

		} else if (idBasedFragment.toLowerCase().startsWith('p_')) {

			attributeCanonical = fdsFacetCanonicalData?.find((data) => data.Name?.toLowerCase() === 'p')?.Number;

		} else {

			attrId = parseInt(idBasedFragment.split('_')[0], 10);
			attributeCanonical = fdsFacetCanonicalData?.find((data) => data.Id === attrId)?.Number;

		}

		return new CanonicalFragment(attrId, attributeCanonical, idBasedFragment, textBasedFragment);

	});

	const allFragmentsBesidesCurrentApplied = [ ...searchKeyFragments, ...userAppliedFragments ].filter(
		(canonicalFragment) => canonicalFragment.attributeId !== AttributeId
			&& !(isModifyingCategory && !canonicalFragment.idBasedFragment.includes('_'))
	);

	const appliedAttributeFragmentsStr = (
		currentListArr.length
			? [ ...allFragmentsBesidesCurrentApplied, currentSelection ]
			: allFragmentsBesidesCurrentApplied
	)
		.sort((a, b) => a.canonicalNumber - b.canonicalNumber)
		.map((frag, i, arr) => (arr.some(({ isMultiSelect }) => isMultiSelect) ? frag.idBasedFragment : frag.textBasedFragment))
		.join('/');

	const reset = () => setValue(Name, Array.isArray(watch(Name)) ? [] : undefined);

	const applyMultiValueList = () => {

		const appliedFragments = `${appliedAttributeFragmentsStr}${appliedAttributeFragmentsStr.endsWith('/') ? '' : '/'}`;
		window.location.href = `/products/${appliedFragments}${getPersistedQueryParameters()}`;

	};

	useEffect(() => setDefaultValues(getArray(watch(Name))), []);

	return {
		setValue,
		reset,
		valueList: currentListArr,
		defaultValues,
		applyDisabled,
		clearDisabled,
		applyMultiValueList
	};

}

export default useChecklistDraftState;
