import React from 'react';
import PropTypes from 'prop-types';

import './RangeMinMaxLabels.scss';

const RangeMinMaxLabels = ({
	min, max, prefix, plusOnMax
}) => (
	<div className="RangeMinMaxLabels">
		<div>
			{prefix}
			{min.toLocaleString()}
		</div>
		<div>
			{prefix}
			{max.toLocaleString()}
			{plusOnMax ? '+' : ''}
		</div>
	</div>
);

RangeMinMaxLabels.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	prefix: PropTypes.string,
	plusOnMax: PropTypes.bool
};

export default RangeMinMaxLabels;
