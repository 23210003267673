import { useState } from 'react';

import { utagViewArtificialPageView } from 'features/tealium';

const trackArtificalPageViewEvent = utagViewArtificialPageView();

function useOverlayState (initiallyShown = false, pushStateHash = '') {

	const [ show, setShow ] = useState(initiallyShown);
	const hashchangeHandler = (e) => {

		const { oldURL } = e;
		const oldURLHash = new URL(oldURL).hash;
		if (oldURLHash === pushStateHash) {

			// eslint-disable-next-line no-use-before-define
			hideOverlay(true);
			window.removeEventListener('hashchange', hashchangeHandler);

		}

	};
	const pushState = () => {

		if (pushStateHash) {

			const currentHash = window.location.hash;
			if (!currentHash || currentHash !== pushStateHash) {

				window.history.pushState(null, '', pushStateHash);

			}
			window.addEventListener('hashchange', hashchangeHandler);

		}

	};
	const back = () => {

		if (pushStateHash) {

			window.removeEventListener('hashchange', hashchangeHandler);
			window.history.back();

		}

	};
	const showOverlay = () => {

		setShow(true);
		pushState();

	};
	const hideOverlay = (skipHistoryBack) => {

		trackArtificalPageViewEvent({ pageName: '' });
		setShow(false);

		if (!skipHistoryBack) {

			back();

		}

	};

	if (show) {

		pushState();

	}

	return {
		show,
		showOverlay,
		hideOverlay
	};

}

export default useOverlayState;
