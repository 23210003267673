const checkIfVideoAPIReady = () => new Promise((resolve) => {

	const { YT } = window;
	if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {

		window.onYouTubeIframeAPIReady = function () {

			resolve();

		};

	} else {

		resolve();

	}

});

export default checkIfVideoAPIReady;
