// Dynamically returns array of exponential steps for ExpoRangeSlider based on predetermined multipliers

const defaultExpoStepMultiplier = [ 0.0025, 0.005, 0.005, 0.01, 0.05 ];
const roundDownToTen = (num) => Math.floor(num / 10) * 10;

// if under 1000 steps, it will function as a linear slider
const getDynamicExpoSteps = (min, max) => (max - min >= 1000
	? defaultExpoStepMultiplier.map((multiplier) => {

		const result = Math.round((max - min) * multiplier);
		// if 2 digit number, round down to 10. We are rounding down because it looks natural to have even numbers
		// in the labels. We prefer round down because its better to undershoot than overshoot with the step values.
		// When you overshoot, your last two steps on the slider can both be "max" and "max+" because of rounding calcs
		const rounded = result > 9 ? roundDownToTen(result) : result;
		return Math.max(1, rounded);

	})
	: [ 1 ]);

export default getDynamicExpoSteps;
