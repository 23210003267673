import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import './SortFilterThumbnail.scss';

const SortFilterThumbnail = ({
	src, width, height, alt, className
}) => {

	const [ imgLoaded, setImgLoaded ] = useState(false);
	const imgSrc = src && !src.includes('http') && src[0] !== '/' ? `/${src}` : src;
	const fullClassName = classNames('SortFilterThumbnail', className, { skeleton: !imgLoaded });
	const altText = alt || ''; // for null values as it is required in LazyImage
	const onLoad = () => setImgLoaded(true);

	return (
		<img
			src={imgSrc}
			className={fullClassName}
			width={width}
			height={height}
			alt={altText}
			onLoad={onLoad}
			onError={onLoad}
		/>
	);

};

SortFilterThumbnail.defaultProps = {
	height: undefined,
	width: undefined,
	alt: ''
};

SortFilterThumbnail.propTypes = {
	src: PropTypes.string.isRequired,
	height: PropTypes.number,
	width: PropTypes.number,
	alt: PropTypes.string,
	className: PropTypes.string
};

export default SortFilterThumbnail;
