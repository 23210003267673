import { checkIfVideoAPIReady, videoOptions, VideoPlayer } from 'features/youtube';

const { apiSettings, aspectRatio } = videoOptions;

function getDynamicVideoWidth () {

	return document.documentElement.clientWidth <= 1000 ? 700 : 900;

}

function getDynamicVideoHeight () {

	const screenHeight = document.documentElement.clientHeight;

	if (screenHeight <= 600) {

		return 500;

	}
	if (screenHeight <= 800) {

		return 600;

	}
	if (screenHeight <= 1000) {

		return 700;

	}
	return 900;

}

function watchVideoClickHandler (e) {

	e.preventDefault();

	const { videoId } = e.target.dataset;
	const width = getDynamicVideoWidth();
	const height = getDynamicVideoHeight() * aspectRatio;
	const playerName = 'splashPopupVideo';
	let player;

	if (!videoId) {

		console.error('[Video Player Error] - Video ID is missing');
		return;

	}

	$('body').lpModal({
		onInit: true,
		lpModalClass: 'lpModal--alt1',
		content: $(`<div id="${playerName}"></div>`),
		width,
		height,
		openEvt: () => {

			checkIfVideoAPIReady().then(() => {

				// initialize player when modal opens
				player = VideoPlayer(playerName, {
					width,
					height,
					videoId,
					playerVars: apiSettings
				});

			});

		},
		closeEvt: () => {

			// destroy player when modal closes
			player.destroy();
			player = null;

		}
	});

}

function initSplashVideo (watchVideo) {

	if (watchVideo.length) {

		watchVideo.forEach((element) => {

			element.addEventListener('click', watchVideoClickHandler);

		});

	}

}

export default initSplashVideo;
