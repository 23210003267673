import PropTypes from 'prop-types';
import React from 'react';

const Field = ({ children, className }) => (
	<div className={`field ${className}`}>
		{children}
	</div>
);

Field.defaultProps = {
	className: ''
};

Field.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	className: PropTypes.string
};

export default Field;
