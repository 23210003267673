import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';

const renderWithStore = (Component, element) => {

	render(<Provider store={store}>
		{Component}
        </Provider>, element);

};

export default renderWithStore;
