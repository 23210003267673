import React, { memo } from 'react';
import PropTypes from 'prop-types';

import isMappedTo from '../../isMappedTo';
import SortFilterThumbnail from '../SortFilterThumbnail';
import SortFilterGenericHeader from '../SortFilterGenericHeader';

const SortFilterAttrGroupHeader = memo(
	({
		name, attributeGroupFilterDisplayTypeId, headerImageSrc, headerImageAltTag, numApplied
	}) => {

		const pxDimension = 24;
		const ImgComponent = () => (
			<SortFilterThumbnail
				src={headerImageSrc}
				alt={headerImageAltTag}
				height={pxDimension}
				width={pxDimension}
			/>
		);
		const groupName = numApplied ? `${name} (${numApplied})` : name;

		const attributeGroupHeaderVariationType = isMappedTo.attributeGroup.headerStyle.which(
			attributeGroupFilterDisplayTypeId
		);
		switch (attributeGroupHeaderVariationType) {

			case 'imageOnly':
				return (
					<SortFilterGenericHeader>
						<ImgComponent />
					</SortFilterGenericHeader>
				);

			case 'imageWithText':
				return (
					<SortFilterGenericHeader>
						<>
							<ImgComponent />
							{groupName}
						</>
					</SortFilterGenericHeader>
				);

			case 'textOnly':
			default:
				return (
					<SortFilterGenericHeader>
						<>
							{groupName}
						</>
					</SortFilterGenericHeader>
				);

		}

	}
);

SortFilterAttrGroupHeader.propTypes = {
	name: PropTypes.string.isRequired,
	attributeGroupFilterDisplayTypeId: PropTypes.number,
	headerImageSrc: PropTypes.string,
	headerImageAltTag: PropTypes.string,
	numApplied: PropTypes.number
};

export default SortFilterAttrGroupHeader;
