import {
	SortFilterDisplaySetDropdowns,
	SortFilterDisplaySetDrawer
} from 'features/sort/components/SortFilterDisplaySet';

import isMappedTo from './isMappedTo';

function getSortDisplayTypeComponent (displayTypeId) {

	switch (isMappedTo.set.interfaceType.which(displayTypeId)) {

		case 'horizontal':
			return SortFilterDisplaySetDropdowns;
		case 'drawer':
			return SortFilterDisplaySetDrawer;
		default:
			return SortFilterDisplaySetDropdowns;

	}

}

export default getSortDisplayTypeComponent;
