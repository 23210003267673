import { updateCounts } from 'features/asset/desktop';

import errorMessages from '../errorMessages';

function removeFromWishList (arrData, successCallback, errorCallback) {

	$.ajax({
		type: 'POST',
		url: `/api/globalwishlists/items/${arrData[0].wishlistToken}/${arrData[0].wishlistItemId}`,
		success (response) {

			if (response.Success) {

				if (typeof successCallback === 'function') {

					updateCounts();
					successCallback.call(this, response);

				}

			} else if (typeof errorCallback === 'function') {

				errorCallback.call(this, response);

			} else {

				const msg = response.ErrorMessage ? errorMessages[response.ErrorMessage] : errorMessages.Failure;
				console.warn(msg);

			}

		},
		error (error) {

			if (typeof errorCallback === 'function') {

				errorCallback.call(this, false);

			} else {

				const msg = error.ErrorMessage ? errorMessages[error.ErrorMessage] : errorMessages.Failure;
				console.warn(msg);

			}

		}
	});

}

export default removeFromWishList;
