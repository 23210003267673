import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { uniqueId } from 'utilities/dom';
import { Field, Input, Label } from 'components/Form';
import ExpoRangeSlider, { getFindOnScaleFunc, getDynamicExpoSteps } from 'components/Form/components/ExpoRangeSlider';
import { Button } from 'components/Button';
import sortFilterGenericEvent from 'features/sort/sortFilterGenericEvent';

import applyRangeValues from '../../applyRangeValues';
import SortFilterAttributeValueList from '../SortFilterAttributeValueList';
import sortFilterUtagLink from '../../sortFilterUtagLink';

import './SortFilterSliderTwoThumb.scss';

// Matches following examples: '.', '32', '32.', '32.34', ''. Ran against entire input value on input change.
const filterInputRegex = /(\d{0,6})?(\.(\d{1,2})?)?/g;
const raiseThumbClass = 'RangeSlider--raiseMinThumb';

const SortFilterSliderTwoThumb = ({
	htmlName,
	attributeValueGroups,
	displayTypeData,
	hideAttributeValues,
	showAttributeValueCounts,
	sliderOverMinMax = true
}) => {

	const {
		MinimumRangeValue: min,
		MaximumRangeValue: max,
		Prefix: prefix,
		PlusOnMax: plusOnMax = true,
		DecimalTerm,
		PriceTerm,
		MinAndUpSearchTerm,
		RangeSearchTerm,
		UnderAndMaxSearchTerm,
		SearchFilterPrefix
	} = displayTypeData;

	const idMin = useRef(uniqueId()).current;
	const idMax = useRef(uniqueId()).current;
	const EXPO_STEPS = getDynamicExpoSteps(min, max);
	const findScale = getFindOnScaleFunc(min, max, EXPO_STEPS);
	const [ rangeValues, setRangeValues ] = useState([ findScale(min), findScale(max) ]);
	// Used to make sure min thumb is always grabbable
	const [ rangeClass, setRangeClass ] = useState('');
	const { watch, setValue, getValues } = useFormContext();
	const minInputValue = watch(idMin) || '';
	const maxInputValue = watch(idMax) || '';
	const applyBtnDisabled = !minInputValue && !maxInputValue;

	const applyClickHandler = () => {

		const values = getValues([ idMin, idMax ]);
		if (values && Object.values(values).some((val) => val && val !== '')) {

			sortFilterUtagLink(htmlName, `$${minInputValue} - $${maxInputValue}`);
			sortFilterGenericEvent('filter_click', htmlName, `$${minInputValue} - $${maxInputValue}`, true);

			applyRangeValues(
				minInputValue,
				maxInputValue,
				DecimalTerm,
				PriceTerm,
				MinAndUpSearchTerm,
				UnderAndMaxSearchTerm,
				RangeSearchTerm,
				SearchFilterPrefix
			);

		}

	};

	const format = (val, isMin, noEmptyString) => {

		const valStr = val.toString();

		return valStr === (isMin ? min.toString() : max.toString()) && !noEmptyString ? '' : valStr;

	};

	const getParsed = (value, isMin) => {

		if (window.Number.isNaN(window.parseFloat(value))) {

			return isMin ? min : max;

		}

		return value.includes('.') ? window.parseFloat(value) : window.parseInt(value, 10);

	};

	// Ignore range slider max value when a > max value is set in max input
	const shouldKeepInputValue = (rangeValue) => {

		const isMaxRange = rangeValue === max;
		return getParsed(maxInputValue) >= max && isMaxRange;

	};

	const onFinalChangeSlider = (newRangeValues, newExpoRangeValues) => {

		// if range max thumb is set to max and input value is already >= max, keep input value
		const shouldKeepInput = shouldKeepInputValue(newExpoRangeValues[1]);
		const unCappedMax = shouldKeepInput ? maxInputValue : newExpoRangeValues[1];

		setValue(idMin, format(newExpoRangeValues[0], true));
		setValue(idMax, format(unCappedMax, false, shouldKeepInput));
		setRangeClass(newExpoRangeValues[0] === max ? raiseThumbClass : '');

	};

	const onChangeInputHandler = (e, isMin) => {

		const id = isMin ? idMin : idMax;
		const validMatches = e.target.value.match(filterInputRegex);

		if (validMatches && validMatches.length) {

			setValue(id, validMatches[0]);

		} else {

			setValue(id, '');

		}

	};

	const onBlurInputHandler = (isMin) => {

		const maxScaleValue = findScale(max);

		if (isMin) {

			const parsedValue = getParsed(minInputValue, true);
			const isMaxInputEmpty = maxInputValue === '';
			const isValueHigherThanMaxInput = !isMaxInputEmpty && parsedValue > getParsed(maxInputValue, false);
			const isValueLowerThanMin = parsedValue < min;
			const newInputValue = isValueHigherThanMaxInput || isValueLowerThanMin ? min : parsedValue;
			const isNewValueOutOfRangeBounds = newInputValue >= max;
			const newRangeValue = isNewValueOutOfRangeBounds ? maxScaleValue : findScale(newInputValue);

			setRangeValues([ newRangeValue, rangeValues[1] ]);
			setValue(idMin, format(newInputValue, true));
			setRangeClass(isNewValueOutOfRangeBounds ? raiseThumbClass : '');

		} else {

			// if user enters exactly the range limit in max, it shouldnt clear input box on blur
			const beforeParseIsMaxPlus = maxInputValue === max.toString();
			const parsedValue = getParsed(maxInputValue, false);
			const isValueLowerThanMinInput = parsedValue < getParsed(minInputValue, true);
			const isValueOutOfRangeBounds = parsedValue >= max;
			const newRangeValue =				isValueLowerThanMinInput || isValueOutOfRangeBounds ? maxScaleValue : findScale(parsedValue);
			const newInputValue = isValueLowerThanMinInput ? max : parsedValue;

			setRangeValues([ rangeValues[0], newRangeValue ]);
			setValue(idMax, format(newInputValue, false, beforeParseIsMaxPlus));

		}

	};

	const onKeyPressInputHandler = (e, isMin) => {

		if (e.which === 13) {

			// trigger blur handler to wipe out invalid inputs
			onBlurInputHandler(isMin);
			applyClickHandler();

		}

	};

	const Slider = (
		<div>
			<ExpoRangeSlider
				className={rangeClass}
				onFinalChange={onFinalChangeSlider}
				onChange={setRangeValues}
				min={min}
				max={max}
				values={rangeValues}
				exponentialSteps={EXPO_STEPS}
				plusOnMax={plusOnMax}
				showRangeLabels
				rangeLabelPrefix={prefix}
			/>
		</div>
	);

	const MinMaxForm = (
		<div className="SortFilterSliderTwoThumb__formWrapper">
			<span>From</span>
			<Field>
				<Label htmlFor={idMin}>Type minimum here</Label>
				<Input
					name={idMin}
					id={idMin}
					type="text"
					placeholder="Min"
					onBlur={() => onBlurInputHandler(true)}
					onChange={(e) => onChangeInputHandler(e, true)}
					onKeyPress={(e) => onKeyPressInputHandler(e, true)}
				/>
			</Field>
			<span className="SortFilterSliderTwoThumb__toLabel">to</span>
			<Field>
				<Label htmlFor={idMax}>Type maximum here</Label>
				<Input
					name={idMax}
					id={idMax}
					type="text"
					placeholder="Max"
					onBlur={() => onBlurInputHandler()}
					onChange={onChangeInputHandler}
					onKeyPress={(e) => onKeyPressInputHandler(e)}
				/>
			</Field>
		</div>
	);

	return (
		<div className="SortFilterSliderTwoThumb">
			{/* This is nested inside the filter elements which are under a form */}
			<div className="SortFilterSliderTwoThumb__flexWrapper">
				{/* Render in actual source order to maintain same tab order */}
				{sliderOverMinMax ? (
					<>
						{Slider}
						{MinMaxForm}
					</>
				) : (
					<>
						{MinMaxForm}
						{Slider}
					</>
				)}
			</div>

			<Button buttonStyleVariant="secondary" aria-disabled={applyBtnDisabled} onClick={applyClickHandler}>
				Apply
			</Button>

			{!hideAttributeValues && attributeValueGroups && attributeValueGroups.length && (
				// TODO THIS NEEDS TO SEND DOWN VALUES SAME WAY AS MAPPEDATTRIBUTECOMPONENT DOES
				<SortFilterAttributeValueList
					htmlName={htmlName}
					attributeValueGroups={attributeValueGroups}
					showAttributeValueCounts={showAttributeValueCounts}
				/>
			)}
		</div>
	);

};

SortFilterSliderTwoThumb.propTypes = {
	htmlName: PropTypes.string,
	attributeValueGroups: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object) ]),
	displayTypeData: PropTypes.shape({
		MinimumRangeValue: PropTypes.number,
		MaximumRangeValue: PropTypes.number,
		Prefix: PropTypes.string,
		PlusOnMax: PropTypes.bool,
		DecimalTerm: PropTypes.string,
		PriceTerm: PropTypes.string,
		MinAndUpSearchTerm: PropTypes.string,
		RangeSearchTerm: PropTypes.string,
		UnderAndMaxSearchTerm: PropTypes.string,
		SearchFilterPrefix: PropTypes.string
	}),
	hideAttributeValues: PropTypes.bool,
	showAttributeValueCounts: PropTypes.bool,
	sliderOverMinMax: PropTypes.bool
};

export default SortFilterSliderTwoThumb;
