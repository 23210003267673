import PropTypes from 'prop-types';
import React from 'react';

import SortFilterAppliedFilterClearButton from './SortFilterAppliedFilterClearButton';

import './SortFilterAppliedFilter.scss';

const SortFilterAppliedFilter = ({ name, value, stepbackLink }) => (stepbackLink ? (
	<SortFilterAppliedFilterClearButton attributeName={name} attributeValue={value} stepbackLink={stepbackLink}>
		<span className="lpIcon-close01" />
		<span>
			{value}
		</span>
	</SortFilterAppliedFilterClearButton>
) : (
	<div className="SortFilterAppliedFilter__filterValue">
		{value}
	</div>
));

SortFilterAppliedFilter.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	stepbackLink: PropTypes.string
};

export default SortFilterAppliedFilter;
