function initImageHoverEffect (sortHoverImages) {

	if (!sortHoverImages || !(sortHoverImages instanceof NodeList)) {

		return;

	}

	const placeholderImg = '/img/global/trans.gif';

	sortHoverImages.forEach((img) => {

		let touchStarted = false;
		let timer = 0;

		const onError = (e) => {

			const { target } = e;
			target.parentElement.classList.remove('brokenImage');
			target.src = target.dataset.origSrc;

		};

		// Disable hover effect on tablets and hybrid laptops
		img.addEventListener('touchstart', () => {

			touchStarted = true;

		});

		img.addEventListener('mouseenter', (e) => {

			const { target } = e;

			if (
				touchStarted
				|| !window.lp.pageData.sortImageHoverEffectEnabled
				|| target.src.includes(placeholderImg)
			) {

				return;

			}

			target.addEventListener('error', onError);

			// Set a delay to prevent accidental hover effect
			timer = setTimeout(() => {

				target.dataset.origSrc = target.src;
				target.src = target.dataset.hoverSrc;

			}, 10);

		});

		img.addEventListener('mouseleave', (e) => {

			const { target } = e;
			if (
				touchStarted
				|| !window.lp.pageData.sortImageHoverEffectEnabled
				|| target.src.includes(placeholderImg)
			) {

				touchStarted = false;
				return;

			}

			clearTimeout(timer);
			target.removeEventListener('error', onError);
			target.src = target.dataset.origSrc;

		});

	});

}

export default initImageHoverEffect;
