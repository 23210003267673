interface Override {
	key: string;
	value: string;
}

const persistedKeys = ['sb'];
const getPersistedQueryParameters = (overrides?: Override[]) => {
	const urlParams = new URLSearchParams(window.location.search.toLowerCase());
	overrides?.forEach(({ key, value }) => urlParams.set(key, value));
	const persistedQueryParameters = persistedKeys
		.filter((key) => urlParams.get(key))
		.map((key) => `${key}=${urlParams.get(key)}`)
		.join('&');
	
	return persistedQueryParameters ? `?${persistedQueryParameters}` : '';
};

export default getPersistedQueryParameters;
