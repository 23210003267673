import { useContext } from 'react';

import ModelContext from './modelContext';
import isMappedTo from './isMappedTo';

const useVisibleAttributesFilter = () => {

	const { canAttributesMapToFds, fdsQueryRulesChoices } = useContext(ModelContext);

	return (attributes) => attributes.filter((att) => {

		const isAttInRules = fdsQueryRulesChoices.some((r) => r.Id === att.AttributeId);
		const isAttSingleSelect = !isMappedTo.attribute.multiSelect(att.AttributeFilterDisplayTypeId);
		const isAttValueSelected = att.AttributeValueGroups.some((valueGroup) => valueGroup.AttributeValues.some((value) => value.IsSelected));

		const isSingleSelectSelected = isAttSingleSelect && isAttValueSelected;
		const isSuppressedByFds = !canAttributesMapToFds && isAttInRules;

		// filter out suppresed attributes
		return !(isSuppressedByFds || isSingleSelectSelected);

	});

};

export default useVisibleAttributesFilter;
