import { utagLinkSort } from 'features/tealium';
import { toTitleCase } from 'utilities/string';

// shop all button tracking
function trackShopAllCategoryClick ({ category, isOpenBox = false } = {}) {

	const trackSortPageEvents = utagLinkSort();
	if (category) {

		trackSortPageEvents({
			eventAction: `Click-Shop-${isOpenBox ? 'OpenBox' : 'All'}-${toTitleCase(category)}`,
			eventLabel: window.location.pathname
		});

	}

}

function initShopAll () {

	const shopAllCategoryLink = document.querySelector('.contextButtons .shopAllCategoryLink');
	const shopOpenBoxCategoryLink = document.querySelector('.contextButtons .shopOpenBoxCategoryLink');

	if (shopAllCategoryLink) {

		shopAllCategoryLink.addEventListener(
			'click',
			(e) => {

				trackShopAllCategoryClick({ category: e.currentTarget.dataset.category, isOpenBox: false });

			},
			{ once: true }
		);

	}

	if (shopOpenBoxCategoryLink) {

		shopOpenBoxCategoryLink.addEventListener(
			'click',
			(e) => {

				trackShopAllCategoryClick({ category: e.currentTarget.dataset.category, isOpenBox: true });

			},
			{ once: true }
		);

	}

}

export default initShopAll;
