import { visibilityObserver } from 'features/product';
import { ProductImpressionsReporter } from 'features/tealium';
import { ProductImpressionsReporter as ProductImpressionsGa4Reporter } from 'features/tealium/ga4/ecommerce';

function initCaptureProductImpressions (elements) {

	const container = document.getElementById('sortResultProducts');
	const { listType } = container.dataset;
	const reporter = new ProductImpressionsReporter({
		list_type: listType,
		product_grouping: window.utag_data.product_grouping,
		sku_input_type: window.utag_data.sku_input_type
	});

	const reporterGa4 = new ProductImpressionsGa4Reporter({
		product_grouping: window.utag_data.product_grouping
	});

	const observer = visibilityObserver((element) => {

		const record = reporter.getProductImpressionDataFromElement(element);
		reporter.track(record);

		const recordGa4 = reporterGa4.getProductImpressionDataFromElement(element);
		reporterGa4.track(recordGa4);

	});

	elements.forEach((element) => {

		observer.observe(element);

	});

}

export default initCaptureProductImpressions;
