/**
 * GA bindings for Recently Viewed widget.
 * Recently Viewed Widget has same structre across all pages mobile and desktop.
 * @param {Function} track for different pages (sort, pla, pdp and so on) we use different track functions
 */
function recentlyViewedGAEvents (track) {

	const container = document.getElementById('recentlyViewedContainer');

	if (!container) {

		return;

	}

	const viewAllLink = document.querySelector('.viewAllRecentlyViewedBtn');
	const moreLikeThisLinks = container.querySelectorAll('.moreLikeThisLink');
	const recentlyViewedProdImgs = container.querySelectorAll('.moreYouMayLikeItem a:first-child');

	const shortSku = window.shortSku ?? window.lp._ProductListingAd?.shortSku;

	const onClickGenerator = (eventAction) => (element, i) => {

		element.addEventListener('click', (e) => {

			const sku = e.target.closest('.moreYouMayLikeItem ').querySelector('[data-certonasku]').dataset.certonasku;
			const eventLabel = shortSku ? `${shortSku}:${i + 1}:${sku}` : `${i + 1}:${sku}`;
			track({ eventAction, eventLabel });

		});

	};

	viewAllLink.addEventListener('click', () => track({ eventAction: 'Click-Recent-View-All', eventLabel: shortSku }));
	moreLikeThisLinks.forEach(onClickGenerator('Click-Recent-More-Like-This'));
	recentlyViewedProdImgs.forEach(onClickGenerator('Click-Recently-Viewed-Product'));

}

export { recentlyViewedGAEvents };
