import SortFilterAttributeValueList from '../SortFilterAttributeValueList';
import SortFilterAttributeMultiValueList from '../SortFilterAttributeMultiValueList';
import SortFilterSliderOneThumb from '../SortFilterSliderOneThumb';
import SortFilterSliderTwoThumb from '../SortFilterSliderTwoThumb';
import SortFilterMinMax from '../SortFilterMinMax';
import SortFilterPanelGrid from '../SortFilterPanelGrid';
import SortFilterMultiColumnValueList from '../SortFilterMultiColumnValueList';
import isMappedTo from '../../isMappedTo';

function mapSelectionTypeToAttrComponent (displayTypeId) {

	const isMultiSelect = isMappedTo.attribute.multiSelect(displayTypeId);

	switch (isMappedTo.attribute.selectionType.which(displayTypeId)) {

		case 'rangeSlider':
			return SortFilterSliderTwoThumb;
		case 'slider':
			return SortFilterSliderOneThumb;
		case 'minMaxInputs':
			return SortFilterMinMax;
		case 'panelGrid':
			return SortFilterPanelGrid;
		case 'valueListWithColumns':
			return SortFilterMultiColumnValueList;
		case 'valueList':
		default:
			if (isMultiSelect) return SortFilterAttributeMultiValueList;
			return SortFilterAttributeValueList;

	}

}

export default mapSelectionTypeToAttrComponent;
